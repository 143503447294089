import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import PerusahaanComp from '../../../../containers/Components/PerusahaanComp';
import HeaderTransaksiComp from '../../../NonStay/Components/HeaderTransaksiComp';
import Moment from 'moment';
import api from '../../../../services/api';
import config from '../../../../config';
import { useGlobalFunction } from '../../../../generalFunction/globalFunction';
import LoaderComponent from '../../../../containers/Components/LoaderComponent';
import { Badge, Table } from 'reactstrap';

const InHousePenjualanCetak = (props) => {
    const filter = JSON.parse(localStorage.getItem('inHouseParamsCetak')) || {
        start_date : null,
        end_date : null,
        dr_tanggal : '',
        sd_tanggal : '',
        state : 'all',
        tipe_penjualan : '',
        title : ''
    };
    const { isLoading, setIsLoading } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const myDate = new Date()
    const startDate = filter.start_date || myDate;
    const endDate = filter.end_date || myDate;    

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {
                offset : 0, 
                limit : 500
            }            
            if (startDate){
                params['start_date'] = Moment(startDate).format('YYYY-MM-DD')
            }
            if (endDate){
                params['end_date'] = Moment(endDate).format('YYYY-MM-DD')
            }
            const res = await api.get(`${config.endPoint.penjualan}`, { params: params}).then(res => res.data);
            setIsLoading(false);            
            if (res){
                setListData(res.results);                
            }
        }catch(error){
            setIsLoading(false);
            console.log(error);
        }
        // eslint-disable-next-line
    },[setIsLoading])

    useEffect(() => {
        fetchData();
    },[fetchData])
    
    return(
        <Fragment>            
            {/* <div className="billprint-container landscape"> */}
            <div style={{backgroundColor: '#ffffff', paddingLeft: 20, paddingRight: 20}}>
                { isLoading && <LoaderComponent/>}
                <PerusahaanComp/>
                <HeaderTransaksiComp
                    title={filter.title}
                    desc={startDate && endDate ? `tanggal ${Moment(startDate).format('DD-MM-YYYY')} s.d tanggal ${Moment(endDate).format('DD-MM-YYYY')}` : `tanggal ${Moment(myDate).format('DD-MM-YYYY')} s.d tanggal ${Moment(myDate).format('DD-MM-YYYY')}`}
                    className='margin-bottom-5'
                    hideKontakName={true}
                />
                <div style={{backgroundColor: '#ffffff'}}>

                    <Table style={{width:"100%", borderCollapse:"collapse"}} bordered className={props.className ? `table-center table-small ${props.className}` : 'table-center table-small'}>
                        <thead style={{backgroundColor:"#c0c0c0", zIndex: 9999}}>
                            <tr>
                                <th rowSpan={2}>#</th>
                                <th rowSpan={2}>Registrasi</th>   
                                <th rowSpan={2}>Tipe Penjualan</th>                                     
                                <th rowSpan={2}>Nama</th>
                                <th rowSpan={2}>Kota</th>
                                <th rowSpan={2}>Kamar / Produk</th>
                                <th rowSpan={2}>Harga Kamar</th>
                                <th rowSpan={2}>C/I</th>
                                <th rowSpan={2}>C/O</th>
                                <th rowSpan={2}>Lama Menginap</th>
                                <th rowSpan={2}>Tamu</th>                                    
                                <th rowSpan={2}>Total</th>
                                <th rowSpan={2}>Status</th>                                    
                            </tr>                                
                        </thead>
                        <tbody>
                            { listData.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{post.no_invoice}</td>
                                        <td>{post.tipe_penjualan_display}</td>
                                        <td>{post.konsumen_name}</td>
                                        <td>{post.kota_name}</td>
                                        <td>{post.detail.length > 0 ? post.detail[0].nama_produk : ''}</td>
                                        <td className='text-right'>{post.detail.length > 0 ? post.detail[0].harga_jual.toLocaleString() : ''}</td>
                                        <td>{post.detail.length > 0 ? post.detail[0].tanggal : ''}</td>
                                        <td>{post.detail.length > 0 ? post.detail[0].tgl_checkout : ''}</td>
                                        <td className='text-right'>{post.detail.length > 0 ? post.detail[0].lama_menginap : ''}</td>
                                        <td className='text-right'>{post.detail.length > 0 ? post.detail[0].jml_tamu : ''}</td>
                                        <td className='text-right'>{post.total_invoice.toLocaleString()}</td>                                            
                                        <td>
                                            <Badge className="mr-1" color={post.is_close ? 'danger' : "success"}>{post.is_close ? 'Checkout' : 'Active'}</Badge>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        </Fragment>
    )
}
export default InHousePenjualanCetak;