import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/globalFunction";
import api from "../../../services/api";
import config from "../../../config";


export function useGuestHouse(){
    const { isLoading, setIsLoading, alertValue, setAlertValue } = useGlobalFunction();
    const [listData, setListData] = useState();
    const [total, setTotal] = useState(0);

    const fetchData = useCallback(async(search='', limit=config.itemPerPage, page=1) => {
        try{
            let params = {
                limit: 200,
                page : page,
                filter: 'active',
            }
            if (search){
                params['q'] = search
            }
            const response = await api.get(`/penjualan/api/penjualan-by-room-detail`, {params:params}).then(res => res.data)
            const data = await response;            
            // console.log('res datadata is >>>', data.data)
            if (data.sukses === 'yes'){
                let total = 0;
                data.data.map(post => {
                    total += post.total_invoice
                    return true;
                })
                setListData(data.data);           
                setTotal(total);     
            }else{
                setAlertValue(alertValue => ({...alertValue, show : true, text : data.pesan}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false, text : '', color : 'danger'}));
                }, 5000)
            }
            setIsLoading(false);
            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch Data'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show : false, text : '', color : 'danger'}));
            }, 5000)
        }
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchData();
    },[fetchData])


    const handleCetak = () => {                
        window.open(`#/laporan/guest-house`, "_blank")        
    }
    
    const handleCetakActiveBill = () => {
        window.open(`#/laporan/active-bill`, "_blank")        
        
    }
    return {
        isLoading, setIsLoading, alertValue, setAlertValue, listData, handleCetak, handleCetakActiveBill,
        total
    }
}