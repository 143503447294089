import api from './api';

// meeting room
export function postCheckin(formData){
    return api.post(`/penjualan/api/checkin`, formData).then(res => res.data);
}


export function getGuest(keyword, limit, page){
    return api.get(`/penjualan/api/penjualan-by-room?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function getGuestbyDetail(keyword, limit, page){
    return api.get(`/penjualan/api/penjualan-by-room-detail?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function postCheckinBulk(formData){
    return api.post(`/penjualan/api/checkin/bulk`, formData).then(res => res.data);
}

export function putCheckinBulk(id, formData){
    return api.put(`/penjualan/api/checkin/bulk/${id}`, formData).then(res => res.data);
}

export function getCheckinBulk(keyword){
    return api.get(`/penjualan/api/checkin/bulk?${keyword}`).then(res => res.data);
}

export function checkout(id, formData){
    return api.post(`/penjualan/api/checkout/${id}`, formData).then(res => res.data);
}

export function postCheckinMeeting(formData){
    return api.post(`/penjualan/api/checkin/meeting`, formData).then(res => res.data);
}

export function putCheckinMeeting(id, formData){
    return api.put(`/penjualan/api/checkin/meeting/${id}`, formData).then(res => res.data);
}

export function editMeetingRoom(id){
    return api.get(`/penjualan/api/edit-meetingroom/${id}`).then(res => res.data);
}

// reservation
export function postReservation(formData){
    return api.post(`/penjualan/api/reservation`, formData).then(res => res.data);
}

export function putReservation(id, formData){
    return api.put(`/penjualan/api/reservation/${id}`, formData).then(res => res.data);
}

export function getReservation(keyword, limit, page){
    return api.get(`/penjualan/api/reservation?${keyword}`, {params:{limit, page}}).then(res => res.data)
}


export function getRoomPlan(keyword, limit, page){
    return api.get(`/penjualan/api/room-plan?${keyword}`, {params:{limit, page}}).then(res => res.data)
}


export function getDrReport(keyword){
    return api.get(`/penjualan/api/laporan-drr?${keyword}`).then(res => res.data)
}