import React, { Fragment, useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, ButtonGroup, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, PaginationItem, PaginationLink, Row,  } from 'reactstrap';
import CardTemplate from '../../../containers/Components/CardTemplate';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import ModalComp from '../../../containers/Components/ModalComp';
import SearchComp from '../../../containers/Components/SearchComp';
import { fetchGuestbyDetail } from '../../../slices/guests';
import * as penjualanService from '../../../services/penjualanService';
import config from '../../../config';
import '../../../style/guest.css';
import StickyTable from "react-sticky-table-thead";
import { useResto } from '../../FoodnBeverage/Pages/restofunction';
import GuestListComp from './Components/GuestListComp';
import Moment from 'moment'
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../../../generalFunction/windowWidthFunction';

const GuestListPage = (props) => {
    const dispatch = useDispatch();
    const { guests } = useSelector(state => state);
    const [depositeError, setDepositeError] = useState(false);
    const [methodError, setMethodError] = useState(false);
    const [filter, setFilter] = useState('all');    
    const [isLoading, setIsLoading] = useState(false);
    const initAlert = {
        show: false,
        text : '',
        color : 'danger'
    }
    const [pageValue, setPageValue] = useState({
        page : 1,
        search : ''
    });
    const history = useHistory();
    const viewType = props.viewType || 'in_house'
    const tipe_penjualan = props.tipe_penjualan || 1
    const [alertValue, setAlertValue] = useState(initAlert)
    const [modalDeposite, setModalDeposite] = useState({
        show: false,
        text : '',
        tipe : 'deposite'
    });
    const [tanggal, setTanggal] = useState('')
    const [formData, setFormData] = useState({
        billing_id : 0,
        deposite : 0,
        konsumen_name : '',
        kota : '',
        no_invoice : '',
        current_deposite : 0,
        total_invoice : 0,
        method_pembayaran : '',
        nomor_kartu : '',
    })
    const { methodPembayaran } = useResto();
    const [modalFilter, setModalFilter] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    let myDate = new Date()
    myDate.setDate(1)
    
    const [filterDate, setFilterDate] = useState({
        start_date : null,
        end_date : null,
        start_date_submit : '',
        end_date_submit : '',
    });


    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    const handleChangeDateFilter = (e, name) => {        
        let submit = '';
        if (isValidDate(e)){
            submit = Moment(e).format('YYYY-MM-DD')
        }
        if (name === 'start_date'){
            setFilterDate(filterDate => ({...filterDate, start_date : e, start_date_submit : submit}))
        }else{
            setFilterDate(filterDate => ({...filterDate, end_date : e, end_date_submit : submit}))
        }
    }
    
    const handleDetail = (post) => {
        sessionStorage.setItem('billingId', post.id)
        history.push('/fo/billing-detail')
    }

    const handleChangeDeposite = (e) => {
        const { name, value } = e.target;
        let nilai = value
        if (name === 'deposite'){
            nilai = nilai.replace(/,/g, '')
        }
        setFormData(formData => ({...formData, [name]: nilai}))
    }
    
    const handleBlurDeposite = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (name === 'deposite'){
            nilai = value.replace(/,/g, '')
            if (parseInt(nilai) === 0 || nilai === ''){
                setDepositeError(true);
            }else{
                setDepositeError(false);                
            }
        }else{
            if (nilai === ''){
                setMethodError(true);
            }else{
                setMethodError(false)
            }
        }
    }

    const handleAddDeposite = (post) => {
        setModalDeposite(modalDeposite => ({...modalDeposite, show: true}))
        setFormData(formData => ({...formData,
            billing_id : post.id,
            konsumen_name : post.konsumen_name,
            current_deposite : post.deposite,
            no_invoice : post.no_invoice,
            total_invoice : post.total_invoice
        }))
    }

    const handleCloseModalDeposite = () => {
        setModalDeposite(modalDeposite => ({...modalDeposite, show: false, tipe : 'deposite'}))
    }

    const handleSubmitDeposite = async() => {
        setIsLoading(true);
        try{
            if (formData.method_pembayaran === ''){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Pilih Metode Pembayaran', color: 'danger'}));
                setMethodError(true);
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(initAlert);
                },5000)
                return;
            }
            if (parseInt(formData.deposite) === 0 || formData.deposite === ''){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color: 'danger'}));
                setDepositeError(true);
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(initAlert);
                },5000)
                return;
            }
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(formData));
            // console.log('depositenya adalaha >>>>>>')

            setIsLoading(false);
            const res = await penjualanService.postDeposite(newForm);
            if (res.sukses === 'yes'){                
                dispatch(fetchGuestbyDetail(`tipe_penjualan=${tipe_penjualan}`, config.itemPerPage, pageValue.page));
                setFormData(formData => ({...formData, deposite: 0}))
            }
            setModalDeposite(modalDeposite => ({...modalDeposite, show: false}));            
            setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan, color: 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000)            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to save deposite', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000)
        }
    }

    const handleCheckout = (post) => {
        sessionStorage.setItem('billingId', post.id)
        history.push('/fo/checkout')
    }

    const handleFilter = () => {        
        dispatch(fetchGuestbyDetail(`tipe_penjualan=${tipe_penjualan}&q=${pageValue.search}&filter=${filter}&start_date=${filterDate.start_date_submit}&end_date=${filterDate.end_date_submit}`, config.itemPerPage, 1));
        setModalFilter(false);
    }

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setPageValue(pageValue => ({...pageValue, search : value}))
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        dispatch(fetchGuestbyDetail(`tipe_penjualan=${tipe_penjualan}&q=${pageValue.search}&filter=${filter}&start_date=${filterDate.start_date_submit}&end_date=${filterDate.end_date_submit}`, config.itemPerPage, 1));
    }
    
    const handlePaginate = (post) => {
        setPageValue(pageValue => ({...pageValue, page : post}))
        dispatch(fetchGuestbyDetail(`tipe_penjualan=${tipe_penjualan}&q=${pageValue.search}&filter=${filter}&start_date=${filterDate.start_date_submit}&end_date=${filterDate.end_date_submit}`, config.itemPerPage, post));
    }

    // const handleClosing = () => {
    //     setModalDeposite(modalDeposite => ({...modalDeposite, show: true, tipe: 'closing'}))
    // }

    const handleSubmitClosing = async() => {
        setIsLoading(true);
        try{
            const res = await penjualanService.closingTransaksi();
            console.log('res adalah', res)
            setIsLoading(false);
            setModalDeposite(modalDeposite => ({...modalDeposite, show: false}));
            if (res.sukses === 'yes'){
                fetchTanggal();
                dispatch(fetchGuestbyDetail(`tipe_penjualan=${tipe_penjualan}`, config.itemPerPage, 1));
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan, color: 'success'}));
                setTimeout(() => {
                    setAlertValue(initAlert);
                }, 5000)
            }else{
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan, color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(initAlert);
                }, 5000)
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to save deposite', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000)
        }
    }

    const handleCheckinMeeting = () => {
        sessionStorage.removeItem('billingId')
        history.push('/fo/meetingroom-checkin')
    }
    
    useEffect(() => {
        dispatch(fetchGuestbyDetail(`tipe_penjualan=${tipe_penjualan}`, config.itemPerPage, 1));
    },[dispatch, tipe_penjualan]);

    const fetchTanggal = useCallback(async() => {
        setIsLoading(true);
        try{
            setIsLoading(false);
            const res = await penjualanService.getMasterTanggal();
            if (res.sukses === 'yes' && res.tanggal){
                let myDate = Moment(res.tanggal, 'YYYY-MM-DD').toDate()
                myDate = Moment(myDate).format('DD-MM-YYYY');
                setTanggal(myDate)
            }
            setIsLoading(false)            
        }catch(error){
            setIsLoading(false)
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch master tanggal'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show : false, text : '', color : 'danger'}));
            }, 5000)
        }
    },[]);

    useEffect(() => {
        fetchTanggal()
    },[fetchTanggal])
    
    const handleChangeRoom = (post) => {
        sessionStorage.setItem('billingId', post.id)
        history.push('/fo/change-room')
    }

    const handleResetFilter = () => {
        setFilterDate(filterDate => ({...filterDate, 
            start_date : null,
            end_date : null,
            start_date_submit : '',
            end_date_submit : '',        
        }));        
        setFilter('all');
        dispatch(fetchGuestbyDetail(`q=&filter=all&tipe_penjualan=${tipe_penjualan}`, config.itemPerPage, 1));
        setModalFilter(false);
    }

    useEffect(() => {
        let countChange = 0;
        if (filter !== 'all'){
            countChange++;
        }
        if (filterDate.start_date_submit !== ''){
            countChange++;
        }
        if (filterDate.end_date_submit !== ''){
            countChange++;
        }

        if (countChange > 0){
            setIsFilter(true);
        }else{
            setIsFilter(false);
        }
    }, [filter, filterDate.start_date_submit, filterDate.end_date_submit]);

    const handlePrint = () => {
        let tmpObj = {}        
        tmpObj['start_date'] = filterDate.start_date_submit !== '' ? filterDate.start_date_submit : Moment(myDate).format('YYYY-MM-DD')
        tmpObj['end_date'] = filterDate.end_date_submit !== '' ? filterDate.end_date_submit : Moment(new Date()).format('YYYY-MM-DD')                        
        tmpObj['dr_tanggal'] = filterDate.start_date !== null ? Moment(filterDate.start_date).format("DD-MM-YYYY") : Moment(myDate).format('DD-MM-YYYY');
        tmpObj['sd_tanggal'] = filterDate.end_date !== null ? Moment(filterDate.end_date).format("DD-MM-YYYY") : Moment(new Date()).format('DD-MM-YYYY');
        tmpObj['state'] = filter
        tmpObj['tipe_penjualan'] = tipe_penjualan
        tmpObj['title'] = viewType === 'in_house' ? 'Laporan In House' : 'Laporan Meeting Room'
        localStorage.setItem('inHouseParams', JSON.stringify(tmpObj))
        window.open(`#/fo/cetak`, "_blank")
    }

    useEffect(() => {           
        const el = document.getElementsByTagName('body')[0];
        el.classList.add('sidebar-minimized');          
        el.classList.add('brand-minimized');    

        const sd = document.getElementsByClassName('scrollbar-container')[0]
        sd.classList.remove('ps')
        sd.classList.remove('ps-container')
        sd.classList.remove('ps--active-y')
    },[]);

    const { height } = useWindowDimensions();


    return(
        <Fragment>
            { (isLoading || guests.loading) &&
                <LoaderComponent/>
            }
            <Alert isOpen={alertValue.show} color={alertValue.color} toggle={() => setAlertValue(initAlert)}>
                {alertValue.text}
            </Alert>
            <Modal isOpen={modalFilter}>
                <ModalHeader toggle={() => setModalFilter(false)}>
                    Filter
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>
                            Status
                        </Label>
                        <Input
                            type='select'
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        >
                            <option value='all'>Semua</option>
                            <option value='active'>Active</option>
                            <option value='checkout'>Checkout</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Dari tanggal
                        </Label>
                        <FormTextSingleComp
                            tipe='date'
                            name='start_date'
                            className="form-control"
                            value={filterDate.start_date}                               
                            onChangeDate={(e) => handleChangeDateFilter(e, 'start_date')}
                            show={true}
                        />                            
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            s.d Tanggal
                        </Label>
                        <FormTextSingleComp
                            tipe='date'
                            name='end_date'
                            className="form-control"
                            value={filterDate.end_date}                               
                            onChangeDate={(e) => handleChangeDateFilter(e, 'end_date')}
                            show={true}
                        />                            
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button onClick={handleFilter} color="primary">Filter</Button>
                        <Button onClick={handleResetFilter} color="danger">Reset</Button>
                        <Button onClick={() => setModalFilter(false)} color="secondary">Cancel</Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
            <ModalComp
                isOpen={modalDeposite.show}
                onClose={handleCloseModalDeposite}
                title="Tambah Deposite"
                submitShow={true}
                submitVariant="primary"
                submitText="Simpan"
                onSubmit={modalDeposite.tipe === 'deposite' ? handleSubmitDeposite : handleSubmitClosing}                
            >
                { modalDeposite.tipe === 'deposite' ? 
                    <Fragment>
                        <FormGroup row>
                            <Col lg={5}>Konsumen sdf</Col>
                            <Col lg={7}>{formData.konsumen_name}</Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={5}>Deposite Saat ini</Col>
                            <Col lg={7}>{formData.current_deposite ? formData.current_deposite.toLocaleString() : ''}</Col>
                        </FormGroup>                        
                        <FormGroup row>
                            <Col lg={5}>Total Tagihan</Col>
                            <Col lg={7}>{formData.total_invoice ? formData.total_invoice.toLocaleString() : ''}</Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={5}>Method Pembayaran</Col>
                            <Col lg={7}>
                                <Input
                                    name='method_pembayaran'
                                    value={formData.method_pembayaran}
                                    onChange={handleChangeDeposite}
                                    onBlur={handleBlurDeposite}
                                    type="select"
                                >
                                    { methodPembayaran.map((post, index) => {
                                        return(
                                            <Fragment key={index}>
                                                { (post.value !== 7 && post.value !== 5 && post.value !== 2) &&
                                                    <option value={post.value} key={index}>{post.label}</option>
                                                }
                                            </Fragment>
                                        )
                                    })}
                                </Input>
                                { methodError &&
                                    <div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>Wajib diisi</div>
                                }
                            </Col>
                        </FormGroup>
                        { (parseInt(formData.method_pembayaran) === 3 || parseInt(formData.method_pembayaran) === 4) &&
                            <FormGroup row>
                                <Col lg={5}>Card Remark</Col>
                                <Col lg={7}>
                                    <Input
                                        name='nomor_kartu'
                                        value={formData.nomor_kartu}
                                        onChange={handleChangeDeposite}
                                        onBlur={handleBlurDeposite}
                                        type="text"
                                    />                                  
                                </Col>
                            </FormGroup>
                        }
                        <FormGroup row>
                            <Col lg={5}>Tambahan Deposite</Col>
                            <Col lg={7}>
                                <NumberFormat 
                                    thousandSeparator={true} 
                                    prefix={''}                     
                                    inputMode="numeric"                            
                                    className={"form-control" }
                                    name="deposite"
                                    value={formData.deposite} 
                                    onChange={handleChangeDeposite}
                                    onBlur={handleBlurDeposite}
                                />
                                { depositeError &&
                                    <div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>Wajib diisi</div>
                                }
                            </Col>
                        </FormGroup>
                    </Fragment>
                    :
                    <Fragment>
                        {`Transaksi tanggal ${tanggal} akan di closing ?`} 
                    </Fragment>
                }
            </ModalComp>
            <CardTemplate
                headerTitle={viewType === 'in_house' ? "In House" : "Meeting Room"}
                lg={12}
            >
                <CardBody>
                    <Row>
                        <Col lg={6}>
                            <SearchComp
                                value={pageValue.search}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDownSearch}
                                onClick={handleSearch}
                                placeHolder="Cari"
                            />
                        </Col>
                        <Col lg={6}>
                            <ButtonGroup>
                                { viewType === 'in_house' ?
                                    <Fragment>
                                        <Button color="warning" onClick={() => history.push('/fo/checkin-bulk')}><i className='fa fa-plus'></i>&nbsp;Checkin</Button>
                                        {/* <Button color="danger" onClick={handleClosing}><i className='fa fa-calendar-check-o'></i>&nbsp;Closing Transaksi</Button> */}
                                    </Fragment>
                                    :
                                    <Button color="success" onClick={handleCheckinMeeting}><i className='fa fa-plus'></i>&nbsp;Checkin</Button>
                                }
                                {/* <Button color="primary" onClick={() => history.push('/fo/checkin')}><i className='fa fa-plus'></i>&nbsp;Checkin</Button> */}
                                <Button color='primary' outline={isFilter ? false : true} onClick={() => setModalFilter(true)}><i className='fa fa-filter'></i></Button>
                                <Button color='success' outline={isFilter ? false : true} onClick={handlePrint}><i className='fa fa-print'></i></Button>
                            </ButtonGroup>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col lg={12}>                            
                            <StickyTable height={height - 340}>
                                <GuestListComp 
                                    data={guests.guests.length > 0 ? guests.guests : []}
                                    handleDetail={handleDetail}
                                    handleAddDeposite={handleAddDeposite}
                                    handleCheckout={handleCheckout}
                                    handleChangeRoom={handleChangeRoom}
                                />                            
                            </StickyTable>
                            { guests.list_page.length > 1 &&
                                <Pagination>
                                    {guests.list_page.map((post, index) => {
                                        return(
                                            <PaginationItem key={index} className={post === parseInt(pageValue.page) ? 'active' : ''}>
                                                <PaginationLink tag="button" onClick={() => handlePaginate(post)}>{post}</PaginationLink>
                                            </PaginationItem>
                                        )
                                    })}
                                </Pagination>
                            }
                        </Col>                        
                    </Row>
                </CardBody>    
            </CardTemplate>

        </Fragment>
    )
}
export default GuestListPage;