import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTipeKamar } from '../../../slices/tipekamar';
import {fetchTipeBed } from '../../../slices/tipebed';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { CardBody, Alert, CardFooter, Button } from 'reactstrap';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import FormTextComp from '../../../containers/Components/FormTextComp';
import { useHistory } from 'react-router-dom';
import { fetchDetail } from '../../../slices/room'

import * as produkService from '../../../services/produkService';

const RoomAddPage = (props) => {
    const history = useHistory();    
    const dispatch = useDispatch();    
    const { rooms } = useSelector(state => state);
    const [isEdit, setIsEdit] = useState(false);
    const roomId = sessionStorage.getItem('roomId') || 0;
    const { tipekamars } = useSelector(state => state);
    const { tipebeds } = useSelector(state => state);    

    const [alertValue, setAlertValue] = useState({
        color : 'danger',
        text : '',
        show : false
    });    
    const [relatedObj, setRelatedObj] = useState({
        tipeKamarObj : [],
        tipeBedObj : []
    });
            
    const fetchTipeKamarLocal = useCallback(async() => {
        dispatch(fetchTipeKamar())
    },[dispatch]);

    const fetchTipeBedLocal = useCallback(async() => {
        dispatch(fetchTipeBed())
    },[dispatch])

    const [formData, setFormData] = useState({
        nama : '',
        tipeKamarId : 0,
        tipeBedId : 0,
        harga : 0,
        id : 0,
        tipeProduk: 3  
    })

    let tmpFormData = {};
	Object.entries(formData).map( ([key, value]) => tmpFormData[key] = false)
	const [errors, setErrors] = useState(
		tmpFormData
	)    

    const arrayForm = [
        {
			'name': 'nama',
			'desc': 'No / Nama Kamar',
			'tipe': 'text',
			'id': 'nama',
			'required': true,
			'value': formData.nama,
			'errors' : errors.nama,
			'rule' : { required : true}
		},
		{
            'name': 'tipeKamarId',
			'desc': 'Tipe Kamar',
			'tipe': 'select',
			'id': 'tipeKamarId',
			'required': true,
			'value': formData.tipeKamarId,			
            'errors' : errors.nama,
            'obj' : relatedObj.tipeKamarObj,
			'rule' : { required : true}
        },
        {
            'name': 'tipeBedId',
			'desc': 'Tipe Tempat Tidur',
			'tipe': 'select',
			'id': 'tipeBedId',
			'required': true,
			'value': formData.tipeBedId,			
            'errors' : errors.nama,
            'obj' : relatedObj.tipeBedObj,
			'rule' : { required : true}
        },
        {
            'name': 'harga',
			'desc': 'Harga',
			'tipe': 'number',
			'id': 'tipeBedId',
			'required': true,
			'value': formData.harga,			
            'errors' : errors.harga,            
			'rule' : { required : true}
        }
    ]

    useEffect(() => {
        fetchTipeKamarLocal();
        fetchTipeBedLocal();
    },[fetchTipeKamarLocal, fetchTipeBedLocal])

    useEffect(() => {
        const tmp = []
        tmp.push({id : 0, value : 'Pilih Tipe Kamar', harga : 0})
        tipekamars.tipekamars.map(post => tmp.push({id : post.id, value : post.nama, harga : post.harga}));
        setRelatedObj(relatedObj => ({...relatedObj, tipeKamarObj: tmp}))        
        
        const tmpBed = [];
        tmpBed.push({id : 0, value : 'Pilih Tipe Bed'});
        tipebeds.tipebeds.map(post => tmpBed.push({id: post.id, value : post.nama}));
        setRelatedObj(relatedObj => ({...relatedObj, tipeBedObj: tmpBed}))
    },[tipebeds.tipebeds, tipekamars.tipekamars]);


    const handleChange = e => {
		const { name, value } = e.target;	
		let nilai = value 
        if (name === 'harga'){
            nilai = nilai.replace('Rp.', '');
            nilai = nilai.replace(/,/g, '');
        };
        
        if (name === 'tipeKamarId'){
            let tmp = relatedObj.tipeKamarObj.filter(post => post.id  === parseInt(value));
            if (tmp.length > 0){
                setFormData({...formData, harga : tmp[0].harga, [name] : nilai});
            }else{
                setFormData({...formData, harga : 0, [name] : nilai});
            }       
        }else{
            setFormData({...formData, [name] : nilai});
        }
    }
    
    const onBlur = e => {
		validateForm(e);
	}

	const validateForm = (key) => {
		let tmpArray = [...arrayForm];
		let tmpData = tmpArray.filter(x => x.name === key);
		if (tmpData && tmpData[0].required && tmpData[0].value === ''){
			setErrors(errors => ({...errors, [tmpData[0].name] : true}))
		}
    }

    const onSubmit = async() => {
        try{
            let newForm = new FormData()
            newForm.append('formData', JSON.stringify(formData));

            let res;
            if (isEdit){
                res = await produkService.putRoom(roomId, newForm);                
            }else{
                res = await produkService.postRoom(newForm);
            }
            
            const newData = await res;
            if (newData.sukses === 'no'){
                setAlertValue(alertValue => ({...alertValue,
                    show : true,
                    text : newData.pesan
                }));

                setTimeout(() => {
                    handleCloseAlert();
                }, 5000)
            }else{
                sessionStorage.removeItem('roomId');
                history.goBack();
            }
        }catch(error){
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Unable to save Room',                
            }));
            setTimeout(() => {
                handleCloseAlert();
            }, 5000)
        }
    }

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue,
            show: false,
            text : '',
            color : 'danger'
        }))
    }

    

    useEffect(() => {
		if (roomId !== 0) {            
            setIsEdit(true);           
            dispatch(fetchDetail(sessionStorage.getItem('roomId')));
        }			
    }, [roomId, dispatch]);
    

    useEffect(() => {
        if(isEdit){        
            setFormData(formData => ({...formData,
                nama : rooms.room.nama || '',
                tipeKamarId : rooms.room.tipe_kamar_id || 0,
                tipeBedId : rooms.room.tipe_bed_id || 0,
                harga : rooms.room.harga_jual || 0,
                id : rooms.room.id || 0
            }));
        }
    },[rooms.room, isEdit])
    
    return(
        <Fragment>
            <Alert color={alertValue.color} isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false}))}>
                {alertValue.text}
            </Alert>
            <CardTemplate
                xs={12}
                lg={12}
                headerTitle="Tambah Kamar"
            >
                <CardBody>
                    { (tipekamars.loading && tipebeds.loading) && <LoaderComponent/>}
                    { arrayForm.map((post, index) => {
                        return(
                            <FormTextComp
                                key={index}
                                onChange={handleChange}
                                errors={errors}
                                onBlur={() => onBlur(post.name)}
                                data={post}
                                formData={formData}
                            />
                        )
                    }) }
                </CardBody>
                <CardFooter>
                    <Button color="primary" size="sm" onClick={onSubmit}>
                        <i className="fa fa-save"></i>&nbsp;Simpan
                    </Button>
                    &nbsp;
                    <Button color="secondary" size="sm" onClick={() => history.goBack()}>
                        <i className="fa fa-undo"></i>&nbsp;Cancel
                    </Button>
                </CardFooter>
            </CardTemplate>
        </Fragment>
    )
}
export default RoomAddPage;
