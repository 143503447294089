import React from 'react';
import {  Button, ButtonGroup, Table } from 'reactstrap';

const ListKontak = (props) => {    
    return(
        <Table responsive>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Nama</th>
                    <th>Jenis Kelamin</th>
                    <th>HP</th>
                    <th>Alamat</th>
                    <th>Kota</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                { props.data && props.data.length > 0 && props.data.map((post, index) => {
                    return(
                        <tr key={index}>
                            <td onClick={() => props.clickList ? props.clickList(post) : null} style={{ cursor : props.clickList ? 'pointer' : 'inherit'}}>{post.nomor}</td>
                            <td onClick={() => props.clickList ? props.clickList(post) : null} style={{ cursor : props.clickList ? 'pointer' : 'inherit'}}>{post.nama}</td>
                            <td onClick={() => props.clickList ? props.clickList(post) : null} style={{ cursor : props.clickList ? 'pointer' : 'inherit'}}>{post.jk}</td>
                            <td onClick={() => props.clickList ? props.clickList(post) : null} style={{ cursor : props.clickList ? 'pointer' : 'inherit'}}>{post.hp}</td>
                            <td onClick={() => props.clickList ? props.clickList(post) : null} style={{ cursor : props.clickList ? 'pointer' : 'inherit'}}>{post.alamat}</td>
                            <td onClick={() => props.clickList ? props.clickList(post) : null} style={{ cursor : props.clickList ? 'pointer' : 'inherit'}}>{post.kabupaten_name}</td>
                            <td>
                                <ButtonGroup>
                                    <Button className='btn-edit' color='danger' onClick={() => props.handleDelete(post)}><i className='fa fa-trash'></i></Button>
                                    <Button className='btn-edit' color='primary' onClick={() => props.handleEdit(post)}><i className='fa fa-edit'></i></Button>
                                    <Button className='btn-edit' color='warning' onClick={() => props.handleCetak(post)}><i className='fa fa-print'></i></Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    )
                })}       
            </tbody>
        </Table>
    )
}
export default ListKontak;