import React, { useCallback, useEffect, Fragment, useState} from 'react';
import PerusahaanComp from '../../containers/Components/PerusahaanComp';
import * as kasbankService from '../../services/kasbankService'
import HeaderTransaksiComp from '../NonStay/Components/HeaderTransaksiComp';
import TransaksiDetailComp from '../NonStay/Components/TransaksiDetailComp';

const KasBankCetak = (props) => {
    const filter = JSON.parse(localStorage.getItem('kasBankParams')) || {
        start_date : '',
        end_date : '',
        kontakId : '',
        title : '',
        dr_tanggal : '',
        sd_tanggal : '',
        tipe_transaksi : 0, 
        kontakName : ''
    };
    const [listData, setListData] = useState({
        obj : [],
        list_page : []
    });


    const fetchData = useCallback(async(q='', page=1) => {
        try{
            const res = await kasbankService.getHutangPiutangDetail(`${q}&kontak_id=${filter.kontakId}&tipe_transaksi=${filter.tipe_transaksi}&start_date=${filter.start_date}&end_date=${filter.end_date}`, 0, 1);
            setListData(listData => ({...listData, obj : res.data, list_page : res.list_page}))
        }catch(error){
            console.log('error', error);
        }
    },[filter.end_date, filter.kontakId, filter.start_date, filter.tipe_transaksi]);

    useEffect(() => {
        fetchData();        
    },[fetchData])

    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp className='margin-bottom-10'/>
                <HeaderTransaksiComp
                    title={filter.title ? filter.title : `Daftar Transaksi Non Stay`}
                    desc={`tanggal ${filter.dr_tanggal} s.d tanggal ${filter.sd_tanggal}`}
                    kontakName={filter.kontakName}
                    className='margin-bottom-5'
                />
                <TransaksiDetailComp
                    listData={listData.obj}
                    showAction={false}
                />
            </div>
        </Fragment>
    )
}
export default KasBankCetak;