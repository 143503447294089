import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, FormGroup, Row, Table } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import * as penjualanService from '../../../services/penjualanService';

import '../../../style/struk.css';
const FbStrukCetak = (props) => {
    const penjualanId = localStorage.getItem('penjualanId') || 208;
    const [penjualan, setPenjualan] = useState({});

    const fetchData = useCallback(async() => {
        try{
            const res = await penjualanService.getPenjualanResto(`id=${penjualanId}`);            
            setPenjualan(res.data);
        }catch(error){
            console.log(error);
        }
    },[penjualanId])

    useEffect(() => {
        fetchData();
    },[fetchData])

    return(
        <Fragment>
            <div className='strukprint-container'>
                <PerusahaanComp/>
                <div className="text-center title">Nota Penjualan</div>
                <div className="text-center desc" style={{borderBottom: '1px solid #cecece'}}>{penjualan.no_invoice}</div>
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col xs={6}>
                                <FormGroup className='box-tanggal'>
                                    <div>Date :</div>
                                    <div>{penjualan.tanggal}</div>
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup className='box-tanggal'>
                                    <div className='text-right'>Kasir :</div>
                                    <div className='text-right'>{penjualan.user}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div style={{borderBottom: '1px solid #cecece'}}></div>
                <Table>
                    <tbody>
                        { penjualan.detail && penjualan.detail.map((post, index) => {
                            return(
                                <tr key={index}>
                                    <td>{`${post.nama_produk}`}</td>
                                    <td>{`${post.qty} x ${post.harga_jual}`}</td>
                                    <td className='text-right'>{`${post.sub_total.toLocaleString()}`}</td>
                                </tr>
                            )
                        })}     
                        <tr className="tr-border">
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>                   
                        <tr className='tr-total'>
                            <td colSpan={2} className='text-right'>Subtotal</td>
                            <td className='text-right'>{penjualan.total_invoice_detail && penjualan.total_invoice_detail.toLocaleString()}</td>
                        </tr>
                        { (parseInt(penjualan.diskon_nominal) > 0) &&                            
                            <tr className='tr-total'>
                                <td colSpan={2} className='text-right'>Diskon</td>
                                <td className='text-right'>-{penjualan.diskon_nominal && penjualan.diskon_nominal.toLocaleString()}</td>
                            </tr>
                        }
                        { parseInt(penjualan.total_pajak) > 0 &&
                            <Fragment>
                                <tr className='tr-total'>
                                    <td colSpan={2} className='text-right'>Tax</td>
                                    <td className='text-right'>{penjualan.total_pajak && penjualan.total_pajak.toLocaleString()}</td>
                                </tr>                                                  
                                <tr className='tr-total'>
                                    <td colSpan={2} className='text-right'>Total</td>
                                    <td className='text-right'>{penjualan.total_invoice && penjualan.total_pajak && (parseInt(penjualan.total_invoice)+parseInt(penjualan.total_pajak)).toLocaleString()}</td>
                                </tr>                        
                            </Fragment>
                        }
                    </tbody>
                </Table>
                { penjualan.tipe_pembayaran && [2,5].includes(parseInt(penjualan.tipe_pembayaran)) &&
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <div className='text-center'>Bill Rcvr</div>
                                <div className='text-center'>{penjualan.tipe_pembayaran_display} :</div>
                                <div className='text-center' style={{marginTop: 60}}>{penjualan.konsumen_name}</div>
                            </FormGroup>
                        </Col>
                    </Row>
                
                }
            </div>
        </Fragment>
    )
}
export default FbStrukCetak