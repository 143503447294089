import api from './api';

export function getKasBank(keyword, limit, page){
    return api.get(`/accounting/api/kas-bank?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function getHutangPiutang(keyword, limit, page){
    return api.get(`/accounting/api/transaksi-kas?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function getHutangPiutangDetail(keyword, limit, page){
    return api.get(`/accounting/api/transaksi-kas-detail?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function getResumeTransaksi(keyword, limit, page){
    return api.get(`/accounting/api/resume-transaksi-kas?${keyword}`, {params:{limit, page}}).then(res => res.data);
}


export function pembayaranTransaksi(form){
    return api.post(`/accounting/api/pembayaran-transaksi`, form).then(res => res.data);
}

export function postDownPayment(form){
    return api.post(`/accounting/api/down-payment`, form).then(res => res.data);
}

export function refundDownPayment(id, form){
    return api.post(`/accounting/api/refund-down-payment/${id}`, form).then(res => res.data);
}