import React, { Fragment,  } from 'react';
import NumberFormat from 'react-number-format';

import { Alert, Badge, Button, CardBody, Col, FormGroup, Input, Label, Row, Card } from 'reactstrap';
import BillingComp from './Components/BillingComp';
import { useCheckout } from './checkoutFunction';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import { AppSwitch } from '@coreui/react';


const CheckoutPage = (props) => {
    const { guests, additionalTrx, totalValue,
        handleChangeLate, handleBlurLate,
        handleAddTrx, showRefund, handleChange, formData,
        handleChangeSwitch, methodPembayaran,
        handleSubmit, alertValue, handleCloseAlert, isLoading
    } = useCheckout();
    
    return(
        <Fragment>
            { (isLoading || guests.loading) &&
                <LoaderComponent/>
            }
            { guests.guest && parseInt(guests.guest.nilai_late_checkout) > 0 &&
                <Alert isOpen={true} color="danger">
                    {`Transaksi ini checkout sudah melebihi jam checkout, total late Checkout sebesar Rp. ${guests.guest.nilai_late_checkout.toLocaleString()} tambahkan transaksi late checkout`}
                </Alert>
            }
            <Alert isOpen={alertValue.show} toggle={handleCloseAlert} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <Row>
                <Col 
                    lg={7}
                    xs={12}
                >
                    <Card>
                        <CardBody style={{padding: 0}}>
                            <div style={{padding: '1.25rem', borderBottom: '1px solid #cecece'}}>
                                Data Pelanggan
                            </div>
                            <div style={{padding: '1.25rem'}} className='box-billing-detail'>
                                <BillingComp
                                    data={guests.guest}
                                    latecheckoutArray={additionalTrx}
                                    totalValue={totalValue}
                                    handleChangeLate={handleChangeLate}
                                    handleBlurLate={handleBlurLate}
                                    handleAddTrx={handleAddTrx}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col
                    lg={5}
                    xs={12}
                >
                    <Card>
                        <CardBody style={{padding: 0}}>
                            <div style={{padding: '1.25rem', borderBottom: '1px solid #cecece'}}>
                                Pembayaran
                            </div>
                            <div style={{padding: '1.25rem'}} className='box-billing-detail box-bayar'>
                                { showRefund &&
                                    <div className='hidden' style={{display: 'none'}}>
                                        <FormGroup row style={{borderBottom: '1px solid #cecece', fontWeight: 'bold'}} className='refund-box'>
                                            <Col lg={4}>
                                                <Label style={{color : '##9b0101'}}>Refund Deposite</Label>
                                            </Col>
                                            <Col lg={8}>                                    
                                                <NumberFormat
                                                    thousandSeparator={true}
                                                    inputMode="numeric"
                                                    className={"form-control text-right"}
                                                    name='refund_deposite'
                                                    value={formData.refund_deposite} 
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </FormGroup>     
                                    </div>
                                }
                                { (parseInt(formData.saldo_dp) > 0) &&
                                    <Fragment>
                                        <FormGroup row>
                                            <Col lg={4}>
                                                <Label>Gunakan DP</Label>
                                            </Col>
                                            <Col lg={8}>
                                                <AppSwitch className={'mx-1'} variant={'pill'} color={'primary'} checked={formData.show_use_dp} onChange={handleChangeSwitch}/>
                                            </Col>
                                        </FormGroup>     
                                        <FormGroup row>
                                            <Col lg={4}>
                                                <Label>Saldo DP</Label>
                                            </Col>
                                            <Col lg={8}>
                                                <Badge color="success" className="float-right">
                                                    {formData.saldo_dp.toLocaleString()}
                                                </Badge>
                                            </Col>
                                        </FormGroup>     

                                    </Fragment>
                                }
                                <FormGroup row>
                                    <Col lg={6}>Subtotal</Col>
                                    <Col lg={6} style={{textAlign: 'right'}}>{formData.sub_total.toLocaleString()}</Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col lg={6}>Diskon</Col>
                                    {/* <Col lg={6} style={{textAlign: 'right'}}>{formData.diskon.toLocaleString()}</Col> */}
                                    <Col lg={6}>
                                        <Input
                                            type="text"
                                            name="diskon"
                                            value={formData.diskon}
                                            onChange={handleChange}
                                            placeholder="Diskon"
                                            style={{
                                                // display: 'none',
                                                textAlign: 'right'
                                            }}
                                            // className
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col lg={6}>Total</Col>
                                    <Col lg={6} style={{textAlign: 'right'}}>{formData.total.toLocaleString()}</Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col lg={6}>Deposite</Col>
                                    <Col lg={6} style={{textAlign: 'right'}}>{formData.deposite.toLocaleString()}</Col>
                                </FormGroup>                            
                                { formData.lebih_bayar > 0 &&
                                    <Fragment>
                                        <FormGroup row style={{borderBottom: '1px solid #cecece'}} className='box-lebih'>
                                            <Col lg={5}>Lebih Bayar</Col>
                                            <Col lg={7} style={{textAlign: 'right'}}>{formData.lebih_bayar.toLocaleString()}</Col>
                                        </FormGroup>
                                    </Fragment>
                                }
                                { formData.kurang_bayar > 0 &&
                                    <Fragment>
                                        <FormGroup row style={{borderBottom: ![3,4].includes(parseInt(formData.method_pembayaran)) ? '1px solid #cecece' : ''}} className={![3,4].includes(parseInt(formData.method_pembayaran)) ? 'box-lebih' : ''}>
                                            <Col lg={5}>Method Pembayaran</Col>
                                            <Col lg={7} style={{textAlign: 'right'}}>
                                                <Input
                                                    onChange={handleChange}
                                                    value={formData.method_pembayaran}
                                                    name='method_pembayaran'
                                                    type='select'
                                                >
                                                    <option value=''>-- Pilih Method Pembayaran --</option>
                                                    { methodPembayaran.map((post, index) => {
                                                        return(
                                                            <option value={post.value} key={index}>{post.label}</option>
                                                        )
                                                    })}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        { (parseInt(formData.method_pembayaran) === 3 || parseInt(formData.method_pembayaran) === 4) &&
                                            <FormGroup row style={{borderBottom: '1px solid #cecece'}} className='box-lebih'>
                                                <Col lg={5}>Card Remark</Col>
                                                <Col lg={7}>
                                                    <Input
                                                        name='nomor_kartu'
                                                        value={formData.nomor_kartu}
                                                        onCHange={handleChange}
                                                        type='text'
                                                        placeHolder="Misal BNI 2202-2200-2000"
                                                    />
                                                </Col>
                                            </FormGroup>
                                        }
                                        <FormGroup row>
                                            <Col lg={5}>Kurang Bayar</Col>
                                            <Col lg={7} style={{textAlign: 'right'}}>{formData.kurang_bayar.toLocaleString()}</Col>
                                        </FormGroup>
                                    </Fragment>
                                }
                                { formData.show_use_dp &&
                                <FormGroup row>
                                    <Col lg={5}>Gunakan DP</Col>
                                    <Col lg={7} style={{textAlign: 'right'}}>
                                        <NumberFormat
                                            thousandSeparator={true}
                                            inputMode="numeric"
                                            className={"form-control text-right"}
                                            name='use_dp'
                                            value={formData.use_dp} 
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                            }
                            {/* { (formData.kurang_bayar > 0 && (parseInt(formData.method_pembayaran) !== 2 && parseInt(formData.method_pembayaran) !== 7 && parseInt(formData.method_pembayaran) !== 5)) && */}
                                <Fragment>
                                    <FormGroup row>
                                        <Col lg={5}>Pembayaran</Col>
                                        <Col lg={7} style={{textAlign: 'right'}}>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                inputMode="numeric"
                                                className={"form-control text-right"}
                                                name='bayar'
                                                value={formData.bayar} 
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col lg={5}>Balance</Col>
                                        <Col lg={7} style={{textAlign: 'right'}}>
                                            {formData.kembali.toLocaleString()}
                                        </Col>
                                    </FormGroup>
                                </Fragment>
                            
                            <div style={{borderTop : '1px solid #cecece', marginBottom: '10px'}}></div>
                            <Button color="primary" size="sm" onClick={handleSubmit}>Simpan</Button>
                            &nbsp;<Button color="secondary" size="sm" onClick={() => props.history.push('/fo')}>Cancel</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}
export default CheckoutPage