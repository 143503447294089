import React, {Fragment, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import PerusahaanComp from '../../containers/Components/PerusahaanComp';
import { fetchPiutang } from '../../slices/piutang';
import HeaderTransaksiComp from '../NonStay/Components/HeaderTransaksiComp';

const KasBankGlobalCetak = (props) => {
    const dispatch = useDispatch();
    const { piutangs } = useSelector(state => state);
    const filter = JSON.parse(localStorage.getItem('kasBankGlobalParams')) || {
        start_date : '',
        end_date : '',
        kontakId : '',
        title : '',
        dr_tanggal : '',
        sd_tanggal : '',
        tipe_transaksi : 0, 
        kontakName : ''
    };

    useEffect(() => {        
        dispatch(fetchPiutang(`tipe_transaksi=${filter.tipe_transaksi}&start_date=${filter.start_date}&end_date=${filter.end_date}`));
    },[dispatch, filter.tipe_transaksi, filter.start_date, filter.end_date])

    return(
        <Fragment>
            <div className='billprint-container'>
                <PerusahaanComp className='margin-bottom-10'/>
                <HeaderTransaksiComp
                    title={filter.title}
                    desc={`tanggal ${filter.dr_tanggal} s.d tanggal ${filter.sd_tanggal}`}
                    kontakName={false}
                    hideKontakName={true}
                    className='margin-bottom-5'
                />
                <Table bordered className='table-center'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Tanggal</th>
                            <th>Nomor Invoice</th>
                            <th>Nama Konsumen</th>
                            <th>Pic Posting</th>
                            <th>Debet</th>
                            <th>Kredit</th>
                        </tr>
                    </thead>
                    <tbody>
                        { piutangs.piutangs.map((post, index) => {
                            return(
                                <tr key={index}>
                                    <td>{post.nomor}</td>
                                    <td>{post.tanggal}</td>
                                    <td>{post.no_trx}</td>
                                    <td>{post.konsumen_name}</td>
                                    <td>{post.kontak_name}</td>
                                    <td className='text-right'>{post.ammdb.toLocaleString()}</td>
                                    <td className='text-right'>{post.ammcr.toLocaleString()}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    )
}
export default KasBankGlobalCetak;