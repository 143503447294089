import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody, CardFooter } from 'reactstrap';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { fetchGuestDetail } from '../../../slices/guests';
import BillingComp from './Components/BillingComp';

const BillingDetailPage = (props) => {
    const dispatch = useDispatch();
    const { guests } = useSelector(state => state);
    const billingId = sessionStorage.getItem('billingId') || 0
    const [totalValue, setTotalValue] = useState({
        sub_total : 0,
        total_diskon : 0,
        total_pajak : 0,
        total : 0,
        last_num : 0
    })

    useEffect(() => {
        dispatch(fetchGuestDetail(billingId))
    }, [dispatch, billingId])

    const handleEdit = () => {
        props.history.push('/fo/meetingroom-checkin')
    }

    useEffect(() => {
        let nomor = 0;
        if (guests.guest.billing_detail && guests.guest.billing_detail.length > 0){
            nomor = guests.guest.billing_detail[guests.guest.billing_detail.length-1].nomor;
        }else{
            if (guests.guest.room_detail && guests.guest.room_detail.length > 0 ){
                nomor = guests.guest.room_detail[guests.guest.room_detail.length-1].nomor
            }
        }
        setTotalValue(totalValue => ({...totalValue,
            sub_total : guests.guest.sub_total || 0,
            total_diskon : guests.guest.total_diskon || 0,
            total_pajak : guests.guest.total_pajak || 0,
            total : guests.guest.total || 0,
            last_num : nomor
        }))
    },[guests.guest]);
    
    return(
        <Fragment>
            <CardTemplate
                headerTitle="Tagihan Detail"
                cardClassName='box-billing-detail'
            >
                <CardBody>
                    <BillingComp
                        data={guests.guest}
                        totalValue={totalValue}
                        hiddenAddButton={true}
                    />                    
                </CardBody>
                {/* { (guests.guest.tipe_penjualan === 3 ) && */}
                { (guests.guest.tipe_penjualan === 3 && !guests.guest.is_close) &&
                    <CardFooter>
                        <Button color="primary" size="sm" onClick={handleEdit}>Edit</Button>
                    </CardFooter>
                }
            </CardTemplate>
        </Fragment>
    )
}   
export default BillingDetailPage;