import React from 'react';
import { FormGroup, Col, InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';


const SearchComp = (props) => {
    return(
        <FormGroup row>
            <Col md="12">
                <InputGroup>
                    <Input type="text" id="input1-group2" name="search" placeholder={props.placeHolder} value={props.value} onChange={props.onChange} onKeyDown={props.onKeyDown}/>
                    <InputGroupAddon addonType="append">
                        <Button type="button" size="sm" color="primary" onClick={props.onClick}><i className="fa fa-search"></i> Search</Button>
                    </InputGroupAddon>
                </InputGroup>
            </Col>
        </FormGroup>
    )
}
export default SearchComp;