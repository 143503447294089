import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import PerusahaanComp from '../../../../containers/Components/PerusahaanComp';
import * as checkinService from '../../../../services/checkinService';
import HeaderTransaksiComp from '../../../NonStay/Components/HeaderTransaksiComp';
import GuestListCetakComp from '../Components/GuestListCetakComp';

const InHouseCetak = (props) => {
    const filter = JSON.parse(localStorage.getItem('inHouseParams')) || {
        start_date : '',
        end_date : '',
        dr_tanggal : '',
        sd_tanggal : '',
        state : 'all',
        tipe_penjualan : '',
        title : ''
    };
    const [listData, setListData] = useState([]);

    const fetchData = useCallback(async() => {
        try{
            const res = await checkinService.getGuestbyDetail(`tipe_penjualan=${filter.tipe_penjualan}&filter=${filter.state}&start_date=${filter.start_date}&end_date=${filter.end_date}`)
            setListData(res.data);
        }catch(error){
            console.log(error);
        }
    },[filter.state, filter.start_date, filter.end_date, filter.tipe_penjualan])

    useEffect(() => {
        fetchData();
    },[fetchData])
    
    return(
        <Fragment>            
            <div className="billprint-container landscape">
                <PerusahaanComp/>
                <HeaderTransaksiComp
                    title={filter.title}
                    desc={`tanggal ${filter.dr_tanggal} s.d tanggal ${filter.sd_tanggal}`}
                    className='margin-bottom-5'
                    hideKontakName={true}
                />
                <GuestListCetakComp
                    data={listData}
                    hideButton={true}
                    className='table-extra-small'
                />
            </div>
        </Fragment>
    )
}
export default InHouseCetak;