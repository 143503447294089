import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, CardBody, CardFooter, Col, FormGroup, Label, Modal,  Row } from 'reactstrap';
import CardTemplate from '../../../containers/Components/CardTemplate';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
import Moment from 'moment';
import * as checkinService from '../../../services/checkinService'
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import KontakPage from '../../Kontak/Kontak/KontakPage';
import KontakAddPage from '../../Kontak/Kontak/KontakAddPage';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTipeKamar } from '../../../slices/tipekamar';
const ReservationAddPage = (props) => {
    const arrayForm = [
        {
            name : 'jenis_konsumen',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : 1,
            label : 'Jenis Konsumen *',
            tipe : 'select',
            showError : false,            
            show : true,            
            obj : [{value : 1, label: 'Perorangan'},{value: 2, label : 'Perusahaan'},{value : 3, label : 'Travel'}, {value : 4, label : 'Pemerintahan'}],
            sequence : 1,
        },
        {
            name : 'konsumen_name',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nama Tamu *',
            tipe : 'input_button',
            showError : false,            
            show : true,
            readOnly : true,
            onClick : function(){
                handleClickKonsumen();
            },
            sequence : 2,            
        },
        {
            name : 'pic_name',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nama Tamu *',
            tipe : 'input_button',
            showError : false,            
            show : true,
            readOnly : true,
            onClick : function(){
                handleClickPic();
            },
            sequence : 2,            
        },
        {
            name : 'jumlah_kamar',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : 1,
            label : 'Jumlah Kamar *',
            tipe : 'number',
            showError : false,            
            show : true,            
            sequence : 2,            
        },
        {
            name : 'jumlah_tamu',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : 1,
            label : 'Jumlah Tamu *',
            tipe : 'number',
            showError : false,            
            show : true,
            sequence : 2,            
        },
        {
            name : 'remark',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Remark *',
            tipe : 'text',
            showError : false,            
            show : true,
            sequence : 2,            
        },
        {
            name : 'tgl_checkin',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : null,
            label : 'Tanggal Checkin *',
            tipe : 'date',
            showError : false,            
            show : true,
            sequence : 2,            
        },
        {
            name : 'lama_menginap',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Lama Menginap *',
            tipe : 'number',
            showError : false,            
            show : true,
            sequence : 2,            
        },
        {
            name : 'tgl_checkout',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : null,
            label : 'Tanggal Checkout *',
            tipe : 'date',
            showError : false,            
            show : true,
            sequence : 2,            
        },
        {
            name : 'tipe_kamar',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Tipe Kamar *',
            tipe : 'select',
            showError : false,            
            show : true,
            sequence : 2, 
            obj : []           
        },
        {
            name : 'konsumen_id',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'konsumen_id *',
            tipe : 'text',
            showError : false,            
            show : false,
            readOnly : true,
            sequence : 2,            
        },
        {
            name : 'pic_id',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'pic_id *',
            tipe : 'text',
            showError : false,            
            show : false,
            readOnly : true,
            sequence : 2,            
        },
        {
            name : 'state',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : 1,
            label : 'Status *',
            tipe : 'select',
            showError : false,            
            show : false,
            readOnly : true,
            sequence : 2,
            obj : [
                {label : 'Reservation', value : 1},
                {label : 'Cancel', value : 3},
            ]            
        },
    ]
    const initForm = arrayForm.reduce((obj, item) => Object.assign(obj, { [item.name] : item}), {});
    const [formData, setFormData] = useState(initForm)
    const [isEdit, setIsEdit] = useState(false);
    const reservationId = sessionStorage.getItem('reservationId') || 0;
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { tipekamars } = useSelector(state => state);
    const [modalValue, setModalValue] = useState({
        show : false,
        tipe : 'konsumen',
        content : 'list',
        title : 'Daftar Tamu'
    })
    const [alertValue, setAlertValue] = useState({
        show: false,
        text : '',
        color : 'danger'
    })
    
    const handleChange = (e) => {
        const { name, value } = e.target;        
        setFormData(formData => ({...formData, [name] : {...formData[name], value: value}}))
        let title = ''
        let nilai = value;
        if (name === 'jumlah_kamar' || name === 'jumlah_tamu' || name === 'lama_menginap'){
            nilai = nilai.replace(/,/g, '');
        }
        if (name === 'jenis_konsumen'){
            if (parseInt(nilai) === 1){ 
                title = 'Tamu'
                setFormData(formData => ({...formData, 
                    konsumen_name : {...formData.konsumen_name, label : 'Nama Tamu *'},
                    pic_id : {...formData.pic_id, required : false},
                    [name] : {...formData[name], value : nilai}
                }));                
            }else { // travel                
                if (parseInt(nilai) === 2){
                    title = 'Perusahaan'
                }else if (parseInt(nilai) === 3){
                    title = "Travel";
                }else{
                    title = "Pemerintahan"
                }
                setFormData(formData => ({...formData, 
                    konsumen_name : {...formData.konsumen_name, label : `Nama ${title} *`},
                    pic_id : {...formData.pic_id, required : true},
                    [name] : {...formData[name], value : nilai}
                }))
            }
            setModalValue(modalValue => ({...modalValue, title : `Daftar ${title}`}))
        }else if (name === 'lama_menginap'){
            let date = new Date(formData.tgl_checkin.value);
            let result = date.setDate(date.getDate() + parseInt(nilai));
            result = Moment(result).format('DD-MM-YYYY');
            setFormData(formData => ({...formData, 
                tgl_checkout : {...formData.tgl_checkout, value : result},
                [name] : {...formData[name], value : nilai}
            }))
        }else{
            setFormData(formData => ({...formData, [name] : {...formData[name], value : nilai}}))
        }         
    }

    useEffect(() => {
        dispatch(fetchTipeKamar('', 100, 1));
    }, [dispatch])

    useEffect(() => {
        let tmp = [{value : '', label : '-- Pilih Tipe Kamar --'}];
        tipekamars.tipekamars.map(post => tmp.push({value : post.id, label : `${post.nama} - Publish Rate Rp.${post.harga.toLocaleString()}`}));
        setFormData(formData => ({...formData, tipe_kamar : {...formData.tipe_kamar, obj : tmp}}))
    },[tipekamars]);

    const handleBlur = (e) => {
        const { name, value } = e.target;        
        if (formData[name].required && (value === '' || parseInt(value) === 0 || value === null)){
            setFormData(formData => ({...formData, [name] : {...formData[name], showError: true}}))
        }else{
            setFormData(formData => ({...formData, [name] : {...formData[name], showError: false}}))
        }
    }

    const handleChangeDate = (e) => {        
        setFormData(formData => ({...formData, tgl_checkin : {...formData.tgl_checkin, value : e}}));
        if (parseInt(formData.lama_menginap.value) > 0){
            let date = new Date(e);
            date = date.setDate(date.getDate() + parseInt(formData.lama_menginap.value));
            let tgl_checkout = Moment(date).format('DD-MM-YYYY');
            setFormData(formData => ({...formData, tgl_checkout : {...formData.tgl_checkout, value : tgl_checkout}}))
        }
    }

    const handleClickKonsumen = () => {
        let title = modalValue.title;
        title = title.replace('Tambah ', '');
        let tipe = 'konsumen'
        if (parseInt(formData.jenis_konsumen.value) === 1){
            // title = "Tamu";
            tipe = 'konsumen'
        }else if (parseInt(formData.jenis_konsumen.value) === 2){
            // title = "Perusahaan";
            tipe = 'perusahaan'
        }else if (parseInt(formData.jenis_konsumen.value) === 3){
            // title = "Travel"
            tipe = 'travel'    
        }else if (parseInt(formData.jenis_konsumen.value) === 4){
            // title = "Pemerintahan"
            tipe = 'pemerintahan'
        }
        setModalValue(modalValue => ({...modalValue,
            show : true,
            tipe : tipe,
            content : 'list',
            title : title
        }))
    }

    const handleClickPic = () => {
        setModalValue(modalValue => ({...modalValue,
            show : true,
            tipe : 'pic',
            content : 'list',
            title : `Daftar Tamu`
        }))
    }

    const attrInput = (nilai) => {        
        let tmp = {}
        tmp['tipe'] = formData[nilai].tipe || ''
        tmp['name'] = nilai;
        tmp['onChange'] = handleChange        
        tmp['required'] =  formData[nilai].required  || ''
        tmp['onBlur'] = handleBlur || ''
        tmp['show'] = formData[nilai].show || ''
        tmp['obj'] = formData[nilai].obj || ''
        tmp['value'] = formData[nilai].value || ''
        tmp['onChangeDate'] = handleChangeDate || ''
        tmp['showError'] = formData[nilai].showError || ''
        tmp['errorMsg'] = formData[nilai].errorMsg || ''
        tmp['onClick'] = formData[nilai].onClick || ''
        return tmp;
    }

    const labelInput = (nilai) => {
        return formData[nilai].label        
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let array = {...formData};            
            let countError = 0;
            
            Object.entries(array).map(([index, post]) => {
                if (post.required && (post.tipe === 'text' || post.tipe === 'select')){
                    if (post.value === ''){
                        post.showError = true;
                        countError++;
                    }else{
                        post.showError = false;
                    }
                }
                if (post.required && post.tipe === 'date'){
                    if (post.value === null){
                        post.showError = true;
                        countError++;
                    }else{
                        post.showError = false
                    }
                }
                if (post.required && post.tipe === 'number'){
                    if (post.value === '' || parseInt(post.value) === 0){
                        post.showError = true;
                        countError++;
                    }else{
                        post.showError = false
                    }
                }
                return true;
            });
            if (countError > 0){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi data', color : 'danger'}));
                setFormData(array);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))
                },5000);
                return;
            }

            let tmpForm = {}
            Object.entries(array).map(([index, post]) => tmpForm[index] = post.value)            
            let checkout = new Date(formData.tgl_checkin.value);
            checkout = checkout.setDate(checkout.getDate() + parseInt(formData.lama_menginap.value));
            let tgl_checkout = Moment(checkout).format('YYYY-MM-DD');
            
            tmpForm['tgl_checkin'] = Moment(formData.tgl_checkin.value).format('YYYY-MM-DD');
            tmpForm['tgl_checkout'] = tgl_checkout;
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
            let res;
            if (isEdit){
                res = await checkinService.putReservation(reservationId, newForm);
            }else{
                res = await checkinService.postReservation(newForm);
            }
            setIsLoading(false);
            if (res.sukses === 'yes'){
                props.history.push('/fo/reservation')
            }else{
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan, color : 'danger'}))
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))
                }, 5000)
            }            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to save reservation room', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))
            }, 5000)
        }
    }

    const handleCloseModal = () => {
        setModalValue(modalValue => ({...modalValue,
            show: false,
            tipe: 'konsumen',
            content : 'list',
            title : 'Daftar Tamu'
        }))
    }

    const handleAddKontak = () => {
        let title = modalValue.title
        title = `Tambah ${title}`
        setModalValue(modalValue => ({...modalValue, content: 'form', title : title}))
    }

    const handleCancelModal = () => {
        let title = modalValue.title;
        title = title.replace('Tambah ', '');
        setModalValue(modalValue => ({...modalValue, content: 'list', title : title}))
    }

    const selectKontak = (post) => {
        if (modalValue.tipe === 'pic'){
            setFormData(formData => ({...formData, 
                pic_name : {...formData.pic_name, value : post.nama},
                pic_id : {...formData.pic_id, value : post.id}
            }))
        }else{
            setFormData(formData => ({...formData, 
                konsumen_name : {...formData.konsumen_name, value : post.nama},
                konsumen_id : {...formData.konsumen_id, value : post.id}
            }))
        }
        setModalValue(modalValue => ({...modalValue, show: false}))
    }
        
    const fetchEdit = useCallback(async() => {
        if (reservationId !== 0){
            setIsEdit(true);
            setIsLoading(true);
            try{
                const res = await checkinService.getReservation(`id=${reservationId}`);
                setIsLoading(false);
                if (res.sukses === 'yes'){
                    setFormData(formData => ({...formData,
                        tgl_checkin : {...formData.tgl_checkin, value : Moment(res.data.tgl_checkin, 'YYYY-MM-DD').toDate() || null},
                        jenis_konsumen : {...formData.jenis_konsumen, value : res.data.tipe_kontak || 1},
                        konsumen_name : {...formData.konsumen_name, value : res.data.kontak_name || ''},
                        pic_name : {...formData.pic_name, value : res.data.pic_kontak_name || ''},
                        jumlah_kamar : {...formData.jumlah_kamar, value : res.data.jml_kamar || 1},
                        jumlah_tamu : {...formData.jumlah_tamu, value : res.data.jml_tamu || 1},                                
                        lama_menginap : {...formData.lama_menginap, value : res.data.lama_menginap || ''},
                        tgl_checkout : {...formData.tgl_checkout, value : res.data.tgl_checkout || null},
                        tipe_kamar : {...formData.tipe_kamar, value : res.data.tipe_kamar || ''},
                        konsumen_id : {...formData.konsumen_id, value : res.data.kontak_id || ''},
                        pic_id : {...formData.pic_id, value : res.data.pic_kontak_id || ''},            
                        state : {...formData.state, value : res.data.state, show: true || ''},
                        remark : {...formData.remark, value : res.data.remark, show: true || ''},
                    }))
                }
            }catch(error){            
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to save reservation room', color : 'danger'}))
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))
                }, 5000)
            }
        }
    },[reservationId])

    useEffect(() => {
        fetchEdit();
    },[fetchEdit])
    
    
    
    return(
        <Fragment>
            <Modal isOpen={modalValue.show} toggle={handleCloseModal} size='lg'>                
                { modalValue.content === 'list' ?                         
                    <KontakPage
                        modal={true}
                        query={modalValue.tipe === 'pic' ?  `tipe_kontak=1` : `tipe_kontak=${formData.jenis_konsumen.value}`}
                        handleAdd={handleAddKontak}
                        title={modalValue.title}
                        selectKontak={selectKontak}
                    />
                    :
                    <KontakAddPage
                        hideJenisKontak={true}
                        tipe_kontak={modalValue.tipe === 'pic' ? 1 : formData.jenis_konsumen.value}
                        modal={true}
                        title={modalValue.title}
                        handleAdd={handleCancelModal}
                    />
                }
                
            </Modal>
            { isLoading &&
                <LoaderComponent/>
            }
            <Alert isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false, text : '', color: 'danger'}))} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <CardTemplate
                headerTitle="Add Reservation Room"
            >
                <CardBody>
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6}>
                                    <Label>{labelInput('jenis_konsumen')}</Label>
                                    <FormTextSingleComp                                        
                                        {...attrInput('jenis_konsumen')}
                                        name='jenis_konsumen'
                                    />                                  
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('konsumen_name')}</Label>
                                        <FormTextSingleComp                                        
                                            {...attrInput('konsumen_name')}
                                            name='konsumen_name'
                                        />
                                    </FormGroup>
                                </Col>                            
                                { parseInt(formData.jenis_konsumen.value) !==  1 &&
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label>{labelInput('pic_name')}</Label>
                                            <FormTextSingleComp
                                                {...attrInput('pic_name')}
                                                name='pic_name'
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('jumlah_kamar')}</Label>
                                        <FormTextSingleComp
                                            {...attrInput('jumlah_kamar')}
                                            name='jumlah_kamar'
                                            className='text-right'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('jumlah_tamu')}</Label>
                                        <FormTextSingleComp
                                            {...attrInput('jumlah_tamu')}
                                            name='jumlah_tamu'
                                            className='text-right'
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('tgl_checkin')}</Label>
                                        <FormTextSingleComp
                                            {...attrInput('tgl_checkin')}
                                            name='tgl_checkin'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('lama_menginap')}<i>(Hari)</i></Label>
                                        <FormTextSingleComp
                                            {...attrInput('lama_menginap')}
                                            name='lama_menginap'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('tgl_checkout')}</Label>
                                        <div>
                                            {formData.tgl_checkout.value}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('tipe_kamar')}</Label>
                                        <FormTextSingleComp
                                            {...attrInput('tipe_kamar')}
                                            name='tipe_kamar'
                                        />
                                    </FormGroup>
                                </Col>
                                { formData.state.show &&
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label>{labelInput('state')}</Label>
                                            <FormTextSingleComp
                                                {...attrInput('state')}
                                                name='state'
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('remark')}</Label>
                                        <FormTextSingleComp
                                            {...attrInput('remark')}
                                            name='remark'
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <ButtonGroup>
                        <Button size="sm" color="primary" onClick={handleSubmit}>Simpan</Button>
                        <Button size="sm" color="secondary" onClick={() => props.history.goBack()}>Kembali</Button>
                    </ButtonGroup>
                </CardFooter>
            </CardTemplate>
        </Fragment>
    )
}
export default ReservationAddPage;