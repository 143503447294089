import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
const HeaderTransaksiComp = (props) => {    
    const kontak_label = props.kontak_label || "Nama Debitur";
    return(
        <div className={props.className ? props.className : ''}>
            <div className="transaksi-kas-title text-center">{props.title}</div>
            <div className="transaksi-kas-desc text-center">{props.desc}</div>
            { !props.hideKontakName &&
                <Row>
                    <Col lg={6}>
                        <FormGroup row>
                            <Col lg={4}>{kontak_label}</Col>
                            <Col lg={8}>: {props.kontakName}</Col>
                        </FormGroup>
                    </Col>
                </Row>
            }
        </div>
    )
}
export default HeaderTransaksiComp;