import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody, Col, Row, Table, Alert, Pagination, PaginationItem, PaginationLink, ButtonGroup, Collapse } from 'reactstrap';
import CardTemplate from '../../../containers/Components/CardTemplate';
import SearchComp from '../../../containers/Components/SearchComp';
import { fetchReservation } from '../../../slices/reservation';
import * as kontakService from '../../../services/kontakService';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import config from '../../../config';
import Moment from 'moment';
import DatePicker from "react-datepicker";

const ReservationPage = (props) => {
    const dispatch = useDispatch();
    const { reservations } = useSelector(state => state);
    const [pageValue, setPageValue] = useState({
        page : 1,
        search : '',
        filter : 0,
        tanggal : null,
        tanggal_value : '',
        filter_status : 1
    });
    const [tipeKontak, setTipeKontak] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : '',
        color : 'danger'
    });
    const [filterCollapse, setFilterCollapse] = useState(false);
    const fetchTipeKontak = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await kontakService.getTipeKontak();
            setIsLoading(false);
            if (res.sukses === 'no'){
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan,value : ''}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue,show: false, text : '',value : ''}));
                }, 5000)
            }else{                
                setTipeKontak(res.data);
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue,
                show: true, 
                text : 'Unable to fetch tipe kontak',
                value : ''
            }));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue,
                    show: false, 
                    text : '',
                    value : ''
                }));            
            }, 5000)
        }
    },[])

    useEffect(() => {
        fetchTipeKontak();
    },[fetchTipeKontak])

    useEffect(() => {
        if (props.modal) {
            setPageValue(pageValue => ({...pageValue, filter_status : 1}))
            dispatch(fetchReservation(`state=1`, config.itemPerPage, 1))
        }else{
            dispatch(fetchReservation(`state=1`));
        }
    },[dispatch, props.modal]);

    const handleAdd = () => {
        sessionStorage.removeItem('reservationId');
        props.history.push('/fo/reservation/add')
    }

    const handleFilter = (post) => {
        setPageValue(pageValue => ({...pageValue, filter: post}))
        dispatch(fetchReservation(`q=${pageValue.search}&tipe_kontak=${post}&tgl=${pageValue.tanggal_value}&state=${pageValue.filter_status}`, config.itemPerPage, 1))
    }

    const handleFilterStatus = (post) => {
        setPageValue(pageValue => ({...pageValue, filter_status: post}))
        dispatch(fetchReservation(`q=${pageValue.search}&tipe_kontak=${pageValue.filter}&tgl=${pageValue.tanggal_value}&state=${post}`, config.itemPerPage, 1))
    }

    const handleChangeSearch = (e) => {
        const { value } = e.target;    
        setPageValue(pageValue => ({...pageValue, search : value}))
    }

    const handleSearch = () => {
        dispatch(fetchReservation(`q=${pageValue.search}&tipe_kontak=${pageValue.filter}&tgl=${pageValue.tanggal_value}&state=${pageValue.filter_status}`, config.itemPerPage, 1))
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        dispatch(fetchReservation(`q=${pageValue.search}&tipe_kontak=${pageValue.filter}&tgl=${pageValue.tanggal_value}&state=${pageValue.filter_status}`, config.itemPerPage, page));
        setPageValue(pageValue => ({...pageValue, page : page}))
    }

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }
    
    const handleChangeDate = (e) => {        
        let tgl;
        if (isValidDate(e)){
            tgl = Moment(e).format('YYYY-MM-DD')
            dispatch(fetchReservation(`q=${pageValue.search}&tipe_kontak=${pageValue.filter}&tgl=${tgl}&state=${pageValue.filter_status}`, config.itemPerPage, 1));
        }else{
            tgl = ''
        }
        setPageValue(pageValue => ({...pageValue, tanggal : e, tanggal_value: tgl}))
    }

    const handleResetFilter = () => {
        setPageValue(pageValue => ({...pageValue,
            page : 1,
            search : '',
            filter : 0,
            tanggal : null,
            tanggal_value : ''
        }));
        dispatch(fetchReservation());
    }

    const handleEdit = (post) => {
        sessionStorage.setItem('reservationId', post.id);
        props.history.push('/fo/reservation/edit')
    }

    const handleCollapse = () => {
        setFilterCollapse(!filterCollapse)
    }
    
    return(
        <Fragment>
            { (isLoading || reservations.loading) &&
                <LoaderComponent/>
            }
            <Alert isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false, text : '', color: 'danger'}))} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <CardTemplate
                headerTitle="Reservation Room"
            >
                <CardBody>
                    <Collapse isOpen={filterCollapse}>
                        <Row>                            
                            <Col lg={4}>
                                <div className="title-filter">Filter Tanggal</div>
                                <DatePicker
                                    style={{display:'block'}}
                                    name={'tanggal'}
                                    className="form-control"
                                    selected={pageValue.tanggal}   
                                    dateFormat="dd-MM-yyyy"
                                    onChange={handleChangeDate}
                                />  
                            </Col>
                            <Col lg={4}>
                                <div className="title-filter" style={{marginTop: 10, fontWeight: 'bold'}}>Filter Tamu</div>
                                <ul className='ul-filter-guest' style={{marginTop: 0}}>
                                    <li onClick={() => handleFilter(0)} className={parseInt(pageValue.filter) === 0 ? 'active' : '' }>Semua</li>
                                    { tipeKontak.map((post, index) => {
                                        return(
                                            <li key={index} onClick={() => handleFilter(post.value)} className={parseInt(pageValue.filter) === parseInt(post.value) ? 'active' : '' }>{post.label}</li>
                                        )
                                    })}
                                </ul>
                            </Col>
                            <Col lg={4}>
                                <div className="title-filter" style={{marginTop: 10, fontWeight: 'bold'}}>Filter Status</div>
                                <ul className='ul-filter-guest' style={{marginTop: 0}}>
                                    <li onClick={() => handleFilterStatus(0)} className={parseInt(pageValue.filter_status) === 0 ? 'active' : '' }>Semua</li>
                                    <li onClick={() => handleFilterStatus(1)} className={parseInt(pageValue.filter_status) === 1 ? 'active' : '' }>Reservation</li>
                                    <li onClick={() => handleFilterStatus(2)} className={parseInt(pageValue.filter_status) === 2 ? 'active' : '' }>Checkin</li>                                
                                    <li onClick={() => handleFilterStatus(3)} className={parseInt(pageValue.filter_status) === 3 ? 'active' : '' }>Cancel</li>                                
                                </ul>
                            </Col>                            
                        </Row>
                    </Collapse>
                    <Row>
                        <Col lg={props.modal ? 12 : 8}>
                            <SearchComp
                                value={pageValue.search}
                                onClick={handleSearch}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDownSearch}                                
                            />
                        </Col>
                        { !props.modal &&
                            <Col lg={4}>
                                <ButtonGroup>
                                    <Button color="primary" onClick={handleAdd}>Tambah</Button>
                                    <Button onClick={handleCollapse} color='warning'>{ filterCollapse ? 'Close Filter' : 'Open Filter'}</Button>
                                    <Button color='danger' onClick={handleResetFilter}>Reset Filter</Button>
                                </ButtonGroup>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Table bordered responsive className='table-center table-small'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nama Tamu</th>
                                        <th>Tipe Kamar</th>
                                        <th>Jumlah Tamu</th>
                                        <th>Jumlah Kamar</th>
                                        <th>Tanggal Pemesanan</th>
                                        <th>Tanggal Checkin</th>
                                        <th>Tanggal Checkout</th>
                                        <th>Remark</th>
                                        <th>Status</th>
                                        { !props.modal &&
                                            <th>Action</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {reservations.reservations.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null} className="text-right">{post.nomor}</td>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null}>{post.name_display}</td>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null}>{post.tipe_kamar_name}</td>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null} className='text-right'>{post.jml_tamu}</td>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null} className='text-right'>{post.jml_kamar}</td>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null} className='text-center'>{post.tgl_reservasi}</td>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null} className='text-center'>{post.tgl_checkin}</td>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null} className='text-center'>{post.tgl_checkout}</td>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null}>{post.remark}</td>
                                                <td onClick={() => props.selectReservasi ? props.selectReservasi(post) : null}>{post.state_display}</td>
                                                { !props.modal &&
                                                    <td>
                                                        <ButtonGroup>
                                                            <Button className='btn-edit' color='primary' onClick={() => props.state === 2 ? null : handleEdit(post)} disabled={post.state === 2 ? true : false}>
                                                                Edit
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            { reservations.list_page.length > 0 &&
                                <Pagination>
                                    {reservations.list_page.map((post, index) => {
                                        return(
                                            <PaginationItem key={index} className={ pageValue.page === post ? 'active' : ''}>
                                                <PaginationLink tag="button" onClick={() => handlePaginate(post)}>{post}</PaginationLink>
                                            </PaginationItem>
                                        )
                                    })}
                                </Pagination>
                            }
                        </Col>                        
                    </Row>
                </CardBody>
            </CardTemplate>
        </Fragment>
    )
}
export default ReservationPage;