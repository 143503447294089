import React, { useCallback, useState, useEffect, Fragment } from 'react';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { Table, Row, Col, Button, CardBody, ButtonGroup, Alert, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import * as produkService from '../../../services/produkService';
import config from '../../../config';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import ModalComp from '../../../containers/Components/ModalComp';
import SearchComp from '../../../containers/Components/SearchComp';
const TipeBed = (props) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [tipeBedId, setTipeBedId] = useState(0);    
    const [hasError, setHasError] = useState(false);
    const [modalValue, setModalValue] = useState({
        show: false,
        text : ''
    });    
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : '',
        color : 'danger'
    })
    const [pageValue, setPageValue] = useState({
        obj : [],
        page : 1,
        search : ''
    })
    const fetchData  = useCallback(async(q='') => {
        try{
            const res = await produkService.getTipeBed(`q=${q}`, config.itemPerPage, pageValue.page);
            const newData = await res;
            setIsLoading(false);
            setHasError(false);
            setListData(newData.data);
            setPageValue(pageValue => ({...pageValue, obj: newData.list_page}));            
        }catch(error){
            setIsLoading(false);
            setHasError(true);
        }
    },[pageValue.page])

    const handleAdd = () => {
        sessionStorage.removeItem('tipeBedId');
        history.push('/room/master/tipe-bed/add')
    }

    const handleEdit = (id) => {
        history.push({ pathname : '/room/master/tipe-bed/edit', state: {tipeBedId: id}});        
    }

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setPageValue(pageValue => ({...pageValue, search: value}))
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, page: 1}));
        fetchData(pageValue.search);
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue, 
            show: true,
            text : `Apakah anda yakin akan menghapus tipe tempat tidur ${post.nama} ?`
        }));
        setTipeBedId(post.id);
    }

    const handleCloseModal = () => {
        setModalValue(modalValue => ({...modalValue, show: false, text : ''}));
    }

    const handleSubmitDelete = async() => {        
        try{
            const res = await produkService.deleteTipeBed(tipeBedId);
            const newData = await res;
            setIsLoading(false);
            if (newData.sukses === 'yes'){
                fetchData();
            }else{
                setHasError(true);
                setAlertValue(alertValue => ({...alertValue, show: true, text : newData.pesan}))
            }
        }catch(error){
            setIsLoading(false);
            setHasError(true);
        }
        handleCloseModal();
    }

    const handlePage = (page) => {
        setPageValue(pageValue => ({...pageValue, page: page}));
    }

    useEffect(() => {
        fetchData();
    }, [fetchData])
    return(
        <Fragment>
            { isLoading &&
                <LoaderComponent/>
            }
            <ModalComp
                isOpen={modalValue.show}
                onClose={handleCloseModal}
                title="Konfirmasi"
                body={modalValue.text}
                onSubmit={handleSubmitDelete}
                submitShow={true}
            />
            <Alert color={alertValue.color} isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false}))}>
                {alertValue.text}
            </Alert>
            <CardTemplate
                xs={12}
                lg={6}
                colClassName="offset-lg-3"
                headerTitle="Tipe Tempat Tidur"            
            >
                <CardBody>
                    { hasError && <p>Unable to load Tipe tempat tidur</p>}
                    { isLoading && <LoaderComponent/>}
                    <Row>
                        <Col lg={12}>
                            <Button color="primary" className='float-right' size="sm" onClick={handleAdd}>
                                <i className='fa fa-plus'></i> Tambah Kamar
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col lg={12}>
                            <SearchComp
                                placeHolder="Cari Tipe Tempat Tidur"
                                value={pageValue.search}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDown}
                                onClick={handleSearch}
                            />
                        </Col>
                    </Row>
                    <Table responsive style={{marginTop: 10}}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nama</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { listData.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{post.nomor}</td>
                                        <td>{post.nama}</td>
                                        <td>
                                            <ButtonGroup>
                                                <Button color="primary" className="btn-edit" onClick={() => handleEdit(post.id)}>
                                                    <i className="fa fa-edit fa-xs" id={"editTipeBed-" + post.id}></i>&nbsp; Edit
                                                </Button>
                                                <Button color="danger" className="btn-edit" onClick={() => handleDelete(post)}>
                                                    <i className="fa fa-edit fa-xs" id={"hapusTipeBed-" + post.id}></i>&nbsp; Hapus
                                                </Button>

                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    { pageValue.obj && pageValue.obj.length > 0 &&
                        <Pagination>
                            { pageValue.obj.map((post, index) => {
                                return(
                                    <PaginationItem key={index} className={pageValue.page === post ? 'active' : ''}>
                                        <PaginationLink tag="button" onClick={() => handlePage(post)}>
                                            {post}
                                        </PaginationLink>
                                    </PaginationItem>
                                )
                            })}
                        </Pagination>
                    }
                </CardBody>
            </CardTemplate>
        </Fragment>
    )
}
export default TipeBed;