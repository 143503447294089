import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, CardBody, Col, FormGroup, Input, Row, Table, CardFooter, Alert } from 'reactstrap';
import CardTemplate from '../../../containers/Components/CardTemplate';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
import { fetchGuestDetail } from '../../../slices/guests';
import { fetchRoom } from '../../../slices/room';
import * as penjualanService from '../../../services/penjualanService';
import LoaderComponent from '../../../containers/Components/LoaderComponent';


const ChangeRoomPage = (props) => {
    const dispatch = useDispatch();
    const { guests } = useSelector(state => state);
    const billingId = sessionStorage.getItem('billingId') || 0
    const [arrayTrx, setArrayTrx] = useState([]);
    const [objRoom, setObjRoom] = useState([]);
    const { rooms } = useSelector(state => state);
    const [isLoading, setIsLoading] = useState(false);
    const [alertValue, setAlertValue] = useState({
        show: false,
        text : '',
        color : 'danger'
    })

    useEffect(() => {
        dispatch(fetchGuestDetail(billingId, 'is_close=false'))
    }, [dispatch, billingId]);

    useEffect(() => {
        let tmp = [];
        if (guests.guest && guests.guest.room_detail && guests.guest.room_detail.length > 0){
            guests.guest.room_detail.map(post => {
                tmp.push({
                    is_change : false,
                    id : post.id,
                    nama_produk : post.nama_produk,
                    produk_id : post.produk_id,
                    produk_change : '',
                    include_breakfast : 'yes',
                    jenis_harga : 0,
                    harga : 0,
                    obj_jns_harga : [],
                    error_produk_change : false,
                    include_breakfast_obj : [{label : 'Ya', value: 'ya'}, {label : 'Tidak', value: 'tidak'}]
                });
                return true;
            });
        }
        setArrayTrx(tmp);
    },[guests.guest]);

    useEffect(() => {        
        dispatch(fetchRoom(`room_state=VC`, 300, 1))
    }, [dispatch]);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        let nilai = value;
        if (name === 'harga'){
            nilai = nilai.replace(/,/g, '');
        }

        let array = [...arrayTrx];
        array[index] = {...array[index], [name] : nilai}
        if (name === 'produk_change'){
            let obj = rooms.rooms.filter(x => x.id === parseInt(nilai));
            let tmpVariantHarga = []
            if (obj.length > 0){
                let harga_publish = 0
                obj[0].harga_variant.map((post, mykey) => {
                    if (mykey === 0){
                        harga_publish = post.harga
                    }
                    tmpVariantHarga.push({value : post.kategori_harga_id, label : post.kategori_harga_name, harga : post.harga});
                    return true;
                })
                array[index] = {...array[index], obj_jns_harga : tmpVariantHarga, harga : harga_publish}
            }
        }
        if (name === 'jenis_harga'){
            let objArray = array[index].obj_jns_harga.filter(x => parseInt(x.value) === parseInt(nilai));
            if (objArray.length > 0){
                array[index] = {...array[index], harga : objArray[0].harga}
            }
        }
        setArrayTrx(array);
    }

    const handleChangeOption = (index) => {
        let array = [...arrayTrx];
        array[index] = {...array[index], is_change : !array[index].is_change}
        setArrayTrx(array);
    }

    const handleBlur = (e, index) => {
        const { name, value } = e.target;
        let array = [...arrayTrx];
        if (array[index].is_change && value === '' && name === 'produk_change'){
            array[index] = {...array[index], error_produk_change : true}
        }
        
        setArrayTrx(array);
    }
    
    useEffect(() => {
        let tmp = [{label : '-- Pilih Kamar --', value : ''}];
        rooms.rooms.map(post => tmp.push({label : post.nama, value : post.id}));
        setObjRoom(tmp);
    }, [rooms.rooms])
        
    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(arrayTrx));
            const res = await penjualanService.changeRoom(billingId, newForm);
            setIsLoading(false);
            if (res.sukses === 'yes'){
                props.history.push('/fo')
            }else{
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan, color : 'danger'}));
                setTimeout(() => {                
                    setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}));
                }, 5000)
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to switch Room', color : 'danger'}));
            setTimeout(() => {                
                setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}));
            }, 5000)
        }
    }
    const handleCancel = () => {
        sessionStorage.removeItem('billingId');
        props.history.push('/fo');
    }

    return(
        <Fragment>
            { (isLoading || guests.loading) &&
                <LoaderComponent/>
            }
            <Alert isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <CardTemplate
                headerTitle="Change Room"
                cardClassName='box-billing-detail box-change-room'
            >
                <CardBody>
                    <Row>
                        <Col lg={6}>
                            <FormGroup row>
                                <Col lg={3}>Nomor</Col>
                                <Col lg={9}>
                                    { `${guests.guest.no_invoice}` }
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col lg={3}>Nama Tamu</Col>
                                <Col lg={9}>
                                    { guests.guest.konsumen_name }
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col lg={3}>Alamat</Col>
                                <Col lg={9}>
                                    { `${guests.guest.kecamatan_name} ${guests.guest.kota_name}` }
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup row>
                                <Col lg={6}>Tanggal Checkin</Col>
                                <Col lg={6}>
                                    { guests.guest.tgl_checkin }
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col lg={6}>Tanggal Checkout</Col>
                                <Col lg={6}>
                                    { guests.guest.tgl_checkout }
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col lg={6}>Status</Col>
                                <Col lg={6}>
                                    { guests.guest.bill_state }
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>#</th>
                                <th>Kamar Saat ini</th>
                                <th>Kamar Tujuan</th>
                                <th>Jenis Harga</th>
                                <th>Include Breakfast</th>
                                <th>Harga</th>
                            </tr>
                        </thead>
                        <tbody>
                            { arrayTrx.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td>
                                            {/* <FormGroup check> */}
                                                <Input
                                                    className="form-check-input"
                                                    name='is_change'
                                                    type='checkbox'
                                                    checked={post.is_change}
                                                    onChange={() => handleChangeOption(index)}
                                                    style={{marginLeft: '0 !important'}}
                                                />
                                            {/* </FormGroup> */}
                                        </td>
                                        <td>{index+1}</td>
                                        <td>{post.nama_produk}</td>
                                        <td>
                                            <FormTextSingleComp
                                                tipe='select'
                                                value={post.produk_change} 
                                                onChange={(e) => handleChange(e, index)}
                                                name='produk_change'
                                                onBlur={(e) => handleBlur(e, index)}
                                                obj={objRoom}
                                                show={true}
                                            />
                                            { post.error_produk_change &&
                                                <div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>Pilih salah satu kamar</div>
                                            }
                                        </td>
                                        <td>
                                            <FormTextSingleComp
                                                tipe='select'
                                                value={post.jenis_harga}
                                                onChange={(e) => handleChange(e, index)}
                                                name='jenis_harga'
                                                onBlur={(e) => handleBlur(e, index)}
                                                obj={post.obj_jns_harga}
                                                show={true}
                                            />
                                        </td>
                                        <td>
                                            <FormTextSingleComp
                                                tipe='select'
                                                value={post.include_breakfast}
                                                name='include_breakfast'
                                                onChange={(e) => handleChange(e, index)}
                                                onBlur={(e) => handleBlur(e, index)}
                                                obj={post.include_breakfast_obj}
                                                show={true}
                                            />
                                        </td>
                                        <td>
                                            <FormTextSingleComp
                                                tipe='number'
                                                value={post.harga}
                                                name='harga'
                                                onChange={(e) => handleChange(e, index)}
                                                onBlur={(e) => handleBlur(e, index)}
                                                show={true}
                                                className='text-right'
                                            />
                                        </td>                                        
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </CardBody>
                <CardFooter>
                    <ButtonGroup>
                        <Button color="primary" onClick={handleSubmit} size='sm'>Simpan</Button>
                        <Button color="secondary" onClick={handleCancel} >Cancel</Button>
                    </ButtonGroup>
                </CardFooter>
            </CardTemplate>
        </Fragment>
    )
}
export default ChangeRoomPage;