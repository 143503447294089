import api from './api';

// lokasi / gudang
export function getLokasi(keyword, limit, page){
    return api.get(`/master/api/lokasi?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deleteLokasi(id){
    return api.delete(`/master/api/lokasi/${id}`).then(res => res.data)
}

export function postLokasi(formData){
    return api.post(`/master/api/lokasi`, formData).then(res => res.data);
}

export function putLokasi(id, formData){
    return api.put(`/master/api/lokasi/${id}`, formData).then(res => res.data);
}

// kithen
export function getKitchen(keyword, limit, page){
    return api.get(`/master/api/kitchen?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deleteKitchen(id){
    return api.delete(`/master/api/kitchen/${id}`).then(res => res.data)
}

export function postKitchen(formData){
    return api.post(`/master/api/kitchen`, formData).then(res => res.data);
}

export function putKitchen(id, formData){
    return api.put(`/master/api/kitchen/${id}`, formData).then(res => res.data);
}

// meja
export function getMeja(keyword, limit, page){
    return api.get(`/master/api/meja?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deleteMeja(id){
    return api.delete(`/master/api/meja/${id}`).then(res => res.data)
}

export function postMeja(formData){
    return api.post(`/master/api/meja`, formData).then(res => res.data);
}

export function putMeja(id, formData){
    return api.put(`/master/api/meja/${id}`, formData).then(res => res.data);
}

// coffeeshop
export function getCoffee(keyword, limit, page){
    return api.get(`/master/api/coffeeshop?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function deleteCoffee(id){
    return api.delete(`/master/api/coffeeshop/${id}`).then(res => res.data)
}

export function postCoffee(formData){
    return api.post(`/master/api/coffeeshop`, formData).then(res => res.data);
}

export function putCoffee(id, formData){
    return api.put(`/master/api/coffeeshop/${id}`, formData).then(res => res.data);
}


// coffeeshop
export function getSetting(keyword, limit, page){
    return api.get(`/master/api/setting?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postSetting(form){
    return api.post(`/master/api/setting`, form).then(res => res.data);
}

// perusahaan
export function getPerusahaan(keyword, limit, page){
    return api.get(`/master/api/perusahaan?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postPerusahaan(form){
    return api.post(`/master/api/perusahaan`, form).then(res => res.data);
}

// shift pegawai
export function postShiftPegawai(form){
    return api.post(`/master/api/shift-pegawai`, form).then(res => res.data);
}

export function putShiftPegawai(id, form){
    return api.put(`/master/api/shift-pegawai/${id}`, form).then(res => res.data);
}

export function deleteShiftPegawai(id){
    return api.delete(`/master/api/shift-pegawai/${id}`).then(res => res.data);
}

export function getShiftPegawai(keyword, limit, page){
    return api.get(`/master/api/shift-pegawai?${keyword}`, {params:{limit, page}}).then(res => res.data);
}