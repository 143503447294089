import React, { Fragment, useCallback, useEffect, useState } from 'react';
import GuestHouseTable from './GuestHouseTable';
import { useGuestHouse } from './guestHouseFunction';
import * as masterService from '../../../services/masterService'
import { Col, Row } from 'reactstrap';

const GuestHousePrint = () => {
    const { listData } = useGuestHouse();
    const [compInfo, setCompInfo] = useState({
        nama : '',
        alamat : '',
        kota : '',
        telp : ''
    })
    const fetchCompany = useCallback(async() => {
        const res = await masterService.getPerusahaan();
        setCompInfo(compInfo => ({...compInfo,
            nama : res.data.nama,
            alamat : res.data.alamat,
            kota : res.data.kabupaten_name,
            telp : res.data.telp
        }));        
    },[])

    useEffect(() => {
        fetchCompany();
    },[fetchCompany])

    return(
        <Fragment>
            <div className='billprint-container landscape'>
                <Row>
                    <Col xs={12}>
                        <div className="company-name">
                            {compInfo.nama}
                        </div>
                        <div className="company-address">                            
                            { compInfo.alamat }
                        </div>
                        <div className="company-city">
                            { compInfo.kota }
                        </div>
                        <div className="company-phone">
                            { compInfo.telp }
                        </div>
                    </Col>                    
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="text-center bold" style={{fontWeight: 'bold'}}>Laporan Guest House</div>
                        <div className="text-center bold">Tanggal Cetak : {new Date().toLocaleString()}</div>
                    </Col>            
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={12}>
                        <GuestHouseTable listData={listData} />
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default GuestHousePrint;