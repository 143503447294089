import React, { Fragment } from 'react';
import { Input, InputGroup, InputGroupAddon, Button,  } from 'reactstrap';
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const FormTextSingleComp = (props) => {
    return(
        <Fragment>
            { props.tipe === 'date' &&
                <DatePicker
                    style={{display:'block'}}
                    name={props.name}
                    className="form-control"
                    selected={props.value}   
                    dateFormat="dd-MM-yyyy"
                    // onChange={(e) => props.onChangeDate(e, props.name)}
                    onChange={props.onChangeDate}
                />              
            }
            { props.tipe === 'text' &&
                <Input                         
                    id={props.name} 
                    placeholder={props.placeHolder}
                    value={props.value}
                    readOnly={props.readOnly} 
                    onChange={props.onChange}
                    name={props.name}                        
                    required={ props.required }
                    onBlur={props.onBlur}
                    type={props.show ? props.tipe : 'hidden'}
                    onKeyDown={props.onKeyDown ? props.onKeyDown : null}
                />                  
            }
            { props.tipe === 'password'  &&
                <Input                         
                    id={props.name} 
                    placeholder={props.placeHolder}                         
                    value={props.value}
                    readOnly={props.readOnly} 
                    onChange={props.onChange}
                    name={props.name}                        
                    required={ props.required }
                    onBlur={props.onBlur}
                    type={props.show ? props.tipe : 'hidden'}
                    // type={'password'}
                />                  
            }
            { (props.tipe === 'select' && props.show) &&
                <Input 
                    type={props.tipe} 
                    id={props.name} 
                    placeholder={props.placeHolder} 
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                    onBlur={props.onBlur}                    
                >                    
                    {props.obj.map((opt, mykey) => {
                        return(
                            <option value={opt.value} key={mykey}>{opt.label}</option>
                        )
                    })}
                </Input>                    
            }
            { props.tipe === 'input_button' &&
                <InputGroup>
                    <Input 
                        type="text" 
                        id={props.name}
                        name={props.name}
                        placeholder={props.placeHolder}
                        readOnly={props.readOnly}
                        value={props.value}
                        onChange={props.onChange}
                    />
                        <InputGroupAddon addonType="append">
                        <Button type="button" color="primary" onClick={props.onClick}><i className='fa fa-search'></i></Button>
                    </InputGroupAddon>
                </InputGroup>
            }
            { props.tipe === 'number' &&
                <NumberFormat 
                    thousandSeparator={true} 
                    prefix={props.prefix ? 'Rp.' : ''}                     
                    inputMode="numeric"
                    placeholder={props.placeHolder}
                    className={props.className ? `${props.className} form-control` : "form-control" }
                    name={props.name}
                    value={props.value} 
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    readOnly={props.readOnly}
                    id={props.id}
                />            
            }
            {props.showError && 
                (<div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>{props.errorMsg ? props.errorMsg : 'Wajib diisi'}</div>)                        
            }
        </Fragment>
    )
}

export default FormTextSingleComp;