import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGuestDetail } from "../../../slices/guests";
import { useGlobalFunction } from "../../../generalFunction/globalFunction";
import * as checkinService from '../../../services/checkinService';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as penjualanService from '../../../services/penjualanService';
import * as produkService from '../../../services/produkService';

export function useCheckout(){
    const dispatch = useDispatch();
    const { guests } = useSelector(state => state);
    const billingId = sessionStorage.getItem('billingId') || 0 ;
    const { isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert} = useGlobalFunction();
    const [additionalTrx, setAdditionalTrx] = useState([]);    
    const [depositeValue, setDepositeValue] = useState(0);
    const [methodPembayaran, setMetodPembayaran] = useState([]);
    const [showRefund, setShowRefund] = useState(false);
    const history = useHistory();
    const [arrayExtraBed, setArrayExtraBed] = useState([]);
    const [totalValue, setTotalValue] = useState({
        sub_total : 0,
        total_diskon : 0,
        total_pajak : 0,
        total : 0,
        last_num : 0
    })
    const [ formData, setFormData ] = useState({
        sub_total : 0,
        diskon : 0,        
        total : 0,
        billing_id : 0,
        deposite : 0,
        kurang_bayar : 0,
        lebih_bayar : 0,
        method_pembayaran : '',
        bayar : 0,
        kembali : 0,
        saldo_dp : 0,
        show_use_dp : false, 
        use_dp : 0,
        refund_deposite : 0,
        nomor_kartu : ''
    })

    useEffect(() => {
        dispatch(fetchGuestDetail(billingId))
    },[billingId, dispatch]);

    useEffect(() => {
        let nomor = 0;
        if (guests.guest.billing_detail && guests.guest.billing_detail.length > 0){
            nomor = guests.guest.billing_detail[guests.guest.billing_detail.length-1].nomor;
        }else{
            if (guests.guest.room_detail && guests.guest.room_detail.length > 0 ){
                nomor = guests.guest.room_detail[guests.guest.room_detail.length-1].nomor
            }
        }
        setTotalValue(totalValue => ({...totalValue,
            sub_total : guests.guest.sub_total || 0,
            total_diskon : guests.guest.total_diskon || 0,
            total_pajak : guests.guest.total_pajak || 0,
            total : guests.guest.total || 0,
            last_num : nomor
        }))
    },[guests.guest]);

    const fetchMethodPembayaran = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await penjualanService.getMethodPembayaran();
            setMetodPembayaran(res.data);
            setIsLoading(false);            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch method pembayaran', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue,
                    show: false,
                    text : '',
                    color : 'danger'
                }))
            }, 5000)
        }
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchMethodPembayaran();
    },[fetchMethodPembayaran])

    const fetchExtraBed = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await produkService.getExtraBed();
            setIsLoading(false);
            if (res.sukses === 'yes'){
                setArrayExtraBed(res.data);
            }else{
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show : true, text : res.pesan, color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show : false, text : '', color : 'danger'}));
                }, 5000)                
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to catch extra bed', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show : false, text : '', color : 'danger'}));
            }, 5000)
        }
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        fetchExtraBed();
    },[fetchExtraBed])

    const hitungTotal = (array, objFormData=formData) => {
        let sub_total = guests.guest.sub_total || 0;
        let total_diskon = objFormData.diskon;
        let total_pajak = guests.guest.total_pajak || 0;
        let total = guests.guest.total || 0;
        let myArray = [...array];

        myArray.map((post, index) => {
            sub_total += parseInt(post.nominal.value);
            total += parseInt(post.nominal.value);
            return true;
        })

        total = parseInt(total) - parseInt(total_diskon);
        

        setTotalValue(totalValue => ({...totalValue,
            sub_total : sub_total,
            total_diskon : total_diskon,    
            total_pajak : total_pajak,
            total : parseInt(total) 
        }));

        let saldo_dp = parseInt(depositeValue) - parseInt(objFormData.refund_deposite);
        const kurang_bayar = parseInt(total) - parseInt(saldo_dp);
        const lebih_bayar = (parseInt(saldo_dp)) - parseInt(total);

        
        // let result = parseInt(total) - parseInt(guests.guest.deposite)
        const kembali =  (parseInt(objFormData.bayar) + parseInt(objFormData.use_dp)) - kurang_bayar
        // console.log('kurang bayar adalah >>>>', kembali)
        setFormData(formData => ({...formData,
            sub_total : sub_total,
            deposite : objFormData.deposite,
            refund_deposite : objFormData.refund_deposite,
            method_pembayaran : objFormData.method_pembayaran,
            total : total,
            diskon : total_diskon,
            kurang_bayar : kurang_bayar,
            lebih_bayar : lebih_bayar, 
            kembali : kembali,

            bayar : objFormData.bayar,            
            saldo_dp : objFormData.saldo_dp,
            show_use_dp : objFormData.show_use_dp, 
            use_dp : objFormData.use_dp,            
            nomor_kartu : objFormData.nomor_kartu
        }));
    }

    // console.log('additionalTrx', additionalTrx)

    const handleChangeLate = (e, index) => {
        const { name, value } = e.target;
        let array = [...additionalTrx];
        
        let nilai = value;        
        if (name === 'nominal'){
            nilai = nilai.replace(/,/g, '');            
        }

        if (name === 'trx_name'){
            if (parseInt(value) === 3){
                array[index] = {...array[index], 
                    desc : {...array[index].desc, show : true, tipe : 'text'},
                    nominal : {...array[index].nominal, readOnly: false}
                }
            }else if(parseInt(value) === 2){
                array[index] = {...array[index], 
                    desc : {...array[index].desc, show : false, tipe : 'text'},
                    nominal : {...array[index].nominal, readOnly: false}
                }
            }else{
                array[index] = {...array[index], 
                    desc : {...array[index].desc, tipe : 'select', show : true, obj : arrayExtraBed},
                    nominal : {...array[index].nominal, readOnly: true}
                }
            }
        }
        if (name === 'desc'){
            if (array[index][name].tipe === 'select' ){
                let tmpObj = arrayExtraBed.filter(x => x.value === parseInt(nilai));
                if (tmpObj.length > 0){
                    array[index] = {...array[index], nominal : {...array[index].nominal, value : tmpObj[0].harga}}
                    // hitungTotal(tmpObj[0].harga, index)
                }
                if (nilai === ''){
                    array[index] = {...array[index], desc : {...array[index].desc, showError : true}}
                }else{
                    array[index] = {...array[index], desc : {...array[index].desc, showError : false}}
                }
            }
        }

        array[index] = {...array[index],
            [name] : {...array[index][name], value : nilai}
        }
        setAdditionalTrx(array);
        hitungTotal(array);        
    }

    const handleBlurLate = (e, index) => {        
        const { name, value } = e.target;        
        let array = [...additionalTrx];
        let nilai = value;
        if (name === 'nominal'){
            nilai = nilai.replace(/,/g, '');
        }
        if (name === 'nominal'){
            if (parseInt(nilai) === 0 || nilai === ''){
                array[index] = {...array[index], [name] : {...array[index][name], showError : true}};
            }else{
                array[index] = {...array[index], [name] : {...array[index][name], showError : false}};                
            }            
        }else{
            if (nilai === ''){                
                array[index] = {...array[index], [name] : {...array[index][name], showError : true}};
            }else{
                array[index] = {...array[index], [name] : {...array[index][name], showError : false}};                
            }
        }        
        setAdditionalTrx(array);
    }

    useEffect(() => {
        let result = parseInt(guests.guest.total) - parseInt(guests.guest.deposite)
        setFormData(formData => ({...formData,
            sub_total : guests.guest.sub_total || 0,
            total : guests.guest.total || 0,
            diskon : guests.guest.total_diskon || 0,
            billing_id : guests.guest.id || 0,
            deposite : guests.guest.deposite || 0,
            lebih_bayar : (result < 0 ? (result * -1): 0) || 0,
            kurang_bayar : (result > 0 ? result : 0) || 0,
            kembali : 0 - result || 0
        }));
        if (guests.hasErrors){
            setAlertValue(alertValue => ({...alertValue, show: true, text : guests.pesan || '', color : 'danger'}))
        }
        if (parseInt(guests.guest.deposite) > 0){            
            setShowRefund(true);
            setDepositeValue(guests.guest.deposite)
        }else{
            setDepositeValue(0)
            setShowRefund(false)
        }
    },[guests.guest, guests.hasErrors, guests.pesan, setAlertValue]);

    const handleAddTrx = (e) => {
        let nomor = totalValue.last_num
        if (additionalTrx.length > 0){
            nomor = additionalTrx[additionalTrx.length-1].nomor
        }
        let array = [...additionalTrx];
        array.push({
            trx_name : {
                value : 2, 
                showError : false, 
                errorMsg : 'Wajib diisi',
                obj : [
                    {
                        value : 2,
                        label : 'Late Checkout'
                    },
                    {
                        value : 3,
                        label : 'Miscellaneous'
                    },
                    {
                        value : 1,
                        label : 'Extra Bed'
                    },

                ]
            },            
            qty : {value : 1, showError : false, errorMsg : 'Wajib diisi'},
            nominal : {value : 0, showError : false, errorMsg : 'Wajib diisi', readOnly: false},
            desc : {value : '', show: false, tipe : 'text', showError : false},
            nomor : nomor + 1 
        });
        setAdditionalTrx(array);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value;        
        if (['bayar', 'refund_deposite', 'use_dp'].includes(name)){
            if (nilai === ''){
                nilai = '0'
            }                        
            nilai = nilai.replace(/,/g, '');
        }        
        let objFormData = {...formData};
        objFormData[name] = nilai;
        
        if (name === 'method_pembayaran' && [5, '5'].includes(value)){
            objFormData['bayar'] = parseInt(objFormData.kurang_bayar)
        }
        hitungTotal(additionalTrx, objFormData);
    }

    const handleChangeSwitch = () => {
        setFormData(formData => ({...formData, show_use_dp : !formData.show_use_dp}))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{            
            if (parseInt(formData.bayar) > 0 && formData.method_pembayaran === ''){
                setIsLoading(false);
                    setAlertValue(alertValue => ({...alertValue, show : true, text : 'Pilih metode pembayaran', color : 'danger'}));
                    setTimeout(() => {
                        handleCloseAlert()
                    }, 5000)
                    return;
            }
            if (parseInt(formData.method_pembayaran) !== 2 && parseInt(formData.method_pembayaran) !== 5 && parseInt(formData.method_pembayaran) !== 7 && parseInt(formData.kurang_bayar) > 0){
                if(parseInt(formData.kembali) !== 0){
                    setIsLoading(false);
                    setAlertValue(alertValue => ({...alertValue, show : true, text : 'Masukan jumlah pembayaran, pastikan balance harus Nol', color : 'danger'}))
                    setTimeout(() => {
                        handleCloseAlert()
                    }, 7000)
                    return;
                }
            }

            if (parseInt(formData.use_dp) > parseInt(formData.saldo_dp)){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show : true, text : 'Penggunaan DP tidak boleh melebihi saldo DP', color : 'danger'}))
                setTimeout(() => {
                    handleCloseAlert()
                }, 7000)
                return;
            }

            // additional transaksi (late checkout)
            let arrayLate = [...additionalTrx];
            let arrayFormLate = []            
            if (arrayLate.length > 0){
                let errorCount = 0;
                arrayLate.map((post, index) => {
                    Object.entries(post).map(([mykey, value]) => {
                        if (mykey === 'nominal' && (value.value === '' || parseInt(value.value) === 0)){
                            value.showError = true;
                            errorCount++;
                        }
                        if (mykey === 'trx_name' && value.value === '' ){
                            value.showError = true;
                            errorCount++;
                        }
                        if (mykey === 'desc' && value.value === '' && value.tipe === 'select'){
                            value.showError = true;
                            errorCount++;
                        }
                        return true;
                    })     
                    return true;
                });

                if (errorCount > 0){
                    setAlertValue(alertValue => ({...alertValue, show : true, text : 'Lengkapi Data', color : 'danger'}))
                    setTimeout(() => {
                        handleCloseAlert()
                    }, 7000);
                    setAdditionalTrx(arrayLate);
                    setIsLoading(false);
                    return;
                }

                arrayLate.map(post => {
                    arrayFormLate.push({'trx_name' : post.trx_name.value, 'nominal' : post.nominal.value, 'desc' : post.desc.value})                    
                    return true;
                });
            }
            
            
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(formData));
            newForm.append('late_checkout', JSON.stringify(arrayFormLate))
            const res = await checkinService.checkout(billingId, newForm);
            setIsLoading(false);
            if (res.sukses === 'yes'){
                history.push('/fo');
            }else{
                setAlertValue(alertValue => ({...alertValue, show : true, text : res.pesan, color : 'danger'}))
                setTimeout(() => {
                    handleCloseAlert();
                }, 7000)
            }            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'unable to save checkout', color : 'danger'}));
            setTimeout(() => {
                handleCloseAlert();
            }, 5000)
        }
    }


    return {
        guests, isLoading, setIsLoading, alertValue, setAlertValue, handleCloseAlert,
        additionalTrx, setAdditionalTrx,
        totalValue, setTotalValue, hitungTotal, formData, handleChangeLate,
        handleBlurLate, handleAddTrx,
        showRefund, depositeValue, handleChange, handleChangeSwitch,
        methodPembayaran, handleSubmit
    }
}