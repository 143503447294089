import api from './api';

// lokasi / gudang
export function getKontak(keyword, limit, page){
    return api.get(`/kontak/api?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function postKontak(formData){
    return api.post(`/kontak/api`, formData).then(res => res.data);
}

export function putKontak(id, formData){
    return api.put(`/kontak/api/${id}`, formData).then(res => res.data);
}


export function deleteKontak(id){
    return api.delete(`/kontak/api/${id}`).then(res => res.data);
}


export function getGroup(){
    return api.get(`/auth/api/group`).then(res => res.data);
}


export function getTipeKontak(){
    return api.get(`/kontak/api/tipe-kontak`).then(res => res.data);
}
