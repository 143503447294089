import * as React from "react"

function SingleBed(props) {
  return (
    <svg width={354.667} height={354.667} viewBox="0 0 266 266" {...props}>
      <path d="M69.1 1.1c-11.5 2.3-23 10.6-28.6 20.6-4.7 8.5-5.5 14.4-5.5 42.4v25.7l-2.7.7c-3.9.9-12.7 7.1-15.6 10.8-1.3 1.8-3.5 5.2-4.8 7.7l-2.4 4.5-.3 70c-.2 67.6-.2 70.1 1.7 73.1 1.1 1.8 3.6 4.5 5.7 6 3.7 2.9 3.8 2.9 18.9 2.9 15.1 0 15.2 0 18.9-2.9 6.6-5 7.6-7.6 7.6-20.4V231h141v10.5c0 12 1.1 15.7 5.9 19.8 4.8 4 9.3 4.9 23.2 4.5 11.4-.3 12.9-.5 16.2-2.8 2-1.3 4.6-4.3 5.9-6.5l2.3-4V185c0-75.3.3-72.3-7-82.2-3.7-5-12.1-11.2-16.7-12.3l-2.7-.7-.3-28.6c-.3-27.2-.5-29-2.6-34.5-4.4-10.8-13.1-19.4-24.6-24.1-5-2-6.7-2.1-67.1-2.3-34.1-.1-64 .3-66.4.8zm128.4 20.3c5.9 2.8 9.7 6.6 12.2 12.1 1.4 3.4 1.8 7.9 2.1 29.2l.4 25.3h-8.9l-1.6-6c-5.1-18-16.1-31.6-32.5-39.9-10.9-5.5-16.7-6.5-36.7-6.5-20.5 0-26.2 1-37.8 7.1C79 51 67.6 65.7 63 83.7c-1 4.2-1.2 4.3-5 4.3h-4V62.7c0-23.2.2-25.6 2.1-29.7 2.7-6 5.4-8.8 11.4-11.6l5-2.4h120l5 2.4zM149 55c15 2.8 28.3 14.3 33.4 28.8l1.5 4.2H133c-57.9 0-53 .9-48.5-8.8 2.9-6.3 13.2-17 19.3-20.1 2.3-1.2 6.8-2.8 10-3.5 6.8-1.7 27.7-2 35.2-.6zm81.6 55.5c6.9 5.7 7.4 7.5 7.4 29.2V159H27v-18.5c0-23.4 1.3-27.4 10.5-31.6 3.8-1.8 8.4-1.9 96.7-1.6l92.6.2 3.8 3zm7.4 102V247h-17v-14.4c0-7.9-.3-15.2-.6-16.1-1.8-4.5-1.3-4.5-88.4-4.5H49.8l-2.9 2.9-2.9 2.9V247H27v-69h211v34.5z" />
    </svg>
  )
}

export default SingleBed
