import {  useCallback, useEffect, useState } from 'react';
import * as penjualanService from '../../../services/penjualanService';
import config from '../../../config';
import '../../../style/produkStyle.css';
import * as produkService from '../../../services/produkService';
import { useHistory } from 'react-router-dom';
import { fetchDetail } from '../../../slices/coffeeshop';
import { useDispatch, useSelector } from 'react-redux';

export function useResto(){    
    // const { mejas } = useSelector(state => state);
    const history = useHistory();
    const dispatch = useDispatch();    
    const penjualanId = sessionStorage.getItem('penjualanId') || 0;
    const [isEdit, setIsEdit] = useState(false);    
    const { coffees } = useSelector(state => state);
    const [isPpn, setIsPpn] = useState(true);
    const arrayForm = [
        {
            name : 'coffee_name',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'CoffeeShop Name',
            tipe : 'text',
            showError : false,            
            show : true
        },
        {
            name : 'meja_id',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nomor Meja *',
            tipe : 'select',
            showError : false,
            obj : [                
                {value : '', label : '-- Pilih Meja --'}
            ],
            show : true
        },
        {
            name : 'include_room',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : false,
            label : 'Nomor Meja *',
            tipe : 'switch',
            showError : false,            
            show : true
        },     
        {
            name : 'nomor_kamar',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nomor Kamar *',
            tipe : 'select',
            showError : false,            
            show : true,
            obj : [
                {label : '-- Pilih Nomor Kamar', value : ''}
            ]
        },
        {
            name : 'barcode',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Barcode *',
            tipe : 'text',
            placeHolder : 'Enter to show all Product',
            showError : false,            
            show : true,            
        },
        {
            name : 'nama_produk',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nama Produk',
            tipe : 'text',
            placeHolder : 'Nama Produk',
            showError : false,            
            show : true,
            readOnly : true
        },
        {
            name : 'qty',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : 1,
            label : 'Qty',
            tipe : 'number',
            placeHolder : 'Jumlah',
            showError : false,            
            show : true,             
        },
        {
            name : 'harga_satuan',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Harga Satuan',
            tipe : 'number',
            placeHolder : 'Harga Satuan',
            showError : false,            
            show : true,
            readOnly: true   
        },
        {
            name : 'produk_id',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Produk ID',
            tipe : 'text',
            placeHolder : 'Produk ID',
            showError : false,            
            show : false,
            readOnly: true   
        },
        {
            name : 'is_close',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : false,
            label : 'Produk ID',
            tipe : 'text',
            placeHolder : 'Produk ID',
            showError : false,            
            show : false,
            readOnly: true   
        },
        {
            name : 'coffee_id',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : false,
            label : 'Coffee ID',
            tipe : 'text',
            placeHolder : 'Coffee ID',
            showError : false,            
            show : false,
            readOnly: true   
        },
        {
            name : 'lokasi_id',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : false,
            label : 'Lokasi ID',
            tipe : 'text',
            placeHolder : 'Lokasi ID',
            showError : false,            
            show : false,
            readOnly: true   
        },
        {
            name : 'kontak_id',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : 0,
            label : 'Lokasi ID',
            tipe : 'text',            
            showError : false,            
            show : false,
            readOnly: true   
        },
        {
            name : 'kontak_name',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Lokasi ID',
            tipe : 'input_button',            
            showError : false,            
            show : true,
            readOnly: true, 
            onClick : function(){
                handleClickKonsumen()
            }
        }
    ]
    const initModalBayar = {
        'show' : false,
        'text' : ''
    }
    const [modalBayar, setModalBayar] = useState(initModalBayar)    
    const [listProduk, setListProduk] = useState([]);
    const [formProduk, setFormProduk] = useState([]);
    const [pageValue, setPageValue] = useState({
        page : 1,
        obj : [],
        search : ''
    })
    const [methodPembayaran, setMethodPembayaran] = useState([]);
    const InitRoomInfo = {
        room_no : '',
        guest_name : '',
        show : false
    }
    const [selectedProduk, setSelectedProduk] = useState({});
    const [roomInfo, setRoomInfo] = useState(InitRoomInfo)    
    const initForm = arrayForm.reduce((obj, item) => Object.assign(obj, { [item.name] : item }), {})
    const [formData, setFormData] = useState(initForm);
    const [isLoading, setIsLoading] = useState(false);
    const initAlert = {
        show: false,
        text : '',
        color : 'danger'
    }
    const [alertValue, setAlertValue] = useState(initAlert);
    const initModalValue = {
        show : false,
        text : '',
    }
    const [modalValue, setModalValue] = useState(initModalValue);
    const initPajakValue = {
        subtotal : 0,
        dpp : 0,
        ppn : 0,
        total : 0,
        diskon : 0,
        method_pembayaran : 0,
        bayar : 0,
        kembali : 0,
        keterangan : '',
        nomor_kartu : ''
    }
    const [pajakValue, setPajakValue] = useState(initPajakValue)    

    // konsumen
    const [modalKonsumen, setModalKonsumen] = useState({
        show: false,
        text : '',
        content : 'list'
    })

    const handleClickKonsumen = () => {
        setModalKonsumen(modalKonsumen => ({...modalKonsumen, show: true, content: 'list'}))
    }

    const handleSelectKontak = (post) => {
        setFormData(formData => ({...formData,
            kontak_name : {...formData.kontak_name, value : post.nama},
            kontak_id : {...formData.kontak_id, value : post.id},
        }));
        setModalKonsumen(modalKonsumen => ({...modalKonsumen, show: false, content: 'list'}))
    }

    const handleFetchPpn = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await penjualanService.getPpnState()            
            setIsPpn(res.data)
            setIsLoading(false);            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch ppn state', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))
            }, 5000)
        }
    },[])

    useEffect(() => {
        handleFetchPpn();
    },[handleFetchPpn])

    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value
        if (name === 'qty'){
            nilai = nilai.replace(/,/g, '');
        }
        setFormData(formData => ({...formData, [name] : {...formData[name], value: nilai}}))
        if (name === 'nomor_kamar'){
            setRoomInfo(InitRoomInfo)
            if (nilai !== ''){
                let data = formData.nomor_kamar.obj.filter(x => parseInt(x.value) === parseInt(nilai));
                if (data.length > 0){
                    const tmpData = data[0]
                    setRoomInfo(roomInfo => ({...roomInfo, 
                        room_no : tmpData.label,
                        guest_name : tmpData.konsumen_detail_name ? `${tmpData.konsumen_name} QQ ${tmpData.konsumen_detail_name}` : tmpData.konsumen_name,
                        show: true
                    }))
                }          
            }
        }        
        if (name === 'barcode'){
            setPageValue(pageValue => ({...pageValue, search: nilai}))
        }
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData, [name] : {...formData[name], showError: true}}))
        }else{
            setFormData(formData => ({...formData, [name] : {...formData[name], showError: false}}))
        }
    }

    useEffect(() => {
        const defFb = localStorage.getItem(config.localStorageKey.DEF_FB)
        if (!defFb){
            history.push('/fb/default-fb')
        }
    },[history]);

    const handleSubmit = async(state) => {        
        setIsLoading(true);
        let array = {...formData}
        let errorCount = 0;
                
        // validation
        if (array.include_room.value){
            if (array.nomor_kamar.value === '' ){
                array.nomor_kamar.showError = true;
                errorCount++;            
            }else{
                array.nomor_kamar.showError = false;
            }        
        }else{
            array.nomor_kamar.showError = false;                
        }
        
        
        Object.entries(array).map(([index, post]) => {            
            if(post.required){
                if (post.tipe === 'text' && post.value === ''){
                    errorCount++;
                    post.showError = true;                    
                }else if(post.tipe === 'number' && (post.value === '' || parseInt(post.value) === 0)){
                    errorCount++;
                    post.showError = true;
                    post.errorMsg = 'Nilai tidk boleh kosong atau harus lebih dari 0'
                }else{
                    post.showError = false;
                }                
            }
            return true;
        });                

        if (errorCount > 0){
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Lengkapi Data', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 50000);
            setFormData(array);
            setIsLoading(false);
            return;
        }

        
        
        if (formProduk.length === 0){
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Masukan produk yang akan dijual', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 50000);
            setIsLoading(false);
            return;
        }



        let tmpFormData = {}
        Object.entries(array).map(([index, post]) => tmpFormData[index] = post.value);
        tmpFormData['is_close'] = false;

        let newForm = new FormData();
        newForm.append('formData', JSON.stringify(tmpFormData));
        newForm.append('formProduk', JSON.stringify(formProduk));
        
        try{
            let res;
            if (isEdit){
                res = await penjualanService.putPenjualanResto(penjualanId, newForm);
            }else{
                res = await penjualanService.postPenjualanResto(newForm);    
            }
            if (res.sukses === 'yes'){
                setFormData(formData => ({...formData,
                    meja_id : {...formData.meja_id, value : '', obj : res.meja_obj},
                    include_room : {...formData.include_room, value: false},
                    nomor_kamar : {...formData.nomor_kamar, value : ''},
                    barcode : {...formData.barcode, value : ''},
                    nama_produk : {...formData.nama_produk, value : ''},
                    qty : {...formData.qty, value : ''},
                    harga_satuan : {...formData.harga_satuan, value : ''},                    
                }));
                setFormProduk([]);
                setPajakValue(initPajakValue)
            }else{
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan, color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(initAlert)
                }, 5000)
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to save master meja', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000)
        }
    }

    const fetchKamar = useCallback(async() => {
        try{
            const res = await penjualanService.getPenjualanByRoomDetail(`filter=active`, 0, 1)
            if (res.sukses === 'yes'){
                let tmp = [{label : '-- Pilih Nomor Kamar', value : ''}]                
                res.data.map(post => {
                    post.detail.map(value => {
                        tmp.push({
                            label : `${value.nama_produk} - ${post.konsumen_name}`, 
                            value : value.id,
                            konsumen_name : post.konsumen_name, 
                            konsumen_id : post.konsumen_id, 
                            konsumen_detail_name : value.nama_konsumen
                        });
                        return true;
                    });
                    return true;
                })
                setFormData(formData => ({...formData, nomor_kamar : {...formData.nomor_kamar, obj : tmp}}))
            }
        }catch(error){
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch Kamar', color : 'danger'}))
        }
    },[])

    const fetchMethodPembayaran = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await penjualanService.getMethodPembayaran();
            let tmp = [{label : '-- Pilih Jenis Pembayaran --', value : ''}]
            if (res) {
                res.data.map(post => tmp.push({label : post.label, value: post.value}));
                setMethodPembayaran(tmp);        
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
        }
    },[])
    useEffect(() => {
        fetchKamar();
        fetchMethodPembayaran()
    },[fetchKamar, fetchMethodPembayaran])

    const handleChangeSwitch = (e) => {        
        setFormData(formData => ({...formData, include_room: {...formData.include_room, value : !formData.include_room.value}}));        
    }

    const handleKeyDown = (e) => {        
        if (e.key === 'Enter'){            
            (async() => {
                setIsLoading(true);
                const res = await produkService.getProduk(`q=${formData.barcode.value}&lokasi_id=${formData.lokasi_id.value}`, config.itemPerPage, 1);
                setIsLoading(false);
                if (res.sukses === 'yes'){
                    const tmpProduk = res.data;                 
                    if (tmpProduk.length === 1){                        
                        setSelectedProduk({
                            barcode : tmpProduk[0].kode,
                            nama_produk : tmpProduk[0].nama,
                            harga_satuan : tmpProduk[0].harga_jual,
                            produk_id : tmpProduk[0].id,
                            id : tmpProduk[0].id,
                            saldo : tmpProduk[0].saldo,
                            stockable_id : tmpProduk[0].stockable_id
                        })                    
                        let tmpdata = {
                            barcode : tmpProduk[0].kode,
                            nama_produk : tmpProduk[0].nama,
                            harga_satuan : tmpProduk[0].harga_jual,
                            produk_id : tmpProduk[0].id,
                            qty : 1
                        }
                        if ((1 > parseInt(tmpProduk[0].saldo)) && tmpProduk[0].stockable_id === 1){
                            setAlertValue(alertValue => ({...alertValue, show : true, text : `Saldo tidak cukup saldo yang tersedia ${tmpProduk[0].saldo}`, color : 'danger'}));
                            setTimeout(() => {
                                setAlertValue(initAlert);
                            }, 5000);
                            setIsLoading(false);
                            return;
                        }
                        handleInsertProdukNew(tmpdata);                           
                    }else{                    
                        fetchProduk(`q=${pageValue.search}&lokasi_id=${formData.lokasi_id.value}`, config.itemPerPage, 1)                    
                        setModalValue(modalValue => ({...modalValue, show: true, text : ''}));
                        setIsLoading(false);                  
                    }           
                }else{
                    setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan}))
                } 
            })()
        }
    }

    const handleInsertProdukNew = (data) => {
        let array = [...formProduk];
        let cekDouble = array.filter(x => parseInt(x.produk_id) === parseInt(data.produk_id))[0];
        if (cekDouble){
            const indexDouble = array.indexOf(cekDouble);
            let tmpQty = parseInt(array[indexDouble].qty) + parseInt(data.qty);
            array[indexDouble].qty = tmpQty;
            array[indexDouble].sub_total = parseInt(data.harga_satuan) * parseInt(tmpQty);
        }else{
            array.push({
                produk_id : data.produk_id,
                nama_produk : data.nama_produk,
                barcode : data.barcode,
                harga_satuan : data.harga_satuan,
                sub_total : parseInt(data.harga_satuan) * parseInt(data.qty),
                qty : data.qty, 
                showError: false,
                id : 0
            })
            setFormProduk(array);
        }
        clearFormProduk();
        hitungaPajak(array);
        document.getElementById('barcode').focus()
    }

    const handleInsertProduk = () => {
        if (parseInt(formData.qty.value) === 0 || formData.produk_id.value === '' || formData.qty.value === ''){
            if (formData.produk_id.value === ''){
                setFormData(formData => ({...formData,                    
                    nama_produk : {...formData.nama_produk, showError: true},
                }));
            }
            if (parseInt(formData.qty.value) === 0 || formData.qty.value === ''){
                setFormData(formData => ({...formData,
                    qty : {...formData.qty, showError: true},                    
                }));                
            }
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Produk dan qty tidak boleh kosong', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000)
            return
        }
        
        if ((parseInt(formData.qty.value) > parseInt(selectedProduk.saldo)) && selectedProduk.stockable_id === 1){
            console.log('disini ketemuanya', selectedProduk)
            setAlertValue(alertValue => ({...alertValue, show : true, text : `Saldo tidak cukup saldo yang tersedia ${selectedProduk.saldo}`, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000);
            return;
        }
        let array = [...formProduk];

        // filter 
        let cekDouble = array.filter(x => parseInt(x.produk_id) === parseInt(formData.produk_id.value))[0];
        if (cekDouble){
            const indexDouble = array.indexOf(cekDouble);
            let tmpQty = parseInt(array[indexDouble].qty) + parseInt(formData.qty.value);
            array[indexDouble].qty = tmpQty;
            array[indexDouble].sub_total = parseInt(formData.harga_satuan.value) * parseInt(tmpQty);
        }else{            
            array.push({
                produk_id : formData.produk_id.value,
                nama_produk : formData.nama_produk.value,
                barcode : formData.barcode.value,
                harga_satuan : formData.harga_satuan.value,
                sub_total : parseInt(formData.harga_satuan.value) * parseInt(formData.qty.value),
                qty : formData.qty.value, 
                id : 0
            })
            setFormProduk(array);
        }
        clearFormProduk();
        hitungaPajak(array);
        document.getElementById('barcode').focus()
    }

    const handleSelectedProduk = (post) => {    
        setModalValue(modalValue => ({...modalValue, show: false, text : ''}));
        if ((1 > parseInt(post.saldo)) && post.stockable_id === 1){
            setAlertValue(alertValue => ({...alertValue, show : true, text : `Saldo tidak cukup saldo yang tersedia ${post.saldo}`, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000);
            setIsLoading(false);
            return;
        }
        let tmpdata = {
            barcode : post.kode,
            nama_produk : post.nama,
            harga_satuan : post.harga_jual,
            produk_id : post.id,
            qty : 1
        }
        handleInsertProdukNew(tmpdata);           
        setSelectedProduk({
            barcode : post.kode,
            nama_produk : post.nama,
            harga_satuan : post.harga_jual,
            produk_id : post.id,
            id : post.id,
            saldo : post.saldo,
            stockable_id : post.stockable_id
        })                
    }   

    const handleKeyDownQty = (e) => {
        if (e.key === 'Enter'){
            handleInsertProduk();
        }
    }

    const handleClickProduk = () => {
        fetchProduk(`q=${pageValue.search}&lokasi_id=${formData.lokasi_id.value}`, config.itemPerPage, 1)
        setModalValue(modalValue => ({...modalValue, show: true, text : ''}));
        setIsLoading(false);  
    }

    const handleChangeQtyProduk = (e, index) => {
        const { name, value } = e.target;
        let nilai = value.replace(/,/g, '');
        let array = [...formProduk];

        let harga_satuan = array[index].harga_satuan
        if (name === 'harga_satuan'){
            harga_satuan = nilai
        }
        let qty = array[index].qty
        if (name === 'qty'){
            qty = nilai
        }
        let sub_total = hitungSubTotal({
            harga_satuan : harga_satuan,
            qty : qty
        })
        array[index] = {...array[index], [name]: nilai, sub_total: sub_total};        
        setFormProduk(array);
    }

    const handleBlurQtyProduk = (e, index) => {
        const { value } = e.target;
        let nilai = value.replace(/,/g, '');
        let array = [...formProduk]
        let sub_total = hitungSubTotal({
            harga_satuan : array[index].harga_satuan,
            qty : nilai
        })
        if (nilai === '' || parseInt(nilai) === 0){
            array[index] = {...array[index], showError : true, sub_total: sub_total}
        }
        hitungaPajak(array);
        setFormProduk(array);
        document.getElementById('barcode').focus();
    }

    const hitungSubTotal = (obj) => {
        let sub_total = parseInt(obj.qty) * parseInt(obj.harga_satuan);
        return sub_total
    }

    const handleKeyDownQtyProduk = (e) => {
        if (e.key === 'Enter'){
            document.getElementById('barcode').focus();
        }
    }
 

   
    const handlePaginateProduk = (page) => {
        fetchProduk(`q=${pageValue.search}`, config.itemPerPage, page)        
        setPageValue(pageValue => ({...pageValue, page : page}))
    }
    
    
    const handleChangeSearchProduk = (e) => {
        const { value } = e.target;
        setPageValue(pageValue => ({...pageValue, search: value}));
    }

    const handleKeyDownSearchProduk = (e) => {
        if (e.key === 'Enter'){
            handleSearchProduk();
        }
    }

    const fetchProduk = useCallback(async(search='', itemPerPage=config.itemPerPage, page=1) => {
        try{
            const res = await produkService.getProduk(`is_extra_bed=false&${search}`, itemPerPage, page);
            setListProduk(res.data)
            setPageValue(pageValue => ({...pageValue, obj : res.list_page}));
        }catch(error){
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch produk', color: 'danger'}))
        }
    },[]);

    const handleSearchProduk = () => {        
        fetchProduk(`q=${pageValue.search}`, config.itemPerPage, 1)
    }

    const handleCloseModal = () => {
        setModalValue(initModalValue);
    }     
    
    const clearFormProduk = () => {
        setFormData(formData => ({...formData,
            produk_id : {...formData.produk_id, value : '', showError: false},
            nama_produk : {...formData.nama_produk, value : '', showError: false},
            barcode : {...formData.barcode, value : '', showError: false},
            harga_satuan : {...formData.harga_satuan, value : '', showError: false},
            qty : {...formData.qty, value : 1, showError: false},
        }))
        setPageValue(pageValue => ({...pageValue, search: ''}));
    }

    const handleDeleteFormProduk = (index) => {
        let array = [...formProduk];
        array.splice(index, 1);
        setFormProduk(array);
        hitungaPajak(array);
    }

    const hitungaPajak = useCallback((array) => {
        let ppn = 0;
        let subtotal = 0;
        let total = 0;
        let dpp = 0;
        let kembali = 0
        array.map(post => subtotal = parseInt(subtotal) + (parseInt(post.harga_satuan) * parseInt(post.qty)));
        let diskon = parseInt(pajakValue.diskon) > parseInt(subtotal) ? subtotal : pajakValue.diskon 
        dpp = parseInt(subtotal) - parseInt(diskon);   
        console.log("ppn adalah", isPpn)     
        ppn = isPpn ? 0.1 * dpp : 0;
        total = parseInt(dpp) + parseInt(ppn);
        kembali = parseInt(pajakValue.bayar) - parseInt(total);
        setPajakValue(pajakValue => ({...pajakValue,
            subtotal : parseInt(subtotal) > 0 ? subtotal : 0,
            ppn : parseInt(ppn) > 0 ? ppn : 0,
            total : parseInt(total) > 0 ? total : 0,
            dpp : parseInt(dpp) > 0 ? dpp : 0,
            kembali: kembali,
            diskon : pajakValue.diskon
        }))
    },[pajakValue.bayar, pajakValue.diskon, isPpn])

    

    const handleCloseModalBayar = () => {
        setModalBayar(initModalBayar);
    }

    const handleChangeBayar = (e) => {
        const { name, value } = e.target;
        let nilai = value;        
        if ( name === 'diskon'){            
            nilai = value.replace(/,/g, '');
            let diskon = nilai;
            let dpp = parseInt(pajakValue.subtotal) - parseInt(diskon);    
            let ppn = parseInt(dpp) * 0.1;
            let total = parseInt(dpp) + parseInt(ppn);                
            let kembali = parseInt(pajakValue.bayar) - parseInt(total)
            setPajakValue(pajakValue => ({...pajakValue, 
                total: total > 0 ? total : 0, 
                dpp : dpp > 0 ? dpp : 0, 
                ppn: ppn > 0 ? ppn : 0,
                kembali : kembali,                    
                diskon : parseInt(diskon) <= parseInt(pajakValue.subtotal) ? diskon : pajakValue.subtotal
            })) 
        }else if (name === 'bayar'){
            nilai = value.replace(/,/g, '');
            let bayar = nilai;
            let kembali = parseInt(nilai) - parseInt(pajakValue.total)            
            setPajakValue(pajakValue => ({...pajakValue,                 
                bayar : bayar,
                kembali : kembali
            }))
        }else if (name === 'method_pembayaran'){
            if (parseInt(nilai) === 2 || parseInt === 5){
                setFormData(formData => ({...formData,
                    konsumen_name : {...formData.konsumen_name, required: true}
                }))
            }else{
                setFormData(formData => ({...formData,
                    konsumen_name : {...formData.konsumen_name, required: false}
                }))
            }
            setPajakValue(pajakValue => ({...pajakValue, [name] : nilai}))
        }else{
            setPajakValue(pajakValue => ({...pajakValue, [name] : nilai}))
        }
    }

    const handleBayar = () => {
        handleSubmitBayar('is_close')
    }

    const handleSimpan = () => {
        handleSubmitBayar('is_open')
    }

    const handleSubmitBayar = async(q) => {
        setIsLoading(true);
        let array = {...formData}
        let errorCount = 0;

        // validation
        if (array.include_room.value){
            if (array.nomor_kamar.value === '' ){
                array.nomor_kamar.showError = true;
                errorCount++;            
            }else{
                array.nomor_kamar.showError = false;
            }        
        }else{
            array.nomor_kamar.showError = false;                
        }
        
        if (q === 'is_close'){
            Object.entries(array).map(([index, post]) => {            
                if(post.required){
                    if (post.tipe === 'text' && post.value === ''){
                        errorCount++;
                        post.showError = true;                    
                    }else if(post.tipe === 'number' && (post.value === '' || parseInt(post.value) === 0)){
                        errorCount++;
                        post.showError = true;
                        post.errorMsg = 'Nilai tidak boleh kosong atau harus lebih dari 0'
                    }else{
                        post.showError = false;
                    }                
                }
                return true;
            });
        }else{
            Object.entries(array).map(([index, post]) => {            
                if(post.required){
                    if (post.tipe === 'text' && post.value === ''){
                        errorCount++;
                        post.showError = true;                                            
                    }else{
                        post.showError = false;
                    }                
                }
                return true;
            });
        }

        if (errorCount > 0){
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Lengkapi Data', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 50000);
            setFormData(array);
            setIsLoading(false);
            return;
        }
                
        if (formProduk.length === 0){
            setAlertValue(alertValue => ({...alertValue, show: true, text: 'Masukan produk yang akan dijual', color: 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 50000);
            setIsLoading(false);
            return;
        }

        if (q === 'is_close'){
            if (parseInt(pajakValue.method_pembayaran) === 0 && !formData.include_room.value){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Silakan pilih jenis pembayaran'}));
                setTimeout(() => {
                    setAlertValue(initAlert);
                }, 5000);
                return;
            }        
            if (parseInt(pajakValue.method_pembayaran) === 1 && parseInt(pajakValue.kembali) < 0){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Masukan Jumlah Pembayaran'}));
                setTimeout(() => {
                    setAlertValue(initAlert);
                }, 5000);
                return;
            }
        }

        if ([2,5,7].includes(parseInt(pajakValue.method_pembayaran)) > 1 && pajakValue.keterangan === ''){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Masukan Keterangan'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000);
            return;
        }

        let tmpFormData = {}
        Object.entries(array).map(([index, post]) => tmpFormData[index] = post.value);
        if (q === 'is_close'){
            tmpFormData['is_close'] = true;
        }else{
            tmpFormData['is_close'] = false;
        }

        let newForm = new FormData();
        newForm.append('formData', JSON.stringify(tmpFormData));
        newForm.append('formProduk', JSON.stringify(formProduk));
        newForm.append('formBayar', JSON.stringify(pajakValue));
        
        try{
            let res;
            if (isEdit){
                res = await penjualanService.putPenjualanResto(penjualanId, newForm);
            }else{
                res = await penjualanService.postPenjualanResto(newForm);    
            }            
            setModalBayar(initModalBayar);
            if( res.sukses === 'yes'){
                if (q=== 'is_close'){
                    localStorage.setItem('penjualanId', res.penjualan_id)
                    setIsEdit(false);
                    window.open(`#/fb/cetak-struk`, "_blank");
                    setPajakValue(initPajakValue);
                    setFormProduk([]);
                    setFormData(formData => ({...formData,
                        'meja_id' : {...formData.meja_id, value : ''},
                        'include_room' : {...formData.include_room, value : false},
                        'nomor_kamar' : {...formData.nomor_kamar, value : ''},
                        'barcode' : {...formData.barcode, value : ''},
                        'nama_produk' : {...formData.nama_produk, value : ''},
                        'qty' : {...formData.qty, value : ''},
                        'harga_satuan' : {...formData.harga_satuan, value : ''},
                        'produk_id' : {...formData.produk_id, value : ''},
                        'is_close' : {...formData.is_close, value : false},
                        'kontak_id' : {...formData.kontak_id, value : 0},
                        'kontak_name' : {...formData.kontak_name, value : ''},
                    }))
                }                
            }else{
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan, color: 'danger'}));
                setTimeout(() => {
                    setAlertValue(initAlert)
                }, 5000)
            }
            setModalBayar(initModalBayar)
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to save master meja', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000)
        }
    }
    
    // edit
    const fetchEdit = useCallback(async(penjualanId=0) => {
        setIsLoading(true);
        let defFb = localStorage.getItem(config.localStorageKey.DEF_FB)
        try{
            const res = await penjualanService.getPenjualanResto(`id=${penjualanId}&kasir_id=${defFb}`);
            let tmp = [];
            console.log('res edit', res);
            res.data.detail.map(post => {
                tmp.push({
                    produk_id : post.produk_id,
                    nama_produk : post.nama_produk,
                    barcode : post.produk_kode,
                    harga_satuan : post.harga_jual,
                    sub_total : parseInt(post.harga_jual) * parseInt(post.qty),
                    qty : post.qty, 
                    showError: false,
                    id : post.id
                });
                return true;
            });
            if (res.data.include_to_room){
                setRoomInfo(roomInfo => ({...roomInfo,
                    room_no : res.data.room_number,
                    guest_name : res.data.konsumen_name,
                    show : true
                }))
            }
            setFormData(formData => ({...formData,
                include_room : {...formData.include_room, value : res.data.include_to_room},
                nomor_kamar : {...formData.nomor_kamar, value : res.data.room_number_id},
                meja_id : {...formData.meja_id, value : res.data.meja_id}
            }));
            setPajakValue(pajakValue => ({...pajakValue, diskon : res.data.diskon_nominal}))
            setFormProduk(tmp);
            // hitungaPajak(tmp);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch detail data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}));
            }, 5000);
        }
    },[])

    useEffect(() => {
        if(penjualanId !== 0){
            setIsEdit(true);
            fetchEdit(penjualanId)
        }else{
            setIsEdit(false);
        }
    },[penjualanId, fetchEdit]);

    useEffect(() => {
        if(isEdit){
            hitungaPajak(formProduk);
        }
    },[isEdit, hitungaPajak, formProduk])


    
    useEffect(() => {
        const defFb = localStorage.getItem(config.localStorageKey.DEF_FB)
        if (!defFb){
            history.push('/fb/default-fb')
        }else{
            dispatch(fetchDetail(defFb))
        }
    },[dispatch, history]);

    useEffect(() => {
        setFormData(formData => ({...formData,
            coffee_name : {...formData.coffee_name, value : coffees.coffee.nama},
            coffee_id : {...formData.coffee_id, value : coffees.coffee.id},
            lokasi_id : {...formData.lokasi_id, value : coffees.coffee.lokasi_id}
        }))
    },[coffees.coffee, setFormData])    

    return {        
        // mejas, 
        penjualanId, isEdit, setIsEdit, initModalBayar, modalBayar, setModalBayar,
        listProduk, setListProduk, formProduk, setFormProduk, pageValue, setPageValue, InitRoomInfo,
        selectedProduk, setSelectedProduk, roomInfo, setRoomInfo,
        initForm, formData, setFormData, isLoading, setIsLoading, initAlert, alertValue, setAlertValue,
        initModalValue, modalValue, setModalValue, pajakValue, setPajakValue, handleChange, handleBlur,
        handleSubmit, fetchKamar, handleChangeSwitch, handleKeyDown, handlePaginateProduk, handleChangeSearchProduk, handleKeyDownSearchProduk, fetchProduk, handleSearchProduk,
        handleCloseModal, handleSelectedProduk, handleInsertProduk, handleKeyDownQty, clearFormProduk,
        handleDeleteFormProduk, hitungaPajak, handleBayar, handleCloseModalBayar, handleChangeBayar, methodPembayaran, handleSubmitBayar, handleClickProduk, handleChangeQtyProduk, handleBlurQtyProduk, isPpn,
        handleKeyDownQtyProduk, modalKonsumen, setModalKonsumen, handleSelectKontak, handleSimpan
    }
}