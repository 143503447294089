import api from './api';

// meeting room
export function postKategoriHarga(formData){
    return api.post(`/produk/api/kategori-harga`, formData).then(res => res.data);
}

export function putKategoriHarga(id, formData){
    return api.put(`/produk/api/kategori-harga/${id}`, formData).then(res => res.data);
}

export function getKategoriHarga(keyword, limit, page){
    return api.get(`/produk/api/kategori-harga?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function deleteKategoriHarga(id){
    return api.delete(`/produk/api/kategori-harga/${id}`).then(res => res.data)
}