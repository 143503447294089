import React, { Fragment } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const GuestListBtnComp = (props) => {
    return(
        <Fragment>
            <div>
                <ButtonGroup>
                    <Button 
                        outline 
                        color="primary" 
                        className='btn-edit' 
                        onClick={props.handleDetail}
                    >
                        Detail
                    </Button>
                    <Button 
                        outline 
                        color={"warning"}
                        disabled={props.is_close ? true : false}
                        className='btn-edit' 
                        onClick={props.handleAddDeposite}
                    >
                        Deposite
                    </Button>
                </ButtonGroup>   
            </div>
            <div>
                <ButtonGroup>
                    <Button 
                        color={"danger"}
                        disabled={props.is_close ? true : false}
                        className='btn-edit' 
                        onClick={props.handleCheckout}
                    >
                        Checkout
                    </Button>
                    <Button 
                        color={"warning"}
                        disabled={props.is_close ? false : true}
                        className='btn-edit' 
                        onClick={() => window.open(`#/fo/billing/print/${props.post.id}`, "_blank")}
                    >
                        <i className='fa fa-print'></i>&nbsp;Cetak
                    </Button>
                </ButtonGroup>
            </div>
            <div>
                <ButtonGroup>
                    <Button
                        color={"primary"}
                        className='btn-edit'
                        disabled={props.is_close ? true : false}
                        onClick={props.handleChangeRoom}
                    >
                        <i className='fa fa-exchange'></i>&nbsp;Change Room
                    </Button>
                </ButtonGroup> 
            </div>
        </Fragment>
    )
}
export default GuestListBtnComp;