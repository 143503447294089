import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Row, Table } from 'reactstrap';
import '../../../style/masterBillPrint.css';
import * as masterService from '../../../services/masterService'
import { fetchGuestDetail } from '../../../slices/guests';

const CetakMasterBill = (props) => {    
    const dispatch = useDispatch();
    const { guests } = useSelector(state => state);
    const { billingId } = props.match.params || 0;
    const [compInfo, setCompInfo] = useState({
        nama : '',
        alamat : '',
        kota : '',
        telp : ''
    })

    const fetchCompany = useCallback(async() => {
        const res = await masterService.getPerusahaan();
        setCompInfo(compInfo => ({...compInfo,
            nama : res.data.nama,
            alamat : res.data.alamat,
            kota : res.data.kabupaten_name,
            telp : res.data.telp,
            provinsi : res.data.provinsi_name,
        }));        
    },[])

    useEffect(() => {
        fetchCompany();
    },[fetchCompany])

    useEffect(() => {
        dispatch(fetchGuestDetail(billingId))
    }, [dispatch, billingId])

    return(
        <Fragment>
            <div className='billprint-container'>
                <Row>
                    <Col xs={12}>
                        <div className="company-name text-center">
                            <img src={require('../../../assets/img/logo-kurnia.jpg')} alt='logo' style={{width: 100, marginBottom: 5}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="text-center">
                            <div className="company-address">                            
                                { compInfo.alamat }
                            </div>
                            <div className="company-city">
                                { compInfo.kota } Phone { compInfo.telp }
                            </div>                            
                        </div>                        
                    </Col>                    
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="title">
                            HOTEL INVOICE
                        </div>
                    </Col>
                </Row>                
                <Row style={{marginTop: 20}}>
                    <Col xs={6}>
                        <FormGroup row>
                            <Col xs={4}>Bill Rcvr</Col>
                            <Col xs={6}>
                                : &nbsp;{ guests.guest.konsumen_name }
                            </Col>
                        </FormGroup>
                        <FormGroup>                            
                            <div>Address</div>                            
                            <div>
                            { `${guests.guest.kecamatan_name} ${guests.guest.kota_name}` }
                            </div>                            
                        </FormGroup>
                        <FormGroup row style={{marginTop: 20}}>
                            <Col xs={4}>Guest Name</Col>
                            <Col xs={6}>
                                : &nbsp;{ guests.guest.pic_name }
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col xs={6}>                        
                        <FormGroup row>
                            <Col xs={4}>Arrival Date</Col>
                            <Col xs={6}>
                                : &nbsp;{ guests.guest.checkin_date }
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col xs={4}>Departure Date</Col>
                            <Col xs={6}>
                                : &nbsp;{ guests.guest.checkout_date }
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col xs={4}>Bill No</Col>
                            <Col xs={6}>
                                : &nbsp;{ guests.guest.no_invoice }
                            </Col>
                        </FormGroup>                        
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={12}>
                        <Table>
                            <thead>
                                <tr>                                    
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th className='text-right'>Qty</th>
                                    <th className='text-right'>Price</th>
                                    <th className='text-right'>Total</th>                                    
                                </tr>
                            </thead>
                            <tbody>
                                { guests.guest.room_detail && guests.guest.room_detail.map((post, index) => {
                                    return(
                                        <tr key={index}>                                            
                                            <td>{post.tanggal}</td>
                                            <td>{post.nama_produk}</td>
                                            <td className='text-right'>{post.qty}</td>
                                            <td className='text-right'>{post.harga_jual.toLocaleString()}</td>
                                            <td className='text-right'>{(post.harga_jual * post.qty).toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                               
                                { !props.is_close && guests.guest.billing_detail && guests.guest.billing_detail.map((post, index) => {
                                    return(
                                        <tr key={index}>                                            
                                            <td>{post.tanggal}</td>
                                            <td>{post.nama_produk}</td>
                                            <td className='text-right'>{post.qty}</td>
                                            <td className='text-right'>{post.harga_jual.toLocaleString()}</td>
                                            <td className='text-right'>{(post.harga_jual * post.qty).toLocaleString()}</td>
                                        </tr>
                                    )
                                })}                                    
                                { parseInt(guests.guest.sub_total) > 0 &&
                                    <tr>
                                        <td colSpan={4} className='text-right'>Sub Total</td>
                                        <td className='text-right'>{guests.guest.sub_total.toLocaleString()}</td>
                                    </tr>
                                }
                                { parseInt(guests.guest.total_diskon) > 0 &&
                                    <tr>
                                        <td colSpan={4} className='text-right'>Diskon</td>
                                        <td className='text-right'>{guests.guest.total_diskon.toLocaleString()}</td>
                                    </tr>
                                }
                                {  parseInt(guests.guest.total_pajak) > 0 && parseInt(guests.guest.total_diskon) > 0 && (parseInt(guests.guest.sub_total) - parseInt(guests.guest.total_diskon)) > 0 &&
                                    <tr>
                                        <td colSpan={4} className='text-right'>DPP</td>
                                        <td className='text-right'>{(parseInt(guests.guest.sub_total)-parseInt(guests.guest.total_diskon)).toLocaleString()}</td>
                                    </tr>
                                }
                                { parseInt(guests.guest.total_pajak) > 0 &&
                                    <tr>
                                        <td colSpan={4} className='text-right'>Tax</td>
                                        <td className='text-right'>{guests.guest.total_pajak.toLocaleString()}</td>
                                    </tr>
                                }

                                {/* total */}
                                { parseInt(guests.guest.total) > 0 &&
                                    <tr>
                                        <td colSpan={4} className='text-right'>Total</td>
                                        <td className='text-right'>{(parseInt(guests.guest.total)-parseInt(guests.guest.total_diskon)).toLocaleString()}</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        <div style={{marginTop: 10}}/>                        
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <div style={{
                            border : '1px solid #cecece',
                            borderRadius: 5,
                            padding: 5,               
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <table style={{display: 'table-cell', width: '100%'}}>
                                <tbody>
                                    <tr>
                                        <td style={{paddingLeft: 8, paddingRight: 8}}>Total</td>
                                        <td style={{paddingLeft: 8, paddingRight: 8}}>:</td>
                                        <td className='text-right' style={{paddingLeft: 8, paddingRight: 8, width: '100%', whiteSpace: 'nowrap'}}>{(parseInt(guests.guest.total)-parseInt(guests.guest.total_diskon)).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td style={{paddingLeft: 8, paddingRight: 8}}>Deposite</td>
                                        <td style={{paddingLeft: 8, paddingRight: 8}}>:</td>
                                        <td className='text-right' style={{paddingLeft: 8, paddingRight: 8, width: '100%', whiteSpace: 'nowrap'}}>{parseInt(guests.guest.deposite).toLocaleString()}</td>
                                    </tr>                                    
                                    { parseInt(guests.guest.refund) > 0 &&
                                        <tr>
                                            <td style={{paddingLeft: 8, paddingRight: 8}}>Deposite</td>
                                            <td style={{paddingLeft: 8, paddingRight: 8}}>:</td>
                                            <td className='text-right' style={{paddingLeft: 8, paddingRight: 8, width: '100%', whiteSpace: 'nowrap'}}>{parseInt(guests.guest.deposite).toLocaleString()}</td>
                                        </tr>
                                    }
                                    { parseInt(guests.guest.kurang_bayar) > 0 &&
                                        <tr>
                                            <td style={{paddingLeft: 8, paddingRight: 8}}>Payment</td>
                                            <td style={{paddingLeft: 8, paddingRight: 8}}>:</td>
                                            <td className='text-right' style={{paddingLeft: 8, paddingRight: 8, width: '100%', whiteSpace: 'nowrap'}}>{parseInt(guests.guest.kurang_bayar).toLocaleString()}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
                <div style={{marginTop: 10}}/>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <div>In Word</div>
                            <div>
                                { guests.guest.terbilang }
                            </div>
                        </FormGroup>                        
                    </Col>
                    <Col xs={12} style={{marginTop: 80, color: '#384392'}}>
                        <div className="text-center">
                            Thank you for staying with us at Hotel {compInfo.nama}, We look forward to welcoming you again
                        </div>
                        <div className="text-center" style={{fontSize: 12}}>
                            <i>
                                terimakasih untuk menginap bersama kami di Hotel {compInfo.nama}, kami menanti kedatangan anda kembali
                            </i>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={4}>
                        <div style={{textAlign: 'center'}}>CHARGE TO / <i>DITAGIHKAN KEPADA</i></div>
                        <div style={{textAlign: 'center', borderTop: '1px solid #cecece', marginTop: 110}}>{guests.guest.konsumen_name}</div>
                    </Col>
                    <Col xs={3}></Col>
                    <Col xs={4}>
                        <div style={{textAlign: 'center'}}>Cashier</div>
                        <div style={{textAlign: 'center'}}><i>Kasir</i></div>
                        <div style={{textAlign: 'center', marginTop: 100, borderTop: '1px solid #cecece'}}>{guests.guest.kasir}</div>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default CetakMasterBill;