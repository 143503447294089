import { combineReducers } from 'redux'

import postsReducer from './perusahaan'
import provinsiReducer from './provinsi'
import kabupatenReducer from './kabupaten';
import tipeKamarReducer from './tipekamar';
import tipeBedReducer from './tipebed';
import roomReducer from './room';
import meetingroomReducer from './meetingroom';
import lokasiReducer from './lokasi';
import kitchenReducer from './kitchen';
import coffeeReducer from './coffeeshop';
import kategoriReducer from './kategoriproduk';
import produkReducer from './produk';
import satuanReducer from './satuan';
import kontakReducer from './kontak';
import kategoriHargaReducer from './kategoriharga'
import guestsReducer from './guests'
import mejaReducer from './meja';
import restoReducer from './resto';
import pembelianReducer from './pembelian';
import kasbankReducer from './kasbank';
import userReducer from './userslice';
import roleReducer from './roles';
import reservationReducer from './reservation';
import piutangReducer from './piutang';

// import postReducer from './post'
// import commentsReducer from './comments'

const rootReducer = combineReducers({
    posts: postsReducer,
    provinsis : provinsiReducer,
    kabupatens : kabupatenReducer,  
    tipekamars : tipeKamarReducer,
    tipebeds : tipeBedReducer,
    rooms : roomReducer,
    meetingrooms : meetingroomReducer,
    lokasis : lokasiReducer,
    kitchens : kitchenReducer,
    coffees : coffeeReducer,
    kategories : kategoriReducer,
    produks : produkReducer,
    satuans : satuanReducer,
    kontaks : kontakReducer,
    kategoriesHarga : kategoriHargaReducer,
    guests : guestsReducer,
    mejas : mejaReducer,
    restos : restoReducer,
    pembelians : pembelianReducer,
    kasbanks : kasbankReducer,
    users : userReducer,
    roles : roleReducer,
    reservations : reservationReducer,
    piutangs : piutangReducer,
})

export default rootReducer
