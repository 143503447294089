import React, { useState, useEffect, Fragment, useCallback } from 'react';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { CardBody, CardFooter, Button } from 'reactstrap';
import FormTextArrayComp from '../../../containers/Components/FormTextArrayComp';
import * as wilayahService from '../../../services/wilayahService';
import * as kontakService from '../../../services/kontakService';
import AlertAbsoluteComp from '../../../containers/Components/AlertAbsoluteComp';
import { useHistory } from 'react-router-dom';
import LoaderComponent from '../../../containers/Components/LoaderComponent';

const KontakAddPage = (props) => {   
    const [isLoading, setIsLoading] = useState(false); 
    const kontakId = sessionStorage.getItem('kontakId') || '';
    const [isEdit, setIsEdit] = useState(false);
    const [wilayahObj, setWilayahObj] = useState({
        provinsi : [],
    });
    const initArrayForm = [
        {
            name : 'tipe_kontak',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : 1,
            label : 'Pilih Jenis Kontak *',
            tipe : 'select',
            showError : false,
            obj : [
                {value: 1, label: 'Perorangan'},
                {value : 2, label : 'Perusahaan'}
            ],
            show : true
        },
        {
            name : 'nama',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nama *',
            tipe : 'text',
            showError : false,
            show : true
        },        
        {
            name : 'jk',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Jenis Kelamin *',
            tipe : 'select',
            showError : false,
            obj : [{value : '', label : '-- Pilih Jenis Kelamin --'}, { value : 'laki-laki', label : 'Laki-laki'}, {value : 'perempuan', label: 'Perempuan'}],
            show : true
        },
        {
            name : 'jenis_nik',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Jenis NIK *',
            tipe : 'select',
            showError : false,
            obj : [{value : '', label : '-- Pilih Jenis NIK --'}, { value : 1, label : 'SIM'}, {value : 2, label: 'KTP'}, { value : 3, label : 'Passport'}],
            show : true
        },
        {
            name : 'nation',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Kewarganegaraan *',
            tipe : 'text',
            showError : false,
            show : false
        },
        {
            name : 'no_ktp',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nomor KTP *',
            tipe : 'text',
            showError : false,
            show : true
        },
        {
            name : 'alamat',
            rule : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Alamat *',
            tipe : 'text',
            showError : false,
            show : true
        },
        {
            name : 'hp',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'No HP *',
            tipe : 'text',
            showError : false,
            show : true        
        },
        {
            name : 'provinsi_id',
            required : false,
            errorMsg : '',
            value : '',
            label : 'Provinsi *',
            tipe : 'select',
            showError : false,
            show : true,
            obj : wilayahObj.provinsi,
        },
        {
            name : 'kabupaten_id',
            required : false,
            errorMsg : '',
            value : '',
            label : 'Kabupaten / Kota *',
            tipe : 'select',
            obj : [{value : '', label : '-- Pilih Kabupaten / Kota --'}],
            showError : false,
            show : true
        },        
        {
            name : 'kecamatan_id',
            required : false,
            errorMsg : '',
            value : '',
            label : 'Kecamatan *',
            tipe : 'select',
            obj : [{value : '', label : '-- Pilih Kecamatan --'}],
            showError : false,
            show : true
        },    
        {
            name : 'desa_id',
            required : true,
            errorMsg : 'Pilih Salah Satu Desa / Kelurahan',
            value : '',
            label : 'Desa / Kelurahan *',
            tipe : 'select',
            obj : [{value : '', label : '-- Pilih Desa / Kelurahan --'}],
            showError : false,
            show : true
        },        
    ]
    const tmpFormData = initArrayForm.reduce((obj, item) => Object.assign(obj, { [item.name] : item}), {});
    const [formData, setFormData] = useState(tmpFormData);
        

    const [alertValue, setAlertValue] = useState({
        show: false,
        text : ''
    });
    const history = useHistory();
    
    const handleChange = (e) => {       
        const { name, value } = e.target;        
        setFormData(formData => ({...formData, [name] : {...formData[name], value : value}}));
        
        if (name === 'jenis_nik'){
            let label = ''
            if (parseInt(value) === 1){
                label = 'SIM'
                setFormData(formData => ({...formData,
                    nation : {...formData.nation, show: false, required : false},
                    alamat : {...formData.alamat, show: true, required : true},
                    provinsi_id : {...formData.provinsi_id, show: true, required : true},
                    kabupaten_id : {...formData.kabupaten_id, show: true, required : true},
                    kecamatan_id : {...formData.kecamatan_id, show: true, required : true},
                    desa_id : {...formData.desa_id, show: true, required : true},
                }))
            }else if(parseInt(value) === 2){
                label = 'KTP'
                setFormData(formData => ({...formData,
                    nation : {...formData.nation, show: false, required : false},
                    alamat : {...formData.alamat, show: true, required : true},
                    provinsi_id : {...formData.provinsi_id, show: true, required : true},
                    kabupaten_id : {...formData.kabupaten_id, show: true, required : true},
                    kecamatan_id : {...formData.kecamatan_id, show: true, required : true},
                    desa_id : {...formData.desa_id, show: true, required : true},
                }))
            }else if(parseInt(value) === 3){
                label = 'Passport'
                setFormData(formData => ({...formData,
                    nation : {...formData.nation, show: true, required : true},
                    alamat : {...formData.alamat, show: false, required : false},
                    provinsi_id : {...formData.provinsi_id, show: false, required : false},
                    kabupaten_id : {...formData.kabupaten_id, show: false, required : false},
                    kecamatan_id : {...formData.kecamatan_id, show: false, required : false},
                    desa_id : {...formData.desa_id, show: false, required : false},
                }))
            }
            setFormData(formData => ({...formData, 
                no_ktp : {...formData.no_ktp, label : `Nomor ${label}`},
                jenis_nik : {...formData.jenis_nik, value : value},                
            }))
        }
        
        if (name === 'provinsi_id'){                    
            fetchKabupaten(value);
            fetchKecamatan(0);
            fetchDesa(0);
        }
        if (name === 'kabupaten_id'){
            fetchKecamatan(value);
            fetchDesa(0);
        }
        if (name === 'kecamatan_id'){
            fetchDesa(value);
        }

        if (name === 'tipe_kontak'){            
            if ( value === 1 || value === '1'){
                setFormData(formData => ({...formData,
                    jk : {...formData.jk, show : true, required: true},
                    jenis_nik : {...formData.jenis_nik,  show : true, required : true},
                    no_ktp : {...formData.no_ktp,  show : true, required: true},
                    hp : {...formData.hp,  required : true, label : 'No HP *'},                    
                }))                
            }else{
                setFormData(formData => ({...formData,
                    jk : {...formData.jk,  show : false, required : false},                    
                    jenis_nik : {...formData.jenis_nik,  show : false, required : false},
                    no_ktp : {...formData.no_ktp,  required : false, show : false},                    
                    hp : {...formData.hp,  required : false, label : 'No Telp'},                    
                }))                
            }
        }        
    }

    const fetchTipeKontak = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await kontakService.getTipeKontak();
            setIsLoading(false);
            if (res.sukses === 'no'){
                setAlertValue(alertValue => ({...alertValue,
                    show: true, 
                    text : res.pesan,
                    value : ''
                }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue,
                        show: false, 
                        text : '',
                        value : ''
                    }));            
                }, 5000)
            }else{
                setFormData(formData => ({...formData, tipe_kontak : {...formData.tipe_kontak, obj : res.data}}))
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue,
                show: true, 
                text : 'Unable to fetch tipe kontak',
                value : ''
            }));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue,
                    show: false, 
                    text : '',
                    value : ''
                }));            
            }, 5000)
        }
    },[])

    useEffect(() => {
        fetchTipeKontak();
    },[fetchTipeKontak])

    const fetchProvinsi = useCallback(async() => {
        try{
            const res = await wilayahService.getProvinsi(`tipe=all`, 10, 10);
            const newData = await res;
            let tmp = []
            tmp.push({value : '', label : ' -- Pilih Provinsi -- '})
            newData.data.map(post => tmp.push({label : post.nama, value : post.kode}))
            setWilayahObj(wilayahObj => ({...wilayahObj, provinsi : tmp}));
            setFormData(formData => ({...formData, provinsi_id : {...formData.provinsi_id, obj : tmp}}))
        }catch(error){
            setAlertValue(alertValue => ({...alertValue, show: false, text : 'unable to fetch data provinsi'}))
        }
    },[])
    
    useEffect(() => {
        if (props.modal && props.tipe === 'is_suplier'){
            setFormData(formData => ({...formData, 
                tipe_kontak : {...formData.tipe_kontak, show: false, required: false},
                jenis_nik : {...formData.jenis_nik, show: false, required: false},
                no_ktp : {...formData.no_ktp, show: false, required: false},
                jk : {...formData.jk, show: false, required: false},                
            }))
        }
        if (props.hideJenisKontak && props.tipe_kontak){
            if (parseInt(props.tipe_kontak) === 1){
                setFormData(formData => ({...formData,                     
                    jenis_nik : {...formData.jenis_nik, show: true, required: true},
                    no_ktp : {...formData.no_ktp, show: true, required: true},
                    jk : {...formData.jk, show: true, required: true},   
                    hp : {...formData.hp, label : 'Nomor Hp *'}
                }))
            }else{
                setFormData(formData => ({...formData,                     
                    jenis_nik : {...formData.jenis_nik, show: false, required: false},
                    no_ktp : {...formData.no_ktp, show: false, required: false},
                    jk : {...formData.jk, show: false, required: false},
                    hp : {...formData.hp, label : 'Nomor Telp *'}
                }))
            }      
            setFormData(formData => ({...formData, 
                tipe_kontak : {...formData.tipe_kontak, show: false, value : props.tipe_kontak},
            }))
        }
    },[props.modal, props.tipe, props.hideJenisKontak, props.tipe_kontak])

    const fetchKabupaten = useCallback(async(provinsi_id) => {
        try{
            const res = await wilayahService.getKabupaten(`provinsi=${provinsi_id}`);
            const newData = await res;
            let tmp = []
            tmp.push({value : '', label : '-- Pilih Kabupaten --'})            
            newData.data.map(post => tmp.push({value : post.kode, label: post.nama}))
            setFormData(formData => ({...formData, kabupaten_id : {...formData.kabupaten_id, obj : tmp}}));
        }catch(error){
            setAlertValue(alertValue => ({...alertValue, show : true, text : ''}))
        }
    },[]);

    const fetchKecamatan = async(kabupaten_id) => {
        try{
            const res = await wilayahService.getKecamatan(`kabupaten_id=${kabupaten_id}`);
            const newData = await res;
            let tmp = []
            tmp.push({value : '', label : '-- Pilih Kecamatan --'})            
            newData.data.map(post => tmp.push({value : post.kode, label: post.nama}))
            setFormData(formData => ({...formData, kecamatan_id : {...formData.kecamatan_id, obj : tmp}}))
        }catch(error){
            setAlertValue(alertValue => ({...alertValue, show: true, text : ''}))
        }
    }

    const fetchDesa = async(kecamatan_id) => {
        try{
            const res = await wilayahService.getDesa(`kecamatan_id=${kecamatan_id}`);
            const newData = await res;
            let tmp = []
            tmp.push({value : '', label : '-- Pilih Desa / Kelurahan --'})
            newData.data.map(post => tmp.push({value : post.kode, label: post.nama}))
            setFormData(formData => ({...formData, desa_id : {...formData.desa_id, obj : tmp}}))
        }catch(error){
            setAlertValue(alertValue => ({...alertValue, show: true, text : ''}))
        }
    }

    const handleOnBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData, [name] : {...formData[name], showError: true}}))
        }else{
            setFormData(formData => ({...formData, [name] : {...formData[name], showError: false}}))
        }
    }
    
    const handleSubmit = async() => {
        setIsLoading(true);
        let countError = 0;
        let array = {...formData};        
        Object.entries(array).map(([index, post]) => {
            if (post.required){
                if (post.value === '' || parseInt(post.value) === 0){
                    post.showError = true;
                    countError++;
                }else{
                    post.showError = false;
                }
            }else{
                post.showError = false;
            }
            return true;
        })        
        if (countError > 0){
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data'}));
            setIsLoading(false);
            return;
        }        
        let form = {}
        // const form = array.reduce((obj, item) => Object.assign(obj, { [item.name] : item.value}), {});
        Object.entries(array).map(([index, post]) => form[index] = post.value);        
        if (props.modal){
            form[props.tipe] = true;    
        }        
        let newForm = new FormData();
        newForm.append('formData', JSON.stringify(form));
        try{
            let res;
            if (isEdit){
                res = await kontakService.putKontak(kontakId, newForm);                
            }else{
                res = await kontakService.postKontak(newForm);
            }
            const newData = await res;
            setIsLoading(false);
            if (newData.sukses === 'no'){
                setAlertValue(alertValue => ({...alertValue, show: true, text : newData.pesan}))
            }else{
                if (props.modal){
                    props.handleAdd();
                }else{
                    history.push('/kontak');
                }
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable To Save'}))
        }
    }

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue => ({...alertValue, show: false, text : ''})}))
    }

    useEffect(() => {
        fetchProvinsi()
    }, [fetchProvinsi]);
    
    
    const handleCancel = () => {
        if (props.modal){
            props.handleAdd();
        }else{
            props.history.goBack()
        }
    }

    const fetchDetail = useCallback(async(kontakId) => {
        setIsLoading(true)
        try{
            const res = await kontakService.getKontak(`id=${kontakId}`);
            if (res.sukses === 'yes'){
                setFormData(formData => ({...formData,
                    tipe_kontak : {...formData.tipe_kontak, value : res.data.tipe_kontak_id},
                    nama : {...formData.nama, value : res.data.nama},
                    jk : {...formData.jk, value : res.data.jk_id},
                    jenis_nik : {...formData.jenis_nik, value : res.data.jenis_nik_id},
                    nation : {...formData.nation, value : res.data.kewarganegaraan},
                    no_ktp : {...formData.no_ktp, value : res.data.no_nik},
                    alamat : {...formData.alamat, value : res.data.alamat},
                    hp : {...formData.hp, value : res.data.hp},
                    provinsi_id : {...formData.provinsi_id, value : res.data.provinsi_id},
                    kabupaten_id : {...formData.kabupaten_id, value : res.data.kabupaten_id},
                    kecamatan_id : {...formData.kecamatan_id, value : res.data.kecamatan_id},
                    desa_id : {...formData.desa_id, value : res.data.desa_id},
                }));
                fetchKabupaten(res.data.provinsi_id);
                fetchKecamatan(res.data.kabupaten_id);
                fetchDesa(res.data.kecamatan_id);
            }
            setIsLoading(false);            
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch detail'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false, text : 'Unable to fetch detail'}))
            }, 5000)
        }
    },[fetchKabupaten])

    useEffect(() => {
        if (kontakId !== ''){
            console.log('kontakId ======', kontakId)
            fetchDetail(kontakId);
            setIsEdit(true);
        }
    },[kontakId, fetchDetail])

    return(
        <Fragment>
            { isLoading &&
                <LoaderComponent/>
            }       
            <AlertAbsoluteComp
                text={alertValue.text}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />            
            <CardTemplate
                lg={12}
                headerTitle={props.title ? props.title :  "Tambah Kontak"}
            >
                <CardBody>
                    <FormTextArrayComp
                        data={formData}
                        onChange={handleChange}
                        onBlur={handleOnBlur}
                    />                    
                </CardBody>
                <CardFooter>
                    <Button color="primary" size="sm" onClick={handleSubmit}>
                        Simpan
                    </Button>
                    &nbsp;
                    <Button color="secondary" size="sm" onClick={handleCancel}>Cancel</Button>
                </CardFooter>
            </CardTemplate>
        </Fragment>
    )
}
export default KontakAddPage;