import { createSlice } from '@reduxjs/toolkit';
import * as masterService from '../services/masterService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    coffees : [],
    coffee : {},
    list_page : [],
    pesan : ''
}

const coffeeStoreSlice = createSlice({
    name : 'coffeeStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.coffees = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.coffee = action.payload;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.coffees = state.coffees.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan } = coffeeStoreSlice.actions;

export const coffeeSelector = (state) => state.coffees;

export default coffeeStoreSlice.reducer;


export function fetchCoffee(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await masterService.getCoffee(q, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function fetchDetail(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await masterService.getCoffee(`id=${id}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function deleteCoffee(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await masterService.deleteCoffee(id);
            const data = await response;
            if (data.sukses === 'yes'){
                console.log('sukses')
                dispatch(deleteData(id));
                dispatch(setPesan(data.pesan))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}