import React, { Fragment } from 'react';
import { Table } from "reactstrap";


const GuestHouseTable = (props) => {
    const { listData } = props;
    return(
        <Table style={{width:"100%", borderCollapse:"collapse"}} bordered className={`table-center table-small`}>
            <thead style={{backgroundColor:"#c0c0c0", zIndex: 9999}}>
                <tr>
                    <th>#</th>                                
                    <th>Nama</th>
                    <th>Alamat</th>                                
                    <th>Nomor Kamar</th>
                    <th>C/I</th>
                    <th>C/O</th>                                
                    <th>Jumlah Tamu</th>                                
                </tr>
            </thead>
            <tbody>
                { listData?.map((post, index) => {
                    return(                                        
                        <Fragment key={index}>
                            { post.detail.map((detail, indexDetail) => {
                                return(
                                    <tr key={index+indexDetail}>
                                        <td className='text-right'>{indexDetail+index+1}</td>
                                        <td>{post.konsumen_name}</td>
                                        <td>{post.alamat } { post.kota_name } </td>
                                        <td className='text-center'>{detail.nama_produk}</td>
                                        {/* <td className='text-center'>{post.tgl_checkin_actual}</td> */}
                                        <td className='text-center'>{post.tgl_checkin_manual}</td>
                                        <td className='text-center'>{detail.tgl_checkout}</td>   
                                        <td className='text-right'>{detail.jml_tamu}</td>
                                    </tr>
                                )
                            })} 
                        </Fragment>
                    )
                })}
            </tbody>
        </Table>
    )
}
export default GuestHouseTable;