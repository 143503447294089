import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import FbStrukCetak from './views/FoodnBeverage/Laporan/FbStrukCetak';
import KasirCetak from './views/Kasir/KasirCetak';
import KontakCetak from './views/Kontak/Kontak/KontakCetak';
import KasBankCetak from './views/Laporan/KasBankCetak';
import KasBankGlobalCetak from './views/Laporan/KasBankGlobalCetak';
import { CetakMasterBill } from './views/Room';
import LaporanDrrCetak from './views/Room/Laporan/LaporanDrrCetak';
import InHouseCetak from './views/Room/Room/Laporan/InHouseCetak';


import { unregister } from './serviceWorker';
import InHousePenjualanCetak from './views/Room/Room/Laporan/InHousePenjualanCetak';
import GuestHousePrint from './views/Laporan/DailyReport/GuestHousePrint';
import ActiveBillPrint from './views/Laporan/DailyReport/ActiveBillPrint';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const LoginPage = React.lazy(() => import('./views/Authentication/LoginPage'))
// const CetakMasterBill = React.lazy(() => import('./views/Room/Room/CetakMasterBill'));
unregister();	

class App extends Component {
  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} /> */}
              <Route exact path="/login" name="Login Page" render={props => <LoginPage {...props}/>} />
              {/* <Route path="/guest/billing/print/:billingId" name="Cetak" render={props => <CetakMasterBill {...props}/>} /> */}
              <Route path="/fo/billing/print/:billingId" name="Cetak" component={CetakMasterBill} />
              <Route path="/laporan/kasir/cetak" name="Cetak Laporan Kasir" component={KasirCetak} />
              <Route path="/laporan/transaksi-hu" name="Cetak Laporan HU" component={KasBankCetak} />
              <Route path="/kontak/cetak" name="Cetak Kontak" component={KontakCetak} />
              <Route path="/fo/cetak" name="In House Report" component={InHouseCetak} />
              <Route path="/laporan/guest-house" name="Guest House" component={GuestHousePrint} />
              <Route path="/laporan/active-bill" name="Guest House" component={ActiveBillPrint} />
              <Route path="/fo/penjualan-cetak" name="In House Report" component={InHousePenjualanCetak} />
              <Route path="/fb/cetak-struk" name="Cetak Struk" component={FbStrukCetak} />
              <Route path="/fo/laporan-drr/cetak" name="Cetak Laporan DRR" component={LaporanDrrCetak} />
              <Route path="/laporan/transaksi-hu-global" name="Cetak Laporan HU Global" component={KasBankGlobalCetak} />
              {/* <Route path="/guest/billing/print/:billingId" name="Cetak" render={props => <CetakMasterBill {...props}/>} /> */}
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
