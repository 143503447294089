import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Col, FormGroup, Label, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import DoubleBed from '../../../../assets/svg/DoubleBed';
import SingleBed from '../../../../assets/svg/SingleBed';
import TwinBed from '../../../../assets/svg/TwinBed';
import FormTextSingleComp from '../../../../containers/Components/FormTextSingleComp';
import LoaderComponent from '../../../../containers/Components/LoaderComponent';
import ModalComp from '../../../../containers/Components/ModalComp';
import { fetchRoom } from '../../../../slices/room';
import '../../../../style/roomStyle.css';
import * as produkService from '../../../../services/produkService';
import config from '../../../../config';

const RoomStatusPage = (props) => {
    const dispatch = useDispatch();
    const { rooms } = useSelector(state => state);
    const [listData, setListData] = useState([]);
    const [modalValue, setModalValue] = useState({
        'show' : false,
        'title' : 'Change State Room',                
    });
    const [enableButton, setEnableButton] = useState(false);
    const [formRoom, setFormRoom] = useState({
        roomId : 0,
        stateObj : [],
        stateValue : 0,
        errorShow : false,
    });
    const initAlert = {show: false, text : '', color : 'danger'}
    const [alertValue, setAlertValue] = useState(initAlert);
    const [isLoading, setIsLoading] = useState(false);
    const [pageValue, setPageValue] = useState({
        'page' : 1,
        'obj' : [],
        'search' : ''
    })
    
    const handleCloseModal = () => {
        setModalValue(modalValue => ({...modalValue, show: false}))
    }

    useEffect(() => {
        dispatch(fetchRoom());
    }, [dispatch]);

    const fillColor = (params) => {
        if (params === 1){
            return "#0088aa"
        }else{
            return "#b83f30"
        }
    }

    const getClassName = (state) => {
        if (state === 1){
            return "active";
        }else{
            return "not-active"
        }
    }


    useEffect(() => {
        setListData(rooms.rooms);
    }, [rooms.rooms])
        
    const handleChangeState = (post) => {
        let tmp = [{value : 0, label : ' -- Choose State --'}];
        post.room_state_obj.map(post => tmp.push({label : post.label, value : post.value}));        
        setFormRoom(formRoom => ({...formRoom,
            roomId : post.id,
            stateObj : tmp,
        }));
        setModalValue(modalValue => ({...modalValue, show: true}))

    }

    const handleChangeRoom = (e) => {
        const { value } = e.target;
        setFormRoom(formRoom => ({...formRoom, stateValue : value}));
        if (value === 0 || value === '0'){
            setEnableButton(false);
        }else{
            setEnableButton(true);
        }
    }

    const handleBlurRoom = (e) => {
        const { value } = e.target;
        if (value === 0 || value === '0'){
            setFormRoom(formRoom => ({...formRoom, errorShow : true}));
            setEnableButton(false);
        }else{
            setFormRoom(formRoom => ({...formRoom, errorShow : false}));
            setEnableButton(true);
        }
    }

    const handleSubmitChangeState = async() => {
        setIsLoading(true);
        if (formRoom.stateValue === 0 || formRoom.stateValue === '0'){
            setFormRoom(formRoom => ({...formRoom, errorShow : true}));
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Lengkapi Data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000)
            return;
        }
        try{
            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(formRoom));
            const res = await produkService.changeRoomState(formRoom.roomId, newForm);
            let color = ""
            setIsLoading(false);
            if (res.sukses === 'yes'){
                color = "success";
                dispatch(fetchRoom(`q=${pageValue.search}`, config.itemPerPage, pageValue.page));
                setModalValue(modalValue => ({...modalValue, show : false}));
            }else{
                color = "danger"
            }
            setAlertValue(alertValue => ({...alertValue, show : true, text : res.pesan, color : color}))
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000)
        }catch(error){
            setIsLoading(false);
            setAlertValue(initAlert)
        }
    }

    const handlePaginate = (page) => {
        dispatch(fetchRoom(`q=${pageValue.search}`, config.itemPerPage, page));
        setPageValue(pageValue => ({...pageValue, page: page}))
    }

    
    return(        
        <Fragment>
            <Alert isOpen={alertValue.show} color={alertValue.color} toggle={() => setAlertValue(initAlert)}>
                {alertValue.text}
            </Alert>    
            { (isLoading || rooms.loading) &&
                <LoaderComponent/>
            }
            <ModalComp
                isOpen={modalValue.show}
                title={modalValue.title}
                onClose={handleCloseModal}
                submitShow={enableButton}
                onSubmit={handleSubmitChangeState}
                submitText="Save"
            >
                <Alert isOpen={alertValue.show} toggle={() => setAlertValue(initAlert)} color={alertValue.color}>
                    {alertValue.text}
                </Alert>
                <FormGroup>
                    <Label>Change State To</Label>
                    <FormTextSingleComp
                        tipe="select" 
                        id="stateValue"                      
                        value={formRoom.stateValue}
                        onChange={handleChangeRoom}
                        name="stateValue"
                        onBlur={handleBlurRoom}
                        obj={formRoom.stateObj}
                        show={true}
                    />
                    {formRoom.errorShow && 
                        (<div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>'Wajib diisi'</div>)                        
                    }
                </FormGroup>
            </ModalComp>
            <Row>
                <Col lg={10}>                    
                    <Row>
                        { listData.map((post, index) => {
                            return(
                                <Col lg={3} key={index}>
                                    <div className={`room-status-container  ${getClassName(post.room_state_id)}`}>
                                        <div className='room-status-content'>
                                            <div className="room-number-container">
                                                <div className="room-number-content">
                                                    { post.nama }
                                                </div>
                                                <div className="room-number-state">
                                                    { post.room_state_name}
                                                </div>
                                            </div>     
                                            <div className="room-icon-container">
                                                <div className="room-icon-content" style={{width:'50px', height: '50px'}}>
                                                    { post.tipe_bed_id === 1 &&
                                                        <SingleBed width={50} height={50} fill={fillColor(post.room_state_id)}/>
                                                    }
                                                    { post.tipe_bed_id === 2 && 
                                                        <DoubleBed width={50} height={50} fill={fillColor(post.room_state_id)}/>
                                                    }
                                                    { post.tipe_bed_id === 3 &&
                                                        <TwinBed width={50} height={50} fill={fillColor(post.room_state_id)}/>
                                                    }
                                                </div>
                                                <div className="room-name">{post.tipe_kamar_name}</div>
                                            </div>                           
                                        </div>
                                        <div className='room-status-footer'>
                                            { post.room_state_obj.length > 0 &&
                                                <div onClick={() => handleChangeState(post)}>Change State <i className='fa fa-arrow-circle-right'></i></div>                                            
                                            }    
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}                        
                    </Row>
                    { rooms.list_page.length > 0&&
                        <Pagination>
                            { rooms.list_page.map((post, index) => {
                                return(
                                    <PaginationItem key={index} className={pageValue.page === post ? 'active' : ''}>
                                        <PaginationLink tag="button" onClick={() => handlePaginate(post)}>
                                            {post}
                                        </PaginationLink>
                                    </PaginationItem>
                                )
                            })}
                        </Pagination>                    
                    }
                </Col>                
            </Row>
        </Fragment>        
    )
}
export default RoomStatusPage;