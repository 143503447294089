import React, { Fragment, useState, useCallback, useEffect } from 'react';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { CardBody, CardFooter, Button, Alert } from 'reactstrap';
import FormTextComp from '../../../containers/Components/FormTextComp';
import LoaderComponent from '../../../containers/Components/LoaderComponent';

import * as produkService from '../../../services/produkService';
import { useLocation, useHistory } from 'react-router-dom';

const TipeBedAddPage = (props) => {
    const [form, setForm] = useState({
        'nama' : '',
        'id' : 0
    });
    const history = useHistory();
    const location = useLocation();
    const tipeBedId = location.state && location.state.tipeBedId ? location.state.tipeBedId : sessionStorage.getItem('tipeBedId');
    const [errors, setErrors] = useState({
        'nama' : false,        
    });
    const [alertValue, setAlertValue] = useState({
        show: false,
        text : '',
        color : 'danger'
    });
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(form => ({...form, [name] : value}));
    }

    const onBlur = e => {
		validateForm(e);
    }    

	const validateForm = (key) => {                          
        if (form[key] === ''){
            setErrors(errors => ({...errors, [key] : true}))
        }else{
            setErrors(errors => ({...errors, [key] : false}))
        }        
    }
    
    const onSubmit = async() => {
        setIsLoading(true);
        let error = 0;        
        if(error > 0){
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Isi data dengan benar'}));
            return;
        }

        // for number
        let newFormData = {...form}
        try{    
            let newForm = new FormData()
            newForm.append('formData', JSON.stringify(newFormData));
            let res;
            if (isEdit){
                res = await produkService.putTipeBed(tipeBedId, newForm);
            }else{
                res = await produkService.postTipeBed(newForm);
            }
            const newData = await res;
            setIsLoading(false);
            if (newData.sukses === 'yes'){
                if (isEdit){
                    sessionStorage.removeItem('tipeBedId');
                }
                history.push('/room/master/tipe-bed')                
            }else{
                setAlertValue(alertValue => ({...alertValue, show: true, text : newData.pesan}));
            }            
        }catch(error){
            setIsLoading(false);            
        }

    }

    const fetchDetail = useCallback(async(tipeBedId) => {
        setIsLoading(true);
        try{
            const res = await produkService.getTipeBed(`id=${tipeBedId}`, 1, 1);
            const newData = await res;
            setForm(formData => ({...formData,
                id : newData.data.id,
                nama : newData.data.nama
            }));
            setHasError(false);
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setHasError(true);
        }
    },[])

    useEffect(() => {
		if (location.state && location.state.tipeBedId) {
            sessionStorage.setItem('tipeBedId', location.state.tipeBedId);
            setIsEdit(true);
            fetchDetail(location.state.tipeBedId);
        }
        if(sessionStorage.getItem('tipeBedId')){
            setIsEdit(true);
            fetchDetail(sessionStorage.getItem('tipeBedId'));
        }
			
    }, [tipeBedId, location.state, fetchDetail]);
    
    return(
        <Fragment>
            { isLoading &&
                <LoaderComponent/>
            }
            
            <Alert color={alertValue.color} isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false}))}>
                        {alertValue.text}
            </Alert>
            <CardTemplate
                xs={12}
                lg={6}
                colClassName="offset-lg-3"
                headerTitle="Tambah Tipe Tempat Tidur"
            >
                <CardBody>
                {hasError && <p>Unable to load edit page</p>}
                    <FormTextComp		
                        onChange={handleChange}
                        errors={errors}
                        onBlur={() => onBlur('nama')}
                        data={{
                            'name': 'nama',
                            'desc': 'Nama Tipe Tempat Tidur',
                            'placeHolder' : 'ex: Twin Bed',
                            'tipe': 'text',
                            'id': 'nama',
                            'required': true,
                            'value': form.nama,                  
                            'rule' : { required : true}
                        }}
                        formData={form}
                    />
                </CardBody>
                <CardFooter>
                    <Button color="primary" size="sm" onClick={onSubmit}>Simpan</Button>
                    &nbsp;<Button color="danger" size="sm" onClick={() => props.history.goBack()}>Cancel</Button>
                </CardFooter>
            </CardTemplate>
        </Fragment>
    )
}
export default TipeBedAddPage;