import React, { Fragment, useEffect, useState } from 'react';
import CardTemplate from '../../../containers/Components/CardTemplate';
import SearchComp from '../../../containers/Components/SearchComp';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProduk, deleteProduk } from '../../../slices/produk';
import { CardBody, Row, Col, Button, Table, ButtonGroup, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ModalComp from '../../../containers/Components/ModalComp';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import config from '../../../config';

const ProdukPage = (props) => {
    const dispatch = useDispatch();
    const { produks } = useSelector(state => state);
    const history = useHistory();
    const [modalValue, setModalValue] = useState({
        show: false,
        text : ''
    });
    const [pageValue, setPageValue] = useState({
        page : 1,
        obj : [],
        search : ''
    });
    const tipe_stock = props.tipe_stock || ''
    const [produkId, setProdukId] = useState(0);    
    
    const handleAdd = () => {        
        sessionStorage.removeItem('produkId');        
        history.push('/master/produk/add');
    }
    useEffect(() => {        
        dispatch(fetchProduk(`tipe_stock=${tipe_stock}`));        
    },[dispatch, tipe_stock])

    const handleEdit = (id) => {        
        history.push({ pathname : '/master/produk/edit', state: {produkId: id}});
    }

    const handleDelete = (post) => {
        setProdukId(post.id);
        setModalValue(modalValue => ({...modalValue,
            text : `Apakah anda yakin akan menghapus produk ${post.nama} ?`,
            show : true
        }))
    }

    const handleCloseModal = () => {
        setModalValue(modalValue => ({...modalValue, show: false, text : ''}))
    }

    const handleSubmitDelete = () => {
        dispatch(deleteProduk(produkId));
        handleCloseModal();
    }

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setPageValue(pageValue => ({...pageValue, search: value}))
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, page: 1}))
        dispatch(fetchProduk(`q=${pageValue.search}&tipe_stock=${tipe_stock}`, config.itemPerPage, 1));
    }

    useEffect(() => {        
        setPageValue(pageValue => ({...pageValue, obj : produks.list_page}))
    },[produks]);

    const handlePage = (page) => {
        dispatch(fetchProduk(`q=${pageValue.search}&tipe_stock=${tipe_stock}`, config.itemPerPage, page));
        setPageValue(pageValue => ({...pageValue, page : page}))
    }

    return(
        <Fragment>
            { produks.loading &&
                <LoaderComponent/>
            }
            <ModalComp
                isOpen={modalValue.show}
                onClose={handleCloseModal}
                title="Konfirmasi"
                body={modalValue.text}
                onSubmit={handleSubmitDelete}   
                submitShow={true}             
            />
            <CardTemplate
                headerTitle="Master Produk"
                lg={12}
                md={12}
                xs={12}                
            >
                <CardBody>
                    <Row>
                        <Col lg={10} md={10} xs={10}>
                            <SearchComp
                                placeHolder="Cari Produk"
                                value={pageValue.search}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDown}
                                onClick={handleSearch}
                            />
                        </Col>
                        { props.modal ?
                            <Fragment>
                                {props.button_add &&
                                    <Col lg={2} md={2} xs={2}>
                                        <Button block color="warning" onClick={handleAdd}>
                                            <i className="fa fa-plus"></i>&nbsp;
                                        </Button>
                                    </Col>                                
                                }
                            </Fragment>
                            :
                            <Col lg={2} md={2} xs={2}>
                                <Button block color="warning" onClick={handleAdd}>
                                    <i className="fa fa-plus"></i>&nbsp;
                                </Button>
                            </Col>
                        }
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Kode</th>
                                <th>Nama</th>
                                <th>Kategori</th>
                                <th>Satuan</th>                    
                                <th>History Stock</th>                           
                                <th>Tipe Produk</th>
                                <th>Stok Akhir</th>  
                                <th>Harga Jual</th>
                                { !props.modal &&
                                    <th>Action</th>                                
                                }                         
                            </tr>
                        </thead>
                        <tbody>
                            { produks.produks.map((post, index) => {
                                return(
                                    <tr key={index} className={props.modal ? 'click-td' : ''}>
                                        <td onClick={() => props.handleClick(post)}>{post.nomor}</td>
                                        <td onClick={() => props.handleClick(post)}>{post.kode}</td>
                                        <td onClick={() => props.handleClick(post)}>{post.nama}</td>
                                        <td onClick={() => props.handleClick(post)}>{post.kategori_name}</td>
                                        <td onClick={() => props.handleClick(post)}>{post.satuan_name}</td>
                                        <td onClick={() => props.handleClick(post)}>{post.stockable}</td>
                                        <td onClick={() => props.handleClick(post)}>{post.tipe_produk}</td>
                                        <td onClick={() => props.handleClick(post)}>{post.stock}</td>
                                        <td onClick={() => props.handleClick(post)}>{post.harga_jual.toLocaleString()}</td>
                                        { !props.modal &&
                                            <td>
                                                <ButtonGroup>
                                                    <Button className='btn-edit' color='primary' onClick={() => handleEdit(post.id)}>
                                                        <i className='fa fa-edit'></i>&nbsp;Edit
                                                    </Button>
                                                    <Button className='btn-edit' color='danger' onClick={() => handleDelete(post)}>
                                                        <i className='fa fa-trash'></i>&nbsp;Hapus
                                                    </Button>
                                                </ButtonGroup>
                                            </td>                                        
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    { pageValue.obj.length > 1 &&
                        <Pagination>
                            { pageValue.obj.map((post, index) => {
                                return(
                                    <PaginationItem key={index} className={post === pageValue.page ? 'active' : ''}>
                                        <PaginationLink tag="button" onClick={() => handlePage(post)}>
                                            {post}
                                        </PaginationLink>
                                    </PaginationItem>
                                )
                            })}
                        </Pagination>
                    }
                </CardBody>
            </CardTemplate>
        </Fragment>
    )
}
export default ProdukPage;