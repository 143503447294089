import React, { Fragment,} from 'react';
import {  Button, ButtonGroup, Table } from 'reactstrap';

const TransaksiDetailComp = (props) => {
    return(
        <Fragment>            
            <Table bordered className={props.tableClassName ? `${props.tableClassName} table-center` : 'table-center'}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Group</th>
                        <th>Tanggal</th>
                        <th>Uraian</th>
                        <th>Debet</th>
                        <th>Kredit</th>
                        { (props.showAction || props.showRefund) &&
                            <th>Action</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {props.balance.map((post, index) => {
                        return(
                            <tr key={index}>
                                <td colSpan={4} className='text-center'>Open Balance</td>
                                <td className='text-right'>{post.ammdb_balance.toLocaleString()}</td>
                                <td className='text-right'>{post.ammcr_balance.toLocaleString()}</td>
                            </tr>
                        )
                    })}
                    { props.listData.map((post, index) => {
                        return(
                            <tr key={index}>
                                <td className='text-right'>{post.nomor}</td>
                                <td className='text-right'>{post.nogroup}</td>
                                <td>{post.tanggal}</td>
                                <td>{post.trxname}</td>
                                <td className='text-right'>{post.ammdb.toLocaleString()}</td>
                                <td className='text-right'>{post.ammcr.toLocaleString()}</td>
                                { (props.showAction || props.showRefund) &&
                                    <td className='text-center'>
                                        <ButtonGroup>
                                            { props.showAction &&
                                                <Button className='btn-edit' color='danger' onClick={() => props.handleBayar(post)}>Bayar</Button>
                                            }
                                            { props.showRefund &&
                                                <Button className='btn-edit' color='danger' onClick={() => props.handleRefund(post)}>Refund</Button>
                                            }
                                        </ButtonGroup>
                                    </td>
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </Table>        
        </Fragment>
    )
}
export default TransaksiDetailComp;