import { createSlice } from '@reduxjs/toolkit';
import * as checkinService from '../services/checkinService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    guests : [],
    guest : {},
    list_page : [],
    pesan : ''
}

const guestStoreSlice = createSlice({
    name : 'guestStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.guests = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.guest = action.payload;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.guests = state.guests.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan } = guestStoreSlice.actions;

export const guestSelector = (state) => state.guests;

export default guestStoreSlice.reducer;


export function fetchGuest(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await checkinService.getGuest(q, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                dispatch(setPesan(data.pesan))
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function fetchGuestbyDetail(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await checkinService.getGuestbyDetail(q, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                console.log('inilah hasilnya', data)
                dispatch(setPesan(data.pesan))
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}


export function fetchGuestDetail(id, q=''){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await checkinService.getGuest(`id=${id}&${q}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(setPesan(data.pesan))
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

// export function deleteGuest(id){
//     return async(dispatch) => {
//         dispatch(getData());
//         try{
//             const response = await checkinService.deleteGuest(id);
//             const data = await response;
//             if (data.sukses === 'yes'){
//                 dispatch(deleteData(id));
//                 dispatch(setPesan(data.pesan))
//             }else{
//                 dispatch(getDataFailure());
//             }
//         }catch(error){
//             dispatch(getDataFailure());
//         }
//     }
// }