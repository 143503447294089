import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { CardBody, Alert, CardFooter, Button } from 'reactstrap';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import FormTextComp from '../../../containers/Components/FormTextComp';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchDetail } from '../../../slices/meetingroom'

import * as produkService from '../../../services/produkService';

const MeetingRoomAddPage = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch(); 
    const [isLoading, setIsLoading] = useState(false);   
    const { meetingrooms } = useSelector(state => state);
    const [isEdit, setIsEdit] = useState(false);
    const meetingroomId = location.state && location.state.meetingroomId ? location.state.meetingroomId : sessionStorage.getItem('meetingroomId');
    

    const [alertValue, setAlertValue] = useState({
        color : 'danger',
        text : '',
        show : false
    });            

    const [formData, setFormData] = useState({
        nama : '',
        kapasitas : 0,        
        harga : 0,
        id : 0,
        tipeProduk: 4 
    })

    let tmpFormData = {};
	Object.entries(formData).map( ([key, value]) => tmpFormData[key] = false)
	const [errors, setErrors] = useState(
		tmpFormData
	)    

    const arrayForm = [
        {
			'name': 'nama',
			'desc': 'Nama Meeting Room',
			'tipe': 'text',
			'id': 'nama',
			'required': true,
			'value': formData.nama,
			'errors' : errors.nama,
			'rule' : { required : true}
		},
		{
            'name': 'kapasitas',
			'desc': 'Kapasitas (Orang)',
			'tipe': 'text',
			'id': 'kapasitas',
			'required': true,
			'value': formData.kapasitas,			
            'errors' : errors.kapasitas,            
			'rule' : { required : true}
        },        
        {
            'name': 'harga',
			'desc': 'Harga',
			'tipe': 'number',
			'id': 'tipeBedId',
			'required': true,
			'value': formData.harga,
			'readOnly': true,
            'errors' : errors.harga,            
			'rule' : { required : true}
        }
    ]
    

    const handleChange = e => {
		const { name, value } = e.target;	
		let nilai = value 
        if (name === 'harga'){
            nilai = nilai.replace('Rp.', '');
            nilai = nilai.replace(/,/g, '');
        }
        setFormData({...formData, [name] : nilai});
    }
    
    const onBlur = e => {
		validateForm(e);
	}

	const validateForm = (key) => {
		let tmpArray = [...arrayForm];
        let tmpData = tmpArray.filter(x => x.name === key);        
		if (tmpData && tmpData[0].required && tmpData[0].value === ''){
			setErrors(errors => ({...errors, [tmpData[0].name] : true}))
		}
    }

    const onSubmit = async() => {
        setIsLoading(true);        
        try{
            let newForm = new FormData()
            newForm.append('formData', JSON.stringify(formData));

            let res;
            if (isEdit){
                res = await produkService.putMeetingRoom(meetingroomId, newForm);                
            }else{
                res = await produkService.postMeetingRoom(newForm);
            }
            
            const newData = await res;
            setIsLoading(false);
            if (newData.sukses === 'no'){
                setAlertValue(alertValue => ({...alertValue,
                    show : true,
                    text : newData.pesan
                }));
                
                setTimeout(() => {
                    handleCloseAlert();
                }, 5000)
            }else{
                sessionStorage.removeItem('meetingroomId');
                history.goBack();
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue,
                show: true,
                text : 'Unable to save Room',                
            }));
            setTimeout(() => {
                handleCloseAlert();
            }, 5000)
        }
    }

    const handleCloseAlert = () => {
        setAlertValue(alertValue => ({...alertValue,
            show: false,
            text : '',
            color : 'danger'
        }))
    }

    

    useEffect(() => {
		if (location.state && location.state.meetingroomId) {
            sessionStorage.setItem('meetingroomId', location.state.meetingroomId);
            setIsEdit(true);
            // fetchDetail(location.state.meetingroomId);
            // dispatch(fetchDetail(location.state.meetingroomId));
        }
        if(sessionStorage.getItem('meetingroomId')){
            setIsEdit(true);
            dispatch(fetchDetail(sessionStorage.getItem('meetingroomId')));
        }
			
    }, [meetingroomId, location.state, dispatch]);
    

    useEffect(() => {
        if(isEdit){        
            setFormData(formData => ({...formData,
                nama : meetingrooms.meetingroom.nama || '',
                tipeKamarId : meetingrooms.meetingroom.tipe_kamar_id || 0,
                tipeBedId : meetingrooms.meetingroom.tipe_bed_id || 0,
                harga : meetingrooms.meetingroom.harga_jual || 0,
                id : meetingrooms.meetingroom.id || 0
            }));        
        }
    },[meetingrooms.meetingroom, isEdit])
    
    return(
        <Fragment>
            { isLoading &&
                <LoaderComponent/>
            }
            <Alert color={alertValue.color} isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false}))}>
                {alertValue.text}
            </Alert>
            <CardTemplate
                xs={12}
                lg={12}
                headerTitle="Tambah Kamar"
            >
                <CardBody>                    
                    { arrayForm.map((post, index) => {
                        return(
                            <FormTextComp
                                key={index}
                                onChange={handleChange}
                                errors={errors}
                                onBlur={() => onBlur(post.name)}
                                data={post}
                                formData={formData}
                            />
                        )
                    }) }
                </CardBody>
                <CardFooter>
                    <Button color="primary" size="sm" onClick={onSubmit}>
                        <i className="fa fa-save"></i>&nbsp;Simpan
                    </Button>
                    &nbsp;
                    <Button color="secondary" size="sm" onClick={() => history.goBack()}>
                        <i className="fa fa-undo"></i>&nbsp;Cancel
                    </Button>
                </CardFooter>
            </CardTemplate>
        </Fragment>
    )
}
export default MeetingRoomAddPage;
