import React from 'react';
import { Fragment } from 'react';
import { Table, Badge } from 'reactstrap';
import GuestListBtnComp from './GuestListBtnComp';

const GuestListCetakComp = (props) => {
    return(
        <Table style={{width:"100%", borderCollapse:"collapse"}} bordered className={props.className ? `table-center table-small ${props.className}` : 'table-center table-small'}>
            <thead style={{backgroundColor:"#c0c0c0", zIndex: 9999}}>
                <tr>
                    <th rowSpan={2}>#</th>
                    <th rowSpan={2}>Registrasi</th>                                        
                    <th rowSpan={2}>Nama</th>
                    <th rowSpan={2}>Kota</th>
                    <th rowSpan={2}>Kamar</th>
                    <th rowSpan={2}>Harga Kamar</th>
                    <th rowSpan={2}>C/I</th>
                    <th rowSpan={2}>C/O</th>
                    <th rowSpan={2}>Lama Menginap</th>                    
                    <th colSpan={2}>Actual</th>               
                    <th rowSpan={2}>Remark</th>
                    <th rowSpan={2}>Note</th>
                    <th rowSpan={2}>Active Bill</th>
                    <th rowSpan={2}>Deposite</th>
                    <th rowSpan={2}>Diskon</th>
                    <th rowSpan={2}>Status</th>
                    { !props.hideButton &&
                        <th rowSpan={2}>Action</th>
                    }
                </tr>
                <tr>
                    <th>C/I</th>
                    <th>C/O</th>
                </tr>
            </thead>
            <tbody>
                { props.data && props.data.length > 0 && props.data.map((post, index) => {
                    return(
                        <Fragment key={index}>
                            { post?.detail?.length === 1 ?
                                <Fragment>
                                    <tr>
                                        <td>{post.nomor}</td>
                                        <td>{post.no_invoice}</td>
                                        <td>{post.konsumen_name}</td>
                                        <td>{post.kota_name}</td>
                                        <td>{post.detail[0] ? post?.detail[0]?.nama_produk : ''}</td>
                                        <td className='text-right'>{post.detail[0] ? post?.detail[0]?.harga_jual.toLocaleString() : ''}</td>
                                        <td>{post.detail[0] ? post?.detail[0]?.tanggal : ''}</td>
                                        <td>{post.detail[0] ? post?.detail[0]?.tgl_checkout : ''}</td>
                                        <td className='text-right'>{post.detail[0] ? post?.detail[0]?.lama_menginap : ''}</td>
                                        
                                        <td>{post.tgl_checkin_actual}</td>
                                        <td>{post.tgl_checkout_actual}</td>                                        
                                        <td>
                                            { post.tipe_penjualan === 3 ?
                                                <Fragment>
                                                    {post.remark}
                                                    <Badge className="mr-1" color="primary">Meeting Room</Badge>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    {post.remark}
                                                </Fragment>
                                            }
                                        </td>
                                        <td>{post.keterangan}</td>
                                        <td className='text-right'>{post.total_invoice.toLocaleString()}</td>
                                        <td className='text-right'>{post.deposite.toLocaleString()}</td>
                                        <td className='text-right'>{post.diskon_nominal && parseInt(post.diskon_nominal).toLocaleString()}</td>
                                        <td>
                                            <Badge className="mr-1" color={post.is_close ? 'danger' : "success"}>{post.is_close ? 'Checkout' : 'Active'}</Badge>
                                        </td>
                                        { !props.hideButton &&
                                            <td>
                                                <GuestListBtnComp
                                                    is_close={post.is_close}
                                                    handleDetail={() => props.handleDetail(post)}
                                                    handleAddDeposite={() => props.handleAddDeposite(post)}
                                                    handleCheckout={() => props.handleCheckout(post)}
                                                    post={post}
                                                    handleChangeRoom={() => props.handleChangeRoom(post)}
                                                />
                                            </td>
                                        }
                                    </tr>
                                </Fragment>
                                :
                                <Fragment>
                                    <tr key={index}>
                                        <td rowSpan={post?.detail?.length}>{post.nomor}</td>
                                        <td rowSpan={post?.detail?.length}>{post.no_invoice}</td>
                                        <td rowSpan={post?.detail?.length}>{post.konsumen_name}</td>
                                        <td rowSpan={post?.detail?.length}>{post.kota_name}</td>
                                        <td>{post?.detail?.length > 0 ? post?.detail[0]?.nama_produk : ''}</td>
                                        <td className='text-right'>{post?.detail?.length > 0 ? post?.detail[0]?.harga_jual.toLocaleString() : ''}</td>
                                        <td>{post?.detail?.length > 0 ? post?.detail[0]?.tgl_checkin : ''}</td>
                                        <td>{post?.detail?.length > 0 ? post?.detail[0]?.tgl_checkout : ''}</td>
                                        <td className='text-right'>{post?.detail?.length > 0 ? post?.detail[0]?.lama_menginap : ''}</td>                                        
                                        
                                        <td rowSpan={post?.detail?.length}>{post.tgl_checkin_actual}</td>
                                        <td rowSpan={post?.detail?.length}>{post.tgl_checkout_actual}</td>
                                        <td rowSpan={post?.detail?.length}>
                                            { post.tipe_penjualan === 3 ?
                                                <Fragment>
                                                    {post.remark}
                                                    <Badge className="mr-1" color="primary">Meeting Room</Badge>
                                                </Fragment>                                                
                                                :
                                                <Fragment>
                                                    {post.remark}
                                                </Fragment>
                                            }
                                        </td>
                                        <td>{post.keterangan}</td>
                                        <td rowSpan={post?.detail?.length} className='text-right'>{post.total_invoice?.toLocaleString()}</td>
                                        <td rowSpan={post?.detail?.length} className='text-right'>{post.deposite?.toLocaleString()}</td>
                                        <td rowSpan={post?.detail?.length} className='text-right'>{post.diskon_nominal && parseInt(post.diskon_nominal).toLocaleString()}</td>
                                        <td rowSpan={post?.detail?.length}>
                                            <Badge className="mr-1" color={post.is_close ? 'danger' : "success"}>{post.is_close ? 'Checkout' : 'Active'}</Badge>
                                        </td>
                                        { !props.hideButton &&
                                            <td rowSpan={post?.detail?.length}>
                                                <GuestListBtnComp
                                                    is_close={post.is_close}
                                                    handleDetail={() => props.handleDetail(post)}
                                                    handleAddDeposite={() => props.handleAddDeposite(post)}
                                                    handleCheckout={() => props.handleCheckout(post)}
                                                    post={post}
                                                    handleChangeRoom={() => props.handleChangeRoom(post)}
                                                />
                                            </td>
                                        }
                                    </tr>
                                    { post?.detail?.slice(1, post?.detail?.length).map((detail, mykey) => {
                                        return(
                                            <tr key={mykey}>
                                                <td>{detail.nama_produk}</td>
                                                <td className='text-right'>{detail.harga_jual.toLocaleString()}</td>
                                                <td>{detail.tgl_checkin}</td>
                                                <td>{detail.tgl_checkout}</td>
                                                <td className='text-right'>{detail.lama_menginap}</td>
                                                                                        
                                            </tr>
                                        )
                                    })}
                                </Fragment>
                                }
                            </Fragment>                        
                    )
                })}
            </tbody>
        </Table>
    )
}

export default GuestListCetakComp;