import api from './api';

// tipe kamar
export function postTipeKamar(formData){
    return api.post(`/produk/api/tipe-kamar`, formData).then(res => res.data);
}

export function putTipeKamar(id, formData){
    return api.put(`/produk/api/tipe-kamar/${id}`, formData).then(res => res.data);
}

export function getTipeKamar(keyword, limit, page){
    return api.get(`/produk/api/tipe-kamar?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function deleteTipeKamar(id){
    return api.delete(`/produk/api/tipe-kamar/${id}`).then(res => res.data)
}

// tipebed
export function getTipeBed(keyword, limit, page){
    return api.get(`/produk/api/tipe-bed?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function postTipeBed(formData){
    return api.post(`/produk/api/tipe-bed`, formData).then(res => res.data);
}

export function putTipeBed(id, formData){
    return api.put(`/produk/api/tipe-bed/${id}`, formData).then(res => res.data);
}

export function deleteTipeBed(id){
    return api.delete(`/produk/api/tipe-bed/${id}`).then(res => res.data)
}

// room / kamar
export function postRoom(formData){
    return api.post(`/produk/api/room`, formData).then(res => res.data);
}

export function putRoom(id, formData){
    return api.put(`/produk/api/room/${id}`, formData).then(res => res.data);
}

export function getRoom(keyword, limit, page){
    return api.get(`/produk/api/room?tipe_produk=3&${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function deleteRoom(id){
    return api.delete(`/produk/api/room/${id}`).then(res => res.data)
}

// meeting room
export function postMeetingRoom(formData){
    return api.post(`/produk/api/room`, formData).then(res => res.data);
}

export function putMeetingRoom(id, formData){
    return api.put(`/produk/api/room/${id}`, formData).then(res => res.data);
}

export function getMeetingRoom(keyword, limit, page){
    return api.get(`/produk/api/room?tipe_produk=4&${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function changeRoomState(id, form){
    return api.post(`/produk/api/change-room-state/${id}`, form).then(res => res.data);
}

export function deleteMeetingRoom(id){
    return api.delete(`/produk/api/room/${id}`).then(res => res.data)
}


// satuan
export function postSatuan(formData){
    return api.post(`/produk/api/satuan`, formData).then(res => res.data);
}

export function putSatuan(id, formData){
    return api.put(`/produk/api/satuan/${id}`, formData).then(res => res.data);
}

export function getSatuan(keyword, limit, page){
    return api.get(`/produk/api/satuan?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function deleteSatuan(id){
    return api.delete(`/produk/api/satuan/${id}`).then(res => res.data)
}

//  mutasi
export function postMutasi(formData){
    return api.post(`/produk/api/mutasi-barang`, formData).then(res => res.data);
}

export function getMutasi(keyword, limit, page){
    return api.get(`/produk/api/mutasi-barang?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

//  produk
export function postProduk(formData){
    return api.post(`/produk/api/produk`, formData).then(res => res.data);
}

export function putProduk(id, formData){
    return api.put(`/produk/api/produk/${id}`, formData).then(res => res.data);
}

export function getProduk(keyword, limit, page){
    return api.get(`/produk/api/produk?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function deleteProduk(id){
    return api.delete(`/produk/api/produk/${id}`).then(res => res.data)
}


//  produk sale
export function getProdukSale(keyword, limit, page){
    return api.get(`/produk/api/produk-sale?${keyword}`, {params:{limit, page}}).then(res => res.data)
}


//  pembelian sale
export function postPembelian(form){
    return api.post(`/pembelian/api/pembelian`, form).then(res => res.data)
}

export function getPembelian(keyword, limit, page){
    return api.get(`/pembelian/api/pembelian?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

// retur
export function putRetur(id, form){
    return api.put(`/pembelian/api/retur/${id}`, form).then(res => res.data)
}

// menu
export function postMenu(form){
    return api.post(`/produk/api/menu`, form).then(res => res.data)
}

export function putMenu(id, form){
    return api.put(`/produk/api/menu/${id}`, form).then(res => res.data)
}

export function deleteMenu(id){
    return api.delete(`/produk/api/menu/${id}`).then(res => res.data)
}

// extra bed
export function getExtraBed(){
    return api.get(`/produk/api/get-extrabed`).then(res => res.data)
}
