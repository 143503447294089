import api from './api';

// meeting room
export function postKategori(formData){
    return api.post(`/produk/api/kategori-produk`, formData).then(res => res.data);
}

export function putKategori(id, formData){
    return api.put(`/produk/api/kategori-produk/${id}`, formData).then(res => res.data);
}

export function getKategori(keyword, limit, page){
    return api.get(`/produk/api/kategori-produk?${keyword}`, {params:{limit, page}}).then(res => res.data)
}

export function deleteKategori(id){
    return api.delete(`/produk/api/kategori-produk/${id}`).then(res => res.data)
}