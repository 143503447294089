import { createSlice } from '@reduxjs/toolkit';
import * as produkService from '../services/produkService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    satuans : [],
    satuan : {},
    list_page : [],
    pesan : ''
}

const satuanStoreSlice = createSlice({
    name : 'satuanStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.satuans = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.satuan = action.payload;
        },
        deleteData : (state, action) => {
            // let tmpData = [...state.satuans];
            let tmpData = state.satuans.satuans;
            console.log('satuans tmpData', tmpData);
            state.loading = false;
            state.hasErrors = false;
            state.satuans = state.satuans.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan } = satuanStoreSlice.actions;

export const satuanSelector = (state) => state.satuans;

export default satuanStoreSlice.reducer;


export function fetchSatuan(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await produkService.getSatuan(`q=${q}`, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function fetchDetail(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await produkService.getSatuan(`id=${id}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function deleteSatuan(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await produkService.deleteSatuan(id);
            const data = await response;
            if (data.sukses === 'yes'){
                console.log('sukses')
                dispatch(deleteData(id));
                dispatch(setPesan(data.pesan))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}