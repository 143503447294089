import React, { Fragment, useEffect, useState } from 'react';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { CardBody, Button, Row, Col, Modal, ModalHeader, ModalFooter, Alert, ModalBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import SearchComp from '../../../containers/Components/SearchComp';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchKontak } from '../../../slices/kontak';
import ListKontak from '../Component/ListKontak';
import config from '../../../config';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import * as kontakService from '../../../services/kontakService'

const KontakPage = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { kontaks } = useSelector(state => state);    
    const [isLoading, setIsLoading] = useState(false);
    const [kontakId, setKontakId] = useState(0);
    const [modalValue, setModalValue] = useState({
        show: false,
    });
    const [pageValue, setPageValue] = useState({
        page : 1,
        search : ''
    })
    const [alertValue, setAlertValue] = useState({
        show: false,
        text : '',
        color : 'danger'
    })
    const handleAdd = () => {
        sessionStorage.removeItem('kontakId');
        if (props.modal){
            props.handleAdd();
        }else{
            history.push('/kontak/add');
        }
    }
    

    const handleDelete = (post) => {
        setKontakId(post.id);        
        setModalValue(modalValue => ({...modalValue, show: true, text : `Apakah anda yakin akan menghapus kontak ${post.nama} ?`}))
    }

    const handleSubmitDelete = async() => {
        setIsLoading(true);
        try{
            const res = await kontakService.deleteKontak(kontakId);
            setIsLoading(false);
            if (res.sukses === 'yes'){
                let q = `q=${pageValue.search}`
                if (props.modal){
                    if (props.tipe){
                        q += `&${props.tipe}=yes`
                    }
                    if (props.query){
                        q += `&${props.query}`
                    }            
                }
                dispatch(fetchKontak(q, config.itemPerPage, pageValue.page));  
            }
            setModalValue(modalValue => ({...modalValue, show : false}))
            setAlertValue(alertValue => ({...alertValue, show : true, text : res.pesan}));
            setTimeout(() => {                
                setAlertValue(alertValue => ({...alertValue, show : false, text : ''}))
            }, 5000);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to delete kontak'}))
            setTimeout(() => {                
                setAlertValue(alertValue => ({...alertValue, show : false, text : ''}))
            }, 5000);
        }
    }
    
    useEffect(() => {
        if (props.modal){
            let q = `q=`
            if (props.tipe){
                q += `&${props.tipe}=yes`
            }
            if (props.query){
                q += `&${props.query}`
            }            
            dispatch(fetchKontak(q, config.itemPerPage, 1));            
        }else{
            dispatch(fetchKontak());
        }
    },[dispatch, props.modal, props.tipe, props.query]);

    const handleCetak = (post) => {
        sessionStorage.setItem('kontakId', post.id);        
        window.open(`#/kontak/cetak`, "_blank")
    }

    const handleEdit = (post) => {
        sessionStorage.setItem('kontakId', post.id);
        if (props.handleEdit){
            props.handleEdit(post);
        }else{
            history.push('/kontak/edit')
        }     
    }

    const handleCloseModal = () => {
        setModalValue(modalValue => ({...modalValue, show: false}))
    }

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setPageValue(pageValue => ({...pageValue, search : value}))
    }

    const handleSearch = () => {
        let q = `q=${pageValue.search}`
        if (props.modal){
            if (props.tipe){
                q += `&${props.tipe}=yes`
            }
            if (props.query){
                q += `&${props.query}`
            }            
        }
        dispatch(fetchKontak(q, config.itemPerPage, 1));
        setPageValue(pageValue => ({...pageValue, page : 1})) 
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {
        setPageValue(pageValue => ({...pageValue, page : page}));
        let q = `q=${pageValue.search}`
        if (props.modal){
            if (props.tipe){
                q += `&${props.tipe}=yes`
            }
            if (props.query){
                q += `&${props.query}`
            }            
        }
        dispatch(fetchKontak(q, config.itemPerPage, page));
    }

    return(
        <Fragment>
            { (isLoading || kontaks.loading) &&
                <LoaderComponent/>
            }
            <Alert isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false}))} color={alertValue.color}>
                {alertValue.text}
            </Alert>
            <Modal isOpen={modalValue.show} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>
                    Konfirmasi
                </ModalHeader>
                <ModalBody>
                    {modalValue.text}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" size="sm" onClick={handleSubmitDelete}>Hapus</Button>
                    <Button color="secondary" size="sm" onClick={handleCloseModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <CardTemplate
                headerTitle={props.title ? props.title : 'Kontak' }
            >            
                <CardBody>
                    <Row>
                        <Col lg={10}>
                            <SearchComp
                                onChange={handleChangeSearch}
                                value={pageValue.search}
                                onKeyDown={handleKeyDown}
                                onClick={handleSearch}
                            />
                        </Col>
                        <Col lg={2} style={{marginBottom: 10}}>
                            <Button onClick={handleAdd} color="warning" block><i className='fa fa-plus'></i>&nbsp; Tambah</Button>
                        </Col>
                    </Row>
                    <ListKontak
                        data={kontaks.kontaks}
                        clickList={props.selectKontak ? props.selectKontak : props.clickList? props.clickList : null}
                        handleCetak={handleCetak}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                    />
                    { kontaks.list_page.length > 0 &&
                        <Pagination>
                            { kontaks.list_page.map((post, index) => {
                                return(
                                    <PaginationItem className={pageValue.page === post ? 'active' : ''} key={index}>
                                        <PaginationLink onClick={() => handlePaginate(post)} tag="button">{post}</PaginationLink>
                                    </PaginationItem>
                                )
                            })}
                        </Pagination>
                    }
                </CardBody>
            </CardTemplate>
        </Fragment>
    )
}
export default KontakPage;