import React, { Fragment } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import NumberFormat from 'react-number-format';

const FormTextComp = (props) => {
    return(        
        <FormGroup>
            <Label htmlFor="name">{props.data.desc}</Label>
            { props.data.tipe === 'number' &&
                <Fragment>
                    <NumberFormat 
                        thousandSeparator={true} 
                        prefix={'Rp.'}                     
                        inputMode="numeric" 
                        className="form-control" 
                        name={props.data.name}
                        value={props.formData[`${props.data.name}`]} 
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                    />
                    {props.errors[props.data.name] && (
                        <div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>{props.data.error_message ? props.data.error_message : 'Wajib diisi'}</div>                        
                    )}
                </Fragment>
            }
            { props.data.tipe === 'text' && 
                <Fragment>
                    <Input                         
                        id={props.data.id} 
                        placeholder={props.data.placeHolder ? props.data.placeHolder :  props.data.desc}                         
                        value={props.formData[`${props.data.name}`]}
                        readOnly={props.data.readOnly} 
                        onChange={props.onChange} 
                        name={props.data.name}                        
                        required={ props.data.required }
                        onBlur={props.onBlur}       
                    />                    
                    {props.errors[props.data.name] && (
                        <div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>{props.data.error_message ? props.data.error_message : 'Wajib diisi'}</div>                        
                    )}
                </Fragment>
            }
            { props.data.tipe === 'select' &&
                <Input 
                    type={props.data.tipe} 
                    id={props.data.id} 
                    placeholder={props.data.desc} 
                    value={props.data.value}
                    onChange={props.onChange}
                    name={props.data.name}
                >
                    { props.data.obj && props.data.obj.length > 0 && props.data.obj.map((post, index) => {
                        return(
                            <option key={index} value={post.id}>{post.value}</option>
                        )
                    })}
                </Input>
            }               
        </FormGroup>
    )
}
export default FormTextComp;