import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import * as kontakService from '../../../services/kontakService';
import * as masterService from '../../../services/masterService'

const KontakCetak = (props) => {
    const [kontakInfo, setKontakInfo] = useState({});
    const kontakId = sessionStorage.getItem('kontakId') || 0;

    const [compInfo, setCompInfo] = useState({
        nama : '',
        alamat : '',
        kota : '',
        telp : '',
    })

    const fetchCompany = useCallback(async() => {
        const res = await masterService.getPerusahaan();
        console.log('res', res.data)
        setCompInfo(compInfo => ({...compInfo,
            nama : res.data.nama,
            alamat : res.data.alamat,
            kota : res.data.kabupaten_name,
            telp : res.data.telp,
            provinsi : res.data.provinsi_name,
        }));        
    },[])

    useEffect(() => {
        fetchCompany();
    },[fetchCompany])

    const fetchData = useCallback(async() => {
        try{
            const res = await kontakService.getKontak(`id=${kontakId}`);
            setKontakInfo(res.data);
        }catch(error){
            console.log(error);
        }
    },[kontakId])

    useEffect(() => {
        fetchData();
    },[fetchData])

    return(
        <Fragment>
            <div className='billprint-container'>
                <Row>
                    <Col xs={12}>
                        <div className="company-name text-center">
                            <img src={require('../../../assets/img/logo_hotel.png')} alt='logo' style={{width: 280, marginBottom: 20}}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="text-center">
                            <div className="company-address">                            
                                { compInfo.alamat }
                            </div>
                            <div className="company-city">
                                { compInfo.kota } Phone { compInfo.telp }
                            </div>                            
                        </div>                        
                    </Col>                    
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="title">
                            Guest Info
                        </div>                        
                    </Col>
                </Row>                
                <Row style={{marginTop: 20}}>
                    <Col xs={12}>
                        <FormGroup row>
                            <Col lg={2}>
                                <Label>Name</Label>
                            </Col>                            
                            <Col lg={8}>
                                :&nbsp;&nbsp;{kontakInfo.nama}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={2}>
                                <Label>Type ID</Label>
                            </Col>                            
                            <Col lg={8}>
                                :&nbsp;&nbsp;{kontakInfo.jenis_nik_id}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={2}>
                                <Label>ID Number</Label>
                            </Col>                            
                            <Col lg={8}>
                                :&nbsp;&nbsp;{kontakInfo.no_nik}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={2}>
                                <Label>Nationality</Label>
                            </Col>                            
                            <Col lg={8}>
                                :&nbsp;&nbsp;{kontakInfo.kewarganegaraan}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col lg={2}>
                                <Label>Address</Label>
                            </Col>                            
                            <Col lg={8}>
                                :&nbsp;&nbsp;{kontakInfo.alamat}
                                <div>&nbsp;&nbsp;{kontakInfo.desa_name} {kontakInfo.kecamatan_name}</div>
                                <div>&nbsp;&nbsp;{kontakInfo.kabupaten_name} {kontakInfo.provinsi_name}</div>
                            </Col>
                        </FormGroup>                        
                    </Col>
                </Row>                
            </div>
        </Fragment>
    )
}
export default KontakCetak;