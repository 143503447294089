import React, { Fragment } from 'react';

const AlertAbsoluteComp = (props) => {
    return(
        <Fragment>
            { props.isOpen &&
                <div className="alert-absolute">
                    <div className='alert-text'>
                        {props.text}                
                    </div>
                    <button type="button" className="close close-button" aria-label="Close" onClick={props.onClose}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            }
        </Fragment>
    )
}
export default AlertAbsoluteComp;
