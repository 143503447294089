import React from 'react';
import { Fragment } from 'react';
import { Table } from 'reactstrap';

const KasirListComp = (props) => {
    return(
        <Fragment>
            <Table bordered className={props.tableClassName ? `${props.tableClassName} table-center table-small` : 'table-border table-small table-center'}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Bill</th>                        
                        <th>Nama Tamu</th>                        
                        <th>Deposite</th>     
                        <th>Checkout</th>
                        <th>C/L</th>         
                        <th>Cash Sales</th>               
                        <th>Total</th>
                        <th>Kasir</th>
                    </tr>
                </thead>
                <tbody>
                    { props.data.map((post, index) => {
                        return(
                            <Fragment key={index}>
                                <tr key={index}>
                                    <td className='text-right'>{post.nomore}</td>
                                    <td>{post.no_trx}</td>
                                    <td className='text-center'>{post.kontak_name ? post.kontak_name : '' }</td>
                                    <td className="text-right">{post.tipe_transaksi === 4 ? post.ammdb.toLocaleString() : 0}</td>
                                    <td className="text-right">{post.tipe_transaksi === 6 && post.drtrx === 12 && post.lawan_tipe_transaksi === 1 ? post.ammdb.toLocaleString() : 0}</td>
                                    <td className="text-right">{post.tipe_transaksi === 5 ? post.ammdb.toLocaleString() : 0}</td>
                                    <td className="text-right">{post.tipe_transaksi === 6 && post.drtrx === 17 && post.lawan_tipe_transaksi === 1 ? post.ammdb.toLocaleString() : 0}</td>
                                    <td className="text-right">{post.lawan_tipe_transaksi === 1 ? post.ammdb.toLocaleString() : 0}</td>
                                    <td>{post.user}</td>
                                </tr>                                
                            </Fragment>
                        )
                    })}
                </tbody>
                { props.obj_total &&
                    <tfoot>
                        <tr>
                            <td className='text-right' colSpan={3}>Total</td>
                            <td className='text-right'>{props.obj_total && props.obj_total.total_deposite && props.obj_total.total_deposite.toLocaleString()}</td>
                            <td className='text-right'>{props.obj_total && props.obj_total.total_checkout && props.obj_total.total_checkout.toLocaleString()}</td>
                            <td className='text-right'>{props.obj_total && props.obj_total.total_cl && props.obj_total.total_cl.toLocaleString()}</td>
                            <td className='text-right'>{props.obj_total && props.obj_total.total_cash_sales && props.obj_total.total_cash_sales.toLocaleString()}</td>
                            <td className='text-right'>{props.obj_total && props.obj_total.grand_total && props.obj_total.grand_total.toLocaleString()}</td>
                        </tr>
                    </tfoot>
                }
            </Table>
        </Fragment>
    )
}
export default KasirListComp