import { createSlice } from '@reduxjs/toolkit';
import * as kontakService from '../services/kontakService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    kontaks : [],
    kontak : {},
    list_page : [],
    pesan : '',
    errorResponse : '',
}

const kontakStoreSlice = createSlice({
    name : 'kontakStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.kontaks = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.kontak = action.payload;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.kontaks = state.kontaks.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        },
        setErrorResponse : (state, action) => {
            state.errorResponse = action.payload;
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan, setErrorResponse } = kontakStoreSlice.actions;

export const kontakSelector = (state) => state.kontaks;

export default kontakStoreSlice.reducer;


export function fetchKontak(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await kontakService.getKontak(q, limit, page);
            const data = await response;
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            if (error.response){                
                setErrorResponse(error.response.status)
            }            
            dispatch(getDataFailure());
        }
    }
}


export function fetchDetail(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await kontakService.getKontak(`id=${id}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function deleteKontak(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await kontakService.deleteKontak(id);
            const data = await response;
            if (data.sukses === 'yes'){
                console.log('sukses')
                dispatch(deleteData(id));
                dispatch(setPesan(data.pesan))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}