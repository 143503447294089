import React, { Fragment } from 'react';
import { FormGroup, Label, Input, InputGroup, InputGroupAddon, Button, Col, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormGroupContent = (props) => {
    return(
        <Fragment>
            { props.tipe === 'date' &&
                <DatePicker
                    style={{display:'block'}}
                    name={props.name}
                    className="form-control"
                    selected={props.value}   
                    dateFormat="dd-MM-yyyy"
                    // onChange={(e) => props.onChangeDate(e, props.name)}
                    onChange={props.onChangeDate}
                />              
            }
            { props.tipe === 'password' &&
                <Input                         
                    id={props.name} 
                    placeholder={props.placeHolder}                         
                    value={props.value}
                    readOnly={props.readOnly} 
                    onChange={props.onChange}
                    name={props.name}                        
                    required={ props.required }
                    onBlur={props.onBlur}
                    type={props.show ? props.tipe : 'hidden'}
                />                  
            }
            { props.tipe === 'text' &&
                <Input                         
                    id={props.name} 
                    placeholder={props.placeHolder}                         
                    value={props.value}
                    readOnly={props.readOnly} 
                    onChange={props.onChange}
                    name={props.name}                        
                    required={ props.required }
                    onBlur={props.onBlur}
                    type={props.show ? props.tipe : 'hidden'}
                />                  
            }
            { props.tipe === 'checkbox' &&
                <Input                         
                    id={props.name} 
                    placeholder={props.placeHolder}                         
                    value={props.value}
                    readOnly={props.readOnly} 
                    onChange={props.onChange}
                    name={props.name}                        
                    required={ props.required }
                    onBlur={props.onBlur}
                    type={props.show ? props.tipe : 'hidden'}
                />                  
            }
            { props.tipe === 'text_area' &&
                <Input                         
                    id={props.name} 
                    placeholder={props.placeHolder}                         
                    value={props.value}
                    readOnly={props.readOnly} 
                    onChange={props.onChange}
                    name={props.name}                        
                    required={ props.required }
                    onBlur={props.onBlur}
                    type={props.show ? 'textarea' : 'hidden'}
                    rows={3}
                />                  
            }
            { (props.tipe === 'select' && props.show) &&
                <Input 
                    type={props.tipe} 
                    id={props.name} 
                    placeholder={props.placeHolder} 
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                    onBlur={props.onBlur}
                >                    
                    {props.obj.map((opt, mykey) => {
                        return(
                            <option value={opt.value} key={mykey}>{opt.label}</option>
                        )
                    })}
                </Input>                    
            }
            { props.tipe === 'input_button' &&
                <InputGroup>
                    <Input 
                        type="text" 
                        id={props.name}
                        name={props.name}
                        placeholder={props.placeHolder}
                        readOnly={props.readOnly}
                        value={props.value}
                        onChange={props.onChange}
                    />
                        <InputGroupAddon addonType="append">
                        <Button type="button" color="primary" onClick={props.onClick}><i className='fa fa-search'></i></Button>
                    </InputGroupAddon>
                </InputGroup>
            }
            { props.tipe === 'number' &&
                <NumberFormat 
                    thousandSeparator={true} 
                    // prefix={'Rp.'}                     
                    inputMode="numeric" 
                    className="form-control" 
                    name={props.name}
                    value={props.value} 
                    onChange={props.onChange}
                    onBlur={props.onBlur}                    
                    readOnly={props.readOnly}                    
                />
            
            }
        </Fragment>
    )
}

const FormTextArrayComp = (props) => {
    return(    
        <Fragment>
            { props.kolom ?
                <Row>
                    <Col lg={6}>
                        { Object.entries(props.data).slice(0,(Object.entries(props.data).length / props.kolom)+(props.plus || 0)).map( ([index, post]) => { 
                            return(
                                <FormGroup key={index}>
                                    { post.show &&
                                        <Label htmlFor="name">{post.label}</Label>
                                    }
                                    <FormGroupContent 
                                        tipe={post.tipe} 
                                        id={post.name} 
                                        placeHolder={post.placeHolder ? post.placeHolder : post.label} 
                                        value={post.value}
                                        onChange={props.onChange}
                                        name={post.name}
                                        onBlur={props.onBlur}
                                        obj={post.obj}
                                        readOnly={post.readOnly}
                                        onClick={post.onClick}
                                        show={post.show}
                                        onChangeDate={props.onChangeDate}
                                    />                           
                                    {post.showError && 
                                        (<div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>{post.errorMsg ? post.errorMsg : 'Wajib diisi'}</div>)                        
                                    }
                                </FormGroup>
                            )
                        })}
                    </Col>
                    <Col lg={6}>
                        { Object.entries(props.data).slice((Object.entries(props.data).length / props.kolom)+(props.plus || 0)).map( ([index, post]) => { 
                            return(
                                <FormGroup key={index}>
                                    { post.show &&
                                        <Label htmlFor="name">{post.label}</Label>
                                    }                                    
                                    <FormGroupContent 
                                        tipe={post.tipe} 
                                        id={post.name} 
                                        placeHolder={post.placeHolder ? post.placeHolder : post.label} 
                                        value={post.value}
                                        onChange={props.onChange}
                                        name={post.name}
                                        onBlur={props.onBlur}
                                        obj={post.obj}
                                        show={post.show}
                                        readOnly={post.readOnly}
                                        onChangeDate={props.onChangeDate}
                                        onClick={post.onClick}
                                    />                           
                                    {post.showError && 
                                        (<div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>{post.errorMsg ? post.errorMsg : 'Wajib diisi'}</div>)                        
                                    }
                                </FormGroup>
                            )
                        })}
                    </Col>
                </Row>
                :
                <Fragment>
                    { Object.entries(props.data).map( ([index, post]) => { 
                        return(
                            <FormGroup key={index}>                                
                                { post.show &&
                                    <Label htmlFor="name">{post.label}</Label>
                                }
                                <FormGroupContent 
                                    tipe={post.tipe} 
                                    id={post.name} 
                                    placeHolder={post.placeHolder ? post.placeHolder : post.label} 
                                    value={post.value}
                                    onChange={props.onChange}
                                    name={post.name}
                                    onBlur={props.onBlur}
                                    obj={post.obj}
                                    show={post.show}
                                    readOnly={post.readOnly}
                                    onChangeDate={props.onChangeDate}
                                    onClick={post.onClick}
                                />                           
                                {post.showError && 
                                    (<div className="error-message" style={{fontStyle:'italic', fontSize: 12, color: 'red'}}>{post.errorMsg ? post.errorMsg : 'Wajib diisi'}</div>)                        
                                }
                            </FormGroup>
                        )
                    })}
                </Fragment>
            }                
        </Fragment>
    )
}
export default FormTextArrayComp;