import { createSlice } from '@reduxjs/toolkit';
import * as kasbankService from '../services/kasbankService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    piutangs : [],
    piutang : {},
    obj_total : {},
    list_page : [],
    pesan : ''
}

const piutangStoreSlice = createSlice({
    name : 'piutangStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.piutangs = action.payload.data;
            state.list_page = action.payload.list_page;
            state.obj_total = action.payload.obj_total;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.piutang = action.payload;
        },
        deleteData : (state, action) => {            
            state.loading = false;
            state.hasErrors = false;
            state.piutangs = state.piutangs.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        },        
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan } = piutangStoreSlice.actions;

export const piutangSelector = (state) => state.piutangs;

export default piutangStoreSlice.reducer;


export function fetchPiutang(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await kasbankService.getHutangPiutang(q, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function fetchDetail(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await kasbankService.getHutangPiutang(`id=${id}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

// export function deleteKasBank(id){
//     return async(dispatch) => {
//         dispatch(getData());
//         try{
//             const response = await kasbankService.deleteKasBank(id);
//             const data = await response;
//             if (data.sukses === 'yes'){
//                 console.log('sukses')
//                 dispatch(deleteData(id));
//                 dispatch(setPesan(data.pesan))
//             }else{
//                 dispatch(getDataFailure());
//             }
//         }catch(error){
//             dispatch(getDataFailure());
//         }
//     }
// }