import React, { useCallback, useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Col, Table, Row } from 'reactstrap';
import PerusahaanComp from '../../../containers/Components/PerusahaanComp';
import * as checkinService from '../../../services/checkinService';
import HeaderTransaksiComp from '../../NonStay/Components/HeaderTransaksiComp';

const LaporanDrrCetak = (props) => {
    const filter = JSON.parse(localStorage.getItem('drrParams')) || {
        tgl : '',
        tanggal : '',        
    };
    const [listData, setListData] = useState([]);

    const fetchData = useCallback(async() => {
        try{
            const res = await checkinService.getDrReport(`tanggal=${filter.tgl}`);
            setListData(listData => ({...listData, 
                room_sales: res.room_sales,
                coffee_obj : res.coffee_obj,
                presentase : res.presentase,
                meeting : res.meeting
            }));            
        }catch(error){
            console.log('error', error);
        }
    },[filter.tgl])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    console.log('listData', listData);

    return(
        <Fragment>
            <div className='billprint-container'>
                <Row>
                    <Col xs={12}>

                        <PerusahaanComp className='margin-bottom-10'/>
                        <HeaderTransaksiComp
                            title={filter.title ? filter.title : `Daftar Transaksi Non Stay`}
                            desc={`Tanggal ${filter.tanggal}`}     
                            hideKontakName={true}               
                            className='margin-bottom-5'
                        />
                        <Table bordered className='tb-drr'>
                            <thead>
                                <tr>
                                    <th>DESCRIPTION</th>
                                    <th>TODAY</th>
                                    <th>ACTUAL MTD</th>
                                    <th>LAST YEAR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={4} style={{fontWeight: 'bold'}}>Room Sales</td>
                                </tr>
                                { listData.room_sales && listData.room_sales.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{post.name}</td>
                                            <td className='text-right'>{post.today.toLocaleString()}</td>
                                            <td className='text-right'>{post.mtd.toLocaleString()}</td>
                                            <td className='text-right'>{post.ytd.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan={4} className='text-center' style={{fontWeight: 'bold'}}></td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={{fontWeight: 'bold'}}>Meeting Room</td>
                                </tr>
                                { listData.meeting && listData.meeting.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{post.name}</td>
                                            <td className='text-right'>{post.today.toLocaleString()}</td>
                                            <td className='text-right'>{post.mtd.toLocaleString()}</td>
                                            <td className='text-right'>{post.ytd.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan={4} className='text-center' style={{fontWeight: 'bold'}}></td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={{fontWeight: 'bold'}}>Coffee Shop</td>
                                </tr>
                                { listData.coffee_obj && listData.coffee_obj.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className={post.bold ? 'strong' : ''}>{post.name}</td>
                                            <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.today.toLocaleString()}</td>
                                            <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.mtd.toLocaleString()}</td>
                                            <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.ytd.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td colSpan={4} className='text-center' style={{fontWeight: 'bold'}}></td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={{fontWeight: 'bold'}}>Room Sales</td>
                                </tr>
                                { listData.presentase && listData.presentase.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className={post.bold ? 'strong' : ''}>{post.name}</td>
                                            <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.today.toLocaleString()}</td>
                                            <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.mtd.toLocaleString()}</td>
                                            <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.ytd.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default LaporanDrrCetak;