import api from './api';

// tipe kamar
export function getPenjualanByRoomDetail(keyword, limit, page){
    return api.get(`/penjualan/api/penjualan-by-room-detail?${keyword}`, {params:{limit, page}}).then(res => res.data);
}

// penjualan resto
export function postPenjualanResto(form){
    return api.post(`/penjualan/api/penjualan-resto`, form).then(res => res.data);
}

// daftar penjualan resto
export function getPenjualanResto(keyword, limit, page){
    return api.get(`/penjualan/api?tipe=resto&${keyword}`, {params:{limit, page}}).then(res => res.data);
}

export function putPenjualanResto(id, form){
    return api.put(`/penjualan/api/penjualan-resto/${id}`, form).then(res => res.data);
}

// method pembayaran
export function getMethodPembayaran(){
    return api.get(`/penjualan/api/method-pembayaran`).then(res => res.data);
}

// deposite
export function postDeposite(form){
    return api.post(`/penjualan/api/deposite`, form).then(res => res.data);
}


// master tanggal
export function getMasterTanggal(){
    return api.get(`/penjualan/api/master-tanggal`).then(res => res.data);
}

export function postMasterTanggal(form){
    return api.post(`/penjualan/api/master-tanggal`, form).then(res => res.data);
}

// closing transaksi
export function closingTransaksi(){
    return api.post(`/penjualan/api/closing-transaksi`).then(res => res.data);
}

// change room
export function changeRoom(id, form){
    return api.post(`/penjualan/api/change-room/${id}`, form).then(res => res.data);
}


// ppn state
export function getPpnState(){
    return api.get(`/penjualan/api/get-ppn-state`).then(res => res.data)
}