import { useState, useEffect, useCallback } from "react"
import * as produkService from '../../../../services/produkService'
import * as penjualanService from '../../../../services/penjualanService'
import * as checkinService from '../../../../services/checkinService'
import config from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { fetchMeetingRoom } from "../../../../slices/meetingroom";
import { fetchRoom } from "../../../../slices/room";
import Moment from 'moment';
import { useHistory } from "react-router-dom";


export function useMeetingroom(){
    const initAlert = {show: false,text : '',color : 'danger'}
    const [alertValue, setAlertValue] = useState(initAlert);    
    const [isLoading, setIsLoading] = useState(false);
    const [totalValue, setTotalValue] = useState({        
        'total_fb' : 0,
        'total_room' : 0,
        'grand_total' : 0,
    });
    const [modalValue, setModalValue] = useState({
        show: false,
        is_list : true,
        title : '',
        alert : { show: false, text : ''},
        tipe_modal : 'kontak',
        tipe_kontak : 1
    });
    const billingId = sessionStorage.getItem('billingId') || 0
    const [isEdit, setIsEdit] = useState(false);
    const history = useHistory();

    // ============================ MEETING ROOM ==================================
    const arrayForm = [
        {
            name : 'jenis_konsumen',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : 1,
            label : 'Jenis Konsumen *',
            tipe : 'select',
            showError : false,            
            show : true,            
            obj : [{value : 1, label: 'Perorangan'},{value: 2, label : 'Perusahaan'}],
            sequence : 1,
        },
        {
            name : 'konsumen_name',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nama Tamu *',
            tipe : 'input_button',
            showError : false,            
            show : true,
            readOnly : true,
            onClick : function(){
                handleClickKonsumen();
            },
            sequence : 4,            
        },
        {
            name : 'meeting_room_id',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Meeting Room',
            tipe : 'select',
            showError : false,            
            show : true,
            obj : [],
            sequence : 5,
        },
        {
            name : 'harga_meeting',
            required : true,
            errorMsg : 'Harga',
            value : 0,
            label : 'Harga Meeting Room',
            tipe : 'number',
            showError : false,
            readOnly : false,            
            show : true,            
            sequence : 6,
        },
        {
            name : 'tgl_checkin',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Tanggal Checkin',
            tipe : 'date',
            showError : false,            
            show : false,
            sequence : 7,
        },
        {
            name : 'tgl_checkout',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Tanggal Checkout',
            tipe : 'date',
            showError : false,            
            show : true,
            sequence : 8,            
        },        
        {
            name : 'konsumen_id',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Konsumen_id',
            tipe : 'text',
            showError : false,            
            show : false,
            readOnly : true,            
            sequence : 12,         
        },
        {
            name : 'deposite',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Jumlah Deposite',
            tipe : 'number',
            showError : false,            
            show : true,
            sequence : 13,            
        },
        {
            name : 'method_pembayaran',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Method Pembayaran',
            tipe : 'select',
            showError : false,            
            show : true,
            sequence : 14,
            obj : []            
        },
    ]
    
    const initForm = arrayForm.reduce((obj, item) => Object.assign(obj, { [item.name] : item}), {});
    const [formData, setFormData] = useState(initForm);
    const dispatch = useDispatch();
    const { rooms } = useSelector(state => state);
    const { meetingrooms } = useSelector(state => state);    
    const [roomList, setRoomList] = useState([]);        
    const [fbIndex, setFbIndex] = useState(0);

    const fetchMethodPembayaran = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await penjualanService.getMethodPembayaran()
            let tmp = [{label : '-- Pilih Jenis Pembayaran --', value : ''}]
            if (res) {
                let tmpObj = res.data.filter(x => x.value !== 2 && x.value !== 5 && x.value !== 7)
                tmpObj.map(post => tmp.push({label : post.label, value: post.value}));
                setFormData(formData => ({...formData, method_pembayaran : {...formData.method_pembayaran, obj : tmp} }));        
            }            
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text :'Unable to fetch method pembayaran', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue,
                    show: false,
                    text : '',
                    color : 'danger'
                }));
            }, 5000)
        }
    },[])

    const fetchDataEdit = useCallback(async(billingId) => {
        setIsLoading(true);
        try{
            const res = await checkinService.editMeetingRoom(billingId);            
            if (res.sukses === 'yes'){
                if (Object.keys(res.data.meeting_room_obj).length === 0 && res.data.meeting_room_obj.constructor === Object){
                    return true;
                }else{                    
                    setFormData(formData => ({...formData,
                        meeting_room_id : {...formData.meeting_room_id, value: res.data.meeting_room_obj.kamar_id},
                        harga_meeting : {...formData.harga_meeting, value : res.data.meeting_room_obj.harga},
                        tgl_checkin : {...formData.tgl_checkin, value : new Date(res.data.meeting_room_obj.tgl_checkin)},
                        tgl_checkout : {...formData.tgl_checkout, value : new Date(res.data.meeting_room_obj.tgl_checkout)},
                    }))
                }
                if (res.data.is_close){
                    return history.push('/fo')
                }                
                setFormData(formData => ({...formData,
                    jenis_konsumen : {...formData.jenis_konsumen, value : res.data.is_rombongan ? 2 : 1},
                    konsumen_name : {...formData.konsumen_name, value : res.data.konsumen_name},
                    konsumen_id : {...formData.konsumen_id, value : res.data.konsumen_id},
                    deposite : {...formData.deposite, value : res.data.deposite},
                    method_pembayaran : {...formData.method_pembayaran, value : res.data.tipe_pembayaran_deposite},
                }));
                let tmp = []
                res.data.room_obj.map(post => tmp.push({
                    kamar_id : post.kamar_id,
                    harga : post.harga,
                    nama_tamu : post.nama_tamu,
                    jml_tamu : post.jml_tamu,
                    no_ktp : post.no_ktp,
                    id : post.id,
                }));
                setRoomList(tmp);
                hitungTotalRoom(tmp, null, 0)

                let tmpFb = []
                res.data.fb_obj.map(post => tmpFb.push({
                    produk_id : post.produk_id,
                    barcode : post.barcode,
                    produk_name : post.produk_name,
                    qty : post.qty,
                    harga_jual : post.harga_jual,
                    sub_total : post.sub_total,
                    id : post.id,
                }));
                setFbList(tmpFb);
                hitungTotalFb(tmpFb, null, '', 0)
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch Data Detail', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue,
                    show: false,
                    text : '',
                    color : 'danger'
                }));
            }, 5000)
        }
    },[history])

    useEffect(() => {
        if (billingId !== 0){
            setIsEdit(true);
            fetchDataEdit(billingId);
            setFormData(formData => ({...formData, deposite : {...formData.deposite, readOnly: true}}))
        }
    },[fetchDataEdit, billingId])

    useEffect(() => {
        fetchMethodPembayaran();
    },[fetchMethodPembayaran])

    const handleChange = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (name === 'deposite' || name === 'harga_meeting'){
            nilai = nilai.replace(/,/g, '');
        }
        setFormData(formData => ({...formData, [name] : {...formData[name], value : nilai}}));
        if (name === 'meeting_room_id'){
            let tmp = meetingrooms.meetingrooms.filter(x => x.id === parseInt(value));
            if (tmp.length > 0){                
                let obj = tmp[0];
                setFormData(formData => ({...formData, harga_meeting : {...formData.harga_meeting, value : obj.harga_jual}}))
            }else{
                setFormData(formData => ({...formData, harga_meeting : {...formData.harga_meeting, value : 0}}))
            }
        }

        if ( name === 'jenis_konsumen' ){                               
            let array = {...formData};
            let sortable = [];            
            let tmp = {}
            if (parseInt(value) === 2){
                array['tipe_rombongan'] = {
                    name : 'tipe_rombongan',
                    required : true,
                    errorMsg : 'Wajib Diisi',
                    value : 1,
                    label : 'Tipe Rombongan *',
                    tipe : 'select',
                    showError : false,            
                    show : true,
                    readOnly : false,                    
                    sequence : 2,
                    obj : [{value : 1, label : 'Pemerintahan'},{value: 2, label : 'Travel'}, {value: 3, label : 'Perusahaan'}]
                }
                array['perusahaan_name'] = {
                    name : 'perusahaan_name',
                    required : true,
                    errorMsg : 'Wajib Diisi',
                    value : '',
                    label : 'Nama Perusahaan *',
                    tipe : 'input_button',
                    showError : false,            
                    show : true,
                    readOnly : true,
                    onClick : () => handleClickPerusahaan(),
                    sequence : 3,
                }
                array['perusahaan_id'] = {
                    name : 'perusahaan_id',
                    required : false,
                    errorMsg : 'Wajib Diisi',
                    value : '',
                    label : 'Perusahaan Id',
                    tipe : 'text',
                    showError : false,
                    show : false,
                    readOnly : true,
                    sequence : 12,
                }
                array['konsumen_name']['label'] = 'Penanggung Jawab'

            }else{                
                delete array.perusahaan_name;
                delete array.tipe_rombongan;
                array['konsumen_name']['label'] = 'Nama Tamu'
            }
            array[name]['value'] = value;
            for (var vehicle in array){
                sortable.push([vehicle, array[vehicle]]);
            }

            sortable.sort(function(a, b) {
                return a[1].sequence - b[1].sequence;
            });                                
            sortable.map((post, key) => tmp[post[0]] = post[1]);
            setFormData(tmp);            
        }
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData, [name] : {...formData[name], showError : true}}))
        }else{
            setFormData(formData => ({...formData, [name] : {...formData[name], showError : false}}))
        }
    }

    const handleCloseAlert = () => {
        setAlertValue(initAlert)
    }

    const handleClickKonsumen = () => {
        setModalValue(modalValue => ({...modalValue, tipe_kontak : 1, show: true, 'title' : 'Pilih Nama', tipe_modal: 'kontak'}))
    }

    const handleClickPerusahaan = () => {
        setModalValue(modalValue => ({...modalValue, tipe_kontak : 2, show: true, 'title' : 'Pilih Nama', tipe_modal: 'kontak'}))
    }

    const handleSelectKontak = (post) => {        
        if(modalValue.tipe_kontak === 2){
            setFormData(formData => ({...formData, 
                perusahaan_name : {...formData.perusahaan_name, value : post.nama},
                perusahaan_id : {...formData.perusahaan_id, value : post.id},
            }));
        }else{
            setFormData(formData => ({...formData, 
                konsumen_name : {...formData.konsumen_name, value : post.nama},
                konsumen_id : {...formData.konsumen_id, value : post.id},
            }));
        }
        setModalValue(modalValue => ({...modalValue, show: false}))
    }

    const handleAddKontak = (post) => {
        setModalValue(modalValue => ({...modalValue, is_list : !modalValue.is_list}))
    }

    const handleChangeDate = (name, e) => {        
        setFormData(formData => ({...formData, [name] : {...formData[name], value : e}}))
    }    

    useEffect(() => {
        dispatch(fetchMeetingRoom())
    },[dispatch])
    
    useEffect(() => {
        let tmp = [{label : '-- Pilih Meeting Room --', value : ''}];
        meetingrooms.meetingrooms.map(post => tmp.push({label : post.nama, value : post.id}));
        setFormData(formData => ({...formData,
            meeting_room_id : {...formData.meeting_room_id, obj : tmp}
        }))
    },[meetingrooms.meetingrooms]); 

    const handleSubmit = async() => {
        setIsLoading(true);
        // formdata
        let array = {...formData};
        let countError = 0;
        Object.entries(array).map(([index, post]) => {
            post.showError = false
            if(post.required){
                if ((post.tipe === 'text' || post.tipe === 'select' || post.tipe === 'input_button') && post.value === ''){
                    countError++;
                    post.showError = true;
                    console.log('error >>>>', index, post);
                }
                if (post.tipe === 'number' && (parseInt(post.value) === 0 || post.value === '')){
                    countError++;
                    post.showError = true
                    console.log('error >>>>', index, post);
                }
            }
            return true;
        });

        if (countError > 0){            
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'lengkapi data', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000);
            return;
        }
        let tmpFormData = {}
        Object.entries(array).map(([index, post]) => tmpFormData[index] = post.value);        
        tmpFormData['tgl_checkout'] = Moment(tmpFormData.tgl_checkout).format('YYYY-MM-DD'); 


        // formRoom
        let arrayRoom = [...roomList];
        countError = 0;
        arrayRoom.map((room, index) => {
            Object.entries(room).map(([mykey, post]) => {
                if (mykey === 'kamar_id' && (post === '' || parseInt(post) === 0)){
                    console.log('error is >>>>', mykey, post)
                    setAlertValue(alertValue => ({...alertValue, show: true, text : `Silakan pilih kamar pada baris ke ${index+1}`}));
                    countError++;
                }
                // if (mykey === 'harga' && (post === '' || parseInt(post) === 0)){
                //     console.log('error is >>>>', mykey, post)
                //     setAlertValue(alertValue => ({...alertValue, show: true, text : `Harga kamar pada baris ke ${index+1} masih kosong`}));
                //     countError++;
                // }      
                return true;                   
            });
            return true;
        })

        if (countError > 0){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'lengkapi data', color : 'danger'}));
            console.log('ada disini >>>>>>>>>> bangsat')
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000);
            return;            
        }
        

        // formProduk
        // formRoom
        let arrayFb  = [...fbList];
        countError = 0;
        arrayFb.map((room, index) => {
            Object.entries(room).map(([mykey, post]) => {
                if (mykey === 'produk_id' && (post === '' || parseInt(post) === 0)){
                    setAlertValue(alertValue => ({...alertValue, show: true, text : `Pilih salah satu nama barang pada baris ke ${index+1} tekan enter pada kolom barcode`}));                    
                    countError++;
                }
                if (mykey === 'qty' && (post === '' || parseInt(post) === 0)){
                    setAlertValue(alertValue => ({...alertValue, show: true, text : `Silakan isi qty pada baris ke ${index}`}));
                    countError++;
                }
                if (mykey === 'harga_jual' && (post === '' || parseInt(post) === 0)){
                    setAlertValue(alertValue => ({...alertValue, show: true, text : `Silakan isi Harga pada baris ke ${index}`}));
                    countError++;
                }                
                return true;      
                  
            });
            return true;
        })

        if (countError > 0){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue,
                    show: false,
                    text : '',
                    color : 'danger'
                }));
            }, 5000)
            return;
        }

        let newForm = new FormData()
        newForm.append('formData', JSON.stringify(tmpFormData));
        newForm.append('fbForm', JSON.stringify(fbList));
        newForm.append('roomForm', JSON.stringify(roomList));
        let res;        
        try{
            if (isEdit) {                                
                res = await checkinService.putCheckinMeeting(billingId, newForm);
            }else{
                res = await checkinService.postCheckinMeeting(newForm);
            }
            setIsLoading(false);
            if (res.sukses === 'no'){
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan, color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(initAlert);
                }, 5000)
            }else{
                history.push('/fo/meeting-room');
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to submit checkin meeting room', color : 'danger'}));
            setTimeout(() => {
                setAlertValue(initAlert);
            }, 5000)
        }
    }

    // ============================ ROOM ==========================================
    const handleAddRoom = () => {
        let array = [...roomList];
        array.push({
            kamar_id : '',
            harga : 0,
            nama_tamu : '',
            jml_tamu : 1,
            no_ktp : '',
            id : 0,
            rb_state : 'ya'
        });
        setRoomList(array);
    }

    const handleChangeRoom = (e, index) => {
        const { name, value } = e.target;
        let nilai = value;
        let array = [...roomList];
        if (name === 'harga'){
            nilai = nilai.replace(/,/g, '');
            hitungTotalRoom(array, index, nilai);
        }
        if (name === 'jml_tamu'){
            nilai = nilai.replace(/,/g, '');
        }
        if (name === 'kamar_id'){
            let tmp = rooms.rooms.filter(x => x.id === parseInt(nilai));
            if (tmp.length > 0){
                let obj = tmp[0];
                array[index] = {...array[index], [name] : nilai, harga : obj.harga_jual};
                hitungTotalRoom(array, index, obj.harga_jual);
            }else{
                array[index] = {...array[index], [name] : nilai};
            }
        }else{
            array[index] = {...array[index], [name] : nilai};
        }        
        setRoomList(array);
    }

    const hitungTotalRoom = (array, index=null, value=0) => {
        let total = 0;
        array.map((post, key) => {
            if (index !== null){
                if (index === key){
                    total += parseInt(value);
                }else{
                    total += parseInt(post.harga);
                }
            }else{
                total += parseInt(post.harga);
            }
            return true;
        });
        setTotalValue(totalValue => ({...totalValue, total_room : total}))
    }

    const handleDeleteRoom = (index) => {
        let array = [...roomList];
        array.splice(index, 1);
        setRoomList(array);
        hitungTotalRoom(array, null, 0);
    }

    useEffect(() => {
        if (isEdit){
            dispatch(fetchRoom(``, 100, 1));
        }else{
            dispatch(fetchRoom(`room_state=VC`, 100, 1));
        }
    },[dispatch, isEdit]);



    // ========================= FOOD AND BEVERAGE =================================
    const [ fbList, setFbList ] = useState([]);
    const handleAddFb = () => {        
        const array = [...fbList];
        array.push({
            produk_id : 0,
            barcode : '',
            produk_name : '',
            qty : 1,
            harga_jual : 0,
            sub_total : 0,
            id : 0,
        });
        setFbList(array);
    }

    const handleDeleteFb = (index) => {
        let array = [...fbList];
        array.splice(index, 1);
        setFbList(array);
        hitungTotalFb(array, null, '', 0);
    }
    
    const handleChangeProduk = (e, index) => {
        const {name, value} = e.target;
        let nilai = value;
        let array = [...fbList];
        if (name === 'qty' || name === 'harga_jual'){
            nilai = nilai.replace(/,/g, '');
            let total = hitungIndex(index, name, nilai);            
            array[index]['sub_total'] = total;
            hitungTotalFb(array, index, name, nilai);
        }        
        array[index][name] = nilai;     
        setFbList(array);
    }

    const hitungIndex = (index, name, value) => {
        let array = [...fbList];
        let harga ;
        let qty;
        
        if (name === 'harga_jual'){
            harga = value !== '' ? value : 0;
        }else{
            harga = array[index].harga_jual !== '' ? array[index].harga_jual : 0;
        }
        
        if (name === 'qty'){
            qty = value !== '' ? value : 0;            
        }else{
            qty = array[index].qty !== '' ? array[index].qty : 0;
        }
        let total = parseInt(qty) * parseInt(harga);
        return total;
    }
    
    const handleBlurProduk = async(e, index) => {
        const { value } = e.target;
        setIsLoading(true);
        let array = [...fbList];
        const barcode = value;
        const res = await produkService.getProduk(`q=${barcode}`, config.itemPerPage, 1);
        setIsLoading(false);
        if (res.data.length === 1){
            let data = res.data[0];                    
            array[index] = {...array[index],
                barcode : data.kode,
                produk_name : data.nama,                   
                produk_id : data.id,
                harga_jual : data.harga_jual,
                sub_total : data.harga_jual
            }
            setFbList(array);
            hitungTotalFb(array, null, '', 0);
        }else {
            setFbIndex(index);
            setModalValue(modalValue => ({...modalValue, tipe_modal : 'fb', show : true, is_list : true}))
        }
    }

    const hitungTotalFb = (array, index=null, name='', value=0) => {               
        let total = 0
        array.map((post, key) => {
            let tmp = 0
            if(index !== null){                
                if (key === index){
                    tmp =  name === 'harga_jual' ? parseInt(value) * parseInt(post.qty) : parseInt(post.harga_jual) * parseInt(value);
                }else{
                    tmp = parseInt(post.harga_jual) * parseInt(post.qty);
                }
            }else{
                tmp = parseInt(post.harga_jual) * parseInt(post.qty);
            }
            total += tmp;
            return true;
        });
        setTotalValue(totalValue => ({...totalValue, total_fb : total}))
    }

    const handleKeyDownProduk = async(e, index) => {
        if (e.key === 'Enter'){
            setIsLoading(true);
            try{
                let array = [...fbList];
                const barcode = array[index].barcode;
                const res = await produkService.getProduk(`q=${barcode}`, config.itemPerPage, 1);
                setIsLoading(false);
                if (res.data.length === 1){
                    let data = res.data[0];                    
                    array[index] = {...array[index],
                        barcode : data.kode,
                        produk_name : data.nama,                   
                        produk_id : data.id,
                        harga_jual : data.harga_jual,
                        sub_total : data.harga_jual
                    }
                    setFbList(array);
                    hitungTotalFb(array, null, '', 0);
                }else {
                    setFbIndex(index);
                    setModalValue(modalValue => ({...modalValue, tipe_modal : 'fb', show : true, is_list : true}))
                }
            }catch(error){
                setIsLoading(false);
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch produk', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(initAlert)
                }, 5000)
            }
            
        }
    }

    const handleClickTdFb = (post) => {
        let array = [...fbList];
        array[fbIndex] = {...array[fbIndex],
            produk_id : post.id,
            produk_name : post.nama,
            harga_jual : post.harga_jual,
            barcode : post.kode,
            qty : 1,
            sub_total : post.harga_jual
        }
        setFbList(array);
        hitungTotalFb(array, null, '', 0);
        setModalValue(modalValue => ({...modalValue, show: false}))
    }

    return {
        alertValue, setAlertValue, fbList, setFbList, isLoading, setIsLoading, modalValue, setModalValue,
        handleAddFb, handleDeleteFb, handleChangeProduk, handleBlurProduk, handleClickTdFb, handleKeyDownProduk, handleClickKonsumen, setFormData, meetingrooms, formData, handleChange, handleBlur, handleSelectKontak, handleAddKontak, handleChangeDate, totalValue, handleAddRoom, roomList, rooms, handleChangeRoom, handleDeleteRoom, handleSubmit, handleCloseAlert
    }
}