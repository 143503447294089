import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import ActiveBillTable from './ActiveBillTable';
import { useGuestHouse } from './guestHouseFunction';
import * as masterService from '../../../services/masterService'

const ActiveBillPrint = () => {
    const { listData, total } = useGuestHouse();
    const [compInfo, setCompInfo] = useState({
        nama : '',
        alamat : '',
        kota : '',
        telp : ''
    })
    const fetchCompany = useCallback(async() => {
        const res = await masterService.getPerusahaan();
        setCompInfo(compInfo => ({...compInfo,
            nama : res.data.nama,
            alamat : res.data.alamat,
            kota : res.data.kabupaten_name,
            telp : res.data.telp
        }));        
    },[])

    useEffect(() => {
        fetchCompany();
    },[fetchCompany])

    return(
        <Fragment>
            <div className='billprint-container landscape'>
                <Row>
                    <Col xs={12}>
                        <div className="company-name">
                            {compInfo.nama}
                        </div>
                        <div className="company-address">                            
                            { compInfo.alamat }
                        </div>
                        <div className="company-city">
                            { compInfo.kota }
                        </div>
                        <div className="company-phone">
                            { compInfo.telp }
                        </div>
                    </Col>                    
                </Row>    
                <Row>
                    <Col xs={12}>
                        <div className="text-center bold" style={{fontWeight: 'bold'}}>Laporan Active Bill</div>
                        <div className="text-center bold">Tanggal Cetak : {new Date().toLocaleString()}</div>
                    </Col>            
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={12}>
                        <ActiveBillTable listData={listData} total={total}/>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default ActiveBillPrint;