import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ModalComp = (props) => {
    return(
        <Modal isOpen={props.isOpen} toggle={props.onClose} className={props.modalClassName ? props.modalClassName : 'modal-danger'} size={props.size ? props.size : "md"}>
            <ModalHeader toggle={props.onClose}>
                {props.title}
            </ModalHeader>
            <ModalBody>
                { props.body }
                { props.children }
            </ModalBody>
            <ModalFooter>
                { props.submitShow &&
                    <Button color={props.submitVariant ? props.submitVariant : "danger"} onClick={props.onSubmit}>{props.submitText ? props.submitText : 'Hapus'}</Button>
                }
                <Button color="secondary" onClick={props.btnCancel ? props.btnCancel : props.onClose}>{props.cancelText ? props.cancelText : 'Cancel'}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalComp;
