import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchKasBank } from '../../slices/kasbank';
import * as masterService from '../../services/masterService'
import { Col, Row } from 'reactstrap';
import '../../style/masterBillPrint.css';
import KasirListComp from './KasirListComp';

const KasirCetak = (props) => {
    const dispatch = useDispatch();
    const { kasbanks } = useSelector(state => state);
    const [kasirName, setKasirName] = useState('')
    const [shiftObj, setShiftObj] = useState([]);    
    const [compInfo, setCompInfo] = useState({
        nama : '',
        alamat : '',
        kota : '',
        telp : ''
    })
    const filterSubmit = JSON.parse(localStorage.getItem('kasirParams')) || {};
    
    useEffect(() => {
        // dispatch(fetchKasBank(`kasir_id=${filterSubmit.kasir_id}&kasir=${filterSubmit.tipe_kasir}&shift=${filterSubmit.shift}&start_date=${filterSubmit.start_date}&end_date=${filterSubmit.end_date}`, 0, 1));
        dispatch(fetchKasBank(`kasir_id=${filterSubmit.kasir_id}&kasir=${filterSubmit.tipe_kasir}&shift=${filterSubmit.shift}&start_date=${filterSubmit.start_date}&end_date=${filterSubmit.end_date}&exclude_tipe_penjualan=10`, 0, 1));
    }, [dispatch, filterSubmit.shift, filterSubmit.start_date, filterSubmit.end_date, filterSubmit.kasir_id, filterSubmit.tipe_kasir])

    const fetchKasir = useCallback(async() => {
        if (filterSubmit.tipe_kasir==='none'){
            setKasirName('FO')
        }else{
            const res = await masterService.getCoffee(`id=${filterSubmit.kasir_id}`, 1, 1);
            if (res.sukses === 'yes'){
                setKasirName(res.data.nama)
            }else{
                setKasirName('Global')            
            }
        }        
    },[filterSubmit.kasir_id, filterSubmit.tipe_kasir])

    useEffect(() => {
        fetchKasir();
    },[fetchKasir])

    const fetchCompany = useCallback(async() => {
        const res = await masterService.getPerusahaan();
        setCompInfo(compInfo => ({...compInfo,
            nama : res.data.nama,
            alamat : res.data.alamat,
            kota : res.data.kabupaten_name,
            telp : res.data.telp
        }));        
    },[])

    useEffect(() => {
        fetchCompany();
    },[fetchCompany])

    const fetchShift = useCallback(async(q='', page=1) => {        
        try{
            const res = await masterService.getShiftPegawai(`${q}`, 10,  page);
            if (res.sukses === 'yes'){                
                setShiftObj(res.data);
            }else{
                return
            }
        }catch(error){
            return            
        }
    },[])

    useEffect(() => {
        fetchShift();
    },[fetchShift])

    console.log('shift is >>>>>', shiftObj.filter(x => x.id === parseInt(filterSubmit.shift))[0] )
    
    return(
        <Fragment>
            <div className='billprint-container landscape'>
                <Row>
                    <Col xs={12}>
                        <div className="company-name">
                            {compInfo.nama}
                        </div>
                        <div className="company-address">                            
                            { compInfo.alamat }
                        </div>
                        <div className="company-city">
                            { compInfo.kota }
                        </div>
                        <div className="company-phone">
                            { compInfo.telp }
                        </div>
                    </Col>                    
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="title">
                            LAPORAN KASIR
                        </div>
                        <div className="desc-title">
                            Tanggal { filterSubmit.start_date }
                        </div>
                        { filterSubmit?.shift && shiftObj.length > 0 && shiftObj.filter(x => x.id === parseInt(filterSubmit.shift)) ?
                            <div className='desc-title'>
                                Shift : { shiftObj.filter(x => x.id === parseInt(filterSubmit.shift))[0].nama.toUpperCase() }
                            </div>
                            :
                            <div>
                                Shift : All
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        Kasir : {kasirName}
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col xs={12}>
                        <KasirListComp
                            data={kasbanks.kasbanks}
                            tableClassName='table-kasir table-bordered'
                            hideKasirName={true}
                            obj_total={kasbanks.obj_total}
                        />
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default KasirCetak;
