import React from 'react';
import { Fragment } from 'react';
import { Button, CardBody, CardFooter, Col, FormGroup, Input, Modal, Row, Table } from 'reactstrap';
import CardTemplate from '../../../containers/Components/CardTemplate';
import FormArrayDoubleDate from '../../../containers/Components/FormArrayDoubleDateComp';
import KontakPage from '../../Kontak/Kontak/KontakPage';
import KontakAddPage from '../../Kontak/Kontak/KontakAddPage';
import '../../../style/checkinMeetingroom.css';
import ProdukPage from '../../Produk/Produk/ProdukPage';
import NumberFormat from 'react-number-format';
import { useMeetingroom } from './function.js/meetingroom';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import AlertAbsoluteComp from '../../../containers/Components/AlertAbsoluteComp';

const MeetingRoomCheckin = (props) => {
    const { alertValue,  fbList, isLoading,  modalValue, setModalValue, handleAddFb, handleDeleteFb, handleChangeProduk, handleBlurProduk, handleClickTdFb, handleKeyDownProduk,  formData, handleChange, handleBlur, handleSelectKontak, handleAddKontak, handleChangeDate, totalValue, handleAddRoom, roomList, rooms, handleChangeRoom, handleDeleteRoom, handleSubmit, handleCloseAlert  } = useMeetingroom();
    
    return(
        <Fragment>
            { isLoading &&
                <LoaderComponent/>
            }
            <AlertAbsoluteComp
                isOpen={alertValue.show}
                text={alertValue.text}
                onClose={handleCloseAlert}
            />
            <Modal isOpen={modalValue.show} toggle={() => setModalValue(modalValue => ({...modalValue, show: false}))} className='modal-default' size="lg">
                { modalValue.is_list &&
                    <Fragment>
                        { modalValue.tipe_modal === 'kontak' &&   
                            <KontakPage
                                modal={true}
                                query={`tipe_kontak=${modalValue.tipe_kontak}`}
                                tipe='is_guest'                                
                                selectKontak={handleSelectKontak}
                                handleAdd={handleAddKontak}
                                hideJenisKontak={true}
                                tipe_kontak={modalValue.tipe_kontak}
                            />
                        }
                        { modalValue.tipe_modal === 'fb' &&
                            <ProdukPage 
                                modal={true}
                                handleClick={handleClickTdFb}
                            />                            
                        }
                    </Fragment>
                }
                { !modalValue.is_list &&
                    <Fragment>
                        { modalValue.tipe_modal === 'kontak' &&
                            <KontakAddPage
                                modal={true}
                                tipe={modalValue.tipe_kontak === 1 ? 'is_guest' : 'is_supplier'}
                                handleAdd={handleAddKontak}
                                hideJenisKontak={true}
                                tipe_kontak={modalValue.tipe_kontak}                                
                            />
                        }
                    </Fragment>
                }
            </Modal>
            <CardTemplate
                headerTitle="Checkin Meeting Room"
            >
                <CardBody className='checkin-meetingroom'>
                    <Row>
                        <Col lg={8}>
                            <FormArrayDoubleDate
                                data={formData}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onChangeDate={handleChangeDate}
                                kolom={2}
                            />
                        </Col>
                        <Col lg={4}>
                            <div className="box-total">
                                <FormGroup row>
                                    <Col lg={6}>Meeting Room</Col>
                                    <Col lg={4} style={{textAlign: 'right'}}>{formData.harga_meeting.value.toLocaleString()}</Col>
                                </FormGroup>                                
                                <FormGroup row>
                                    <Col lg={6}>Total Room </Col>
                                    <Col lg={4} style={{textAlign: 'right'}}>{totalValue.total_room.toLocaleString()}</Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col lg={6}>Total FB </Col>
                                    <Col lg={4} style={{textAlign: 'right'}}>{totalValue.total_fb.toLocaleString()}</Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col lg={6}>Grand Total </Col>
                                    <Col lg={4} style={{textAlign: 'right'}}>
                                        {(parseInt(totalValue.total_room) + parseInt(totalValue.total_fb) + parseInt(formData.harga_meeting.value)).toLocaleString()}
                                    </Col>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    <div className="garis"></div>
                    <Row className='box-title'>
                        <Col lg={6}>
                            <div className="title">Room</div>
                        </Col>
                        <Col lg={6}>
                            <Button color='success' className='float-right' size='sm' onClick={handleAddRoom}>Tambah Room</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nomor Kamar</th>                                   
                                        <th>Include Breakfast</th>                                   
                                        <th>Harga</th>
                                        <th>Jumlah Tamu</th>
                                        <th>Nama Tamu</th>
                                        <th>No KTP</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { roomList.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>
                                                    <Input
                                                        type='select'
                                                        value={post.kamar_id}
                                                        onChange={(e) => handleChangeRoom(e, index)}
                                                        name="kamar_id"
                                                    >
                                                        <option value=''>-- Pilih Kamar --</option>
                                                        { rooms.rooms.map((value, mykey) =>{
                                                            return(
                                                                <option key={mykey} value={value.id}>{value.nama}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                </td>
                                                <td>
                                                    <Input
                                                        type='select'
                                                        value={post.rb_state}
                                                        onChange={(e) => handleChangeRoom(e, index)}
                                                        name="rb_state"
                                                    >
                                                        <option value='ya'>Ya</option>
                                                        <option value='tidak'>Tidak</option>
                                                    </Input>
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        thousandSeparator={true}          
                                                        inputMode="numeric"     
                                                        className="form-control text-right"
                                                        name='harga'
                                                        value={post.harga} 
                                                        onChange={(e) => handleChangeRoom(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <NumberFormat
                                                        thousandSeparator={true}          
                                                        inputMode="numeric"     
                                                        className="form-control text-right"
                                                        name='jml_tamu'
                                                        value={post.jml_tamu} 
                                                        onChange={(e) => handleChangeRoom(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        value={post.nama_tamu}
                                                        name='nama_tamu'
                                                        onChange={(e) => handleChangeRoom(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="text"
                                                        value={post.no_ktp}
                                                        name='no_ktp'
                                                        onChange={(e) => handleChangeRoom(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <i className='fa fa-times' style={{color : 'red'}} onClick={() => handleDeleteRoom(index)}></i>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <div className="garis"></div>
                    <Row className='box-title'>
                        <Col lg={6}>
                            <div className="title">Food And Beverage</div>
                        </Col>
                        <Col lg={6}>
                            <Button color='warning' className='float-right' size='sm' onClick={handleAddFb}>Tambah FB</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Kode</th>
                                        <th>Nama Barang</th>
                                        <th>QTY</th>
                                        <th>Harga Jual</th>
                                        <th>Sub Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fbList.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>
                                                    <Input
                                                        type='text'
                                                        name='barcode'
                                                        onChange={(e) => handleChangeProduk(e, index)}
                                                        value={post.barcode}
                                                        onBlur={(e) => handleBlurProduk(e, index)}
                                                        onKeyDown={(e) => handleKeyDownProduk(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    {post.produk_name}
                                                </td>
                                                <td className='text-right'>
                                                    <NumberFormat
                                                        thousandSeparator={true}          
                                                        inputMode="numeric"     
                                                        className="form-control text-right"
                                                        name='qty'
                                                        value={post.qty} 
                                                        onChange={(e) => handleChangeProduk(e, index)}
                                                    />
                                                </td>
                                                <td className='text-right'>
                                                    <NumberFormat
                                                        thousandSeparator={true}          
                                                        inputMode="numeric"     
                                                        className="form-control text-right"
                                                        name='harga_jual'
                                                        value={post.harga_jual} 
                                                        onChange={(e) => handleChangeProduk(e, index)}
                                                    />
                                                </td>
                                                <td className="text-right">
                                                    {post.sub_total.toLocaleString()}
                                                </td>
                                                <td>
                                                    <i className='fa fa-times' style={{color : 'red'}} onClick={() => handleDeleteFb(index)}></i>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <Button color="primary" size="sm" onClick={handleSubmit}>Simpan</Button>
                    &nbsp;<Button color="secondary" size="sm" onClick={() => props.history.goBack()}>Kembali</Button>
                </CardFooter>
            </CardTemplate>
        </Fragment>
    )
}
export default MeetingRoomCheckin;
