import React, { Fragment } from 'react';
import { Table } from "reactstrap";


const ActiveBillTable = (props) => {
    const { listData, total } = props;
    return(
        <Table style={{width:"100%", borderCollapse:"collapse"}} bordered className={`table-center table-small`}>
            <thead style={{backgroundColor:"#c0c0c0", zIndex: 9999}}>
                <tr>
                    <th>#</th>                                
                    <th>Nama</th>                               
                    <th>Nomor Kamar</th>
                    <th>C/I</th>                    
                    <th>Active Bill</th>                                
                </tr>
            </thead>
            <tbody>
                { listData?.map((post, index) => {
                    return(                                        
                        <Fragment key={index}>
                            { post.detail.map((detail, indexDetail) => {
                                return(
                                    <tr key={index+indexDetail}>
                                        <td className='text-right'>{indexDetail+index+1}</td>
                                        <td>{post.konsumen_name}</td>                                        
                                        <td className='text-center'>{detail.nama_produk}</td>
                                        {/* <td className='text-center'>{post.tgl_checkin_actual}</td> */}
                                        <td className='text-center'>{detail.tgl_checkin}</td>
                                        {/* <td className='text-right'>{post.total_invoice?.toLocaleString()}</td> */}
                                        <td className='text-right'>{detail.harga_jual?.toLocaleString()}</td>
                                    </tr>
                                )
                            })} 
                        </Fragment>
                    )
                })}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={4} className='text-right'>
                        Total
                    </td>
                    <td className='text-right'>{total?.toLocaleString()}</td>
                </tr>
            </tfoot>
        </Table>
    )
}
export default ActiveBillTable;