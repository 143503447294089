import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Alert, CardBody, Col, FormGroup, Row, Table, Label, Button } from 'reactstrap';
import CardTemplate from '../../../containers/Components/CardTemplate';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import * as checkinService from '../../../services/checkinService';
import '../../../style/drReportStyle.css';
import DatePicker from "react-datepicker";
import Moment from 'moment';

const LaporanDrr = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [alertValue, setAlertValue] = useState({
        show: false, text : '', color : 'danger'
    })    
    const [listData, setListData] = useState({
        room_sales : [],
        coffee_obj : [],
        presentase : [],
        meeting : []
    });
    const [filterSubmit, setFilterSubmit] = useState({
        tanggal_submit : Moment(new Date()).format('YYYY-MM-DD'),
        tgl : new Date()
    });

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    const onChangeDate = (e) => {
        let nilai = e;
        if (isValidDate(e)){
            nilai = Moment(nilai).format('YYYY-MM-DD');
        }else{
            nilai = ''
        }
        setFilterSubmit(filter => ({...filter, tanggal_submit : nilai, tgl : e}))
    }

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await checkinService.getDrReport(`tanggal=${filterSubmit.tanggal_submit}`);
            setIsLoading(false);
            setListData(listData => ({...listData, 
                room_sales: res.room_sales,
                coffee_obj : res.coffee_obj,
                presentase : res.presentase,
                meeting : res.meeting
            }));
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show : true, text : 'Unable to fetch Data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show : false, text : '', color : 'danger'}))
            }, 5000)
        }
    },[filterSubmit.tanggal_submit])

    useEffect(() => {
        fetchData();
    },[fetchData])
    

    const handleCetak = () => {
        let tgl = ''
        let tanggal = ''
        if (isValidDate(filterSubmit.tgl)){
            tgl = Moment(filterSubmit.tgl).format('YYYY-MM-DD');
            tanggal = Moment(filterSubmit.tgl).format('DD-MM-YYYY');
        }    
        let tmpObj = {}
        tmpObj['tgl'] = tgl;
        tmpObj['tanggal'] = tanggal;
        localStorage.setItem('drrParams', JSON.stringify(tmpObj))
        window.open(`#/fo/laporan-drr/cetak`, "_blank")
    }

    return(
        <Fragment>
            { isLoading &&
                <LoaderComponent/>
            }
            <Alert isOpen={alertValue.show} color={alertValue.color} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))}>
                {alertValue.text}
            </Alert>
            <CardTemplate
                headerTitle="Daily Revenue Report"
            >
                <CardBody>
                    <Row>
                        <Col lg={6}>
                            <FormGroup row>
                                <Col lg={4}>
                                    <Label>Dari Tanggal</Label>
                                </Col>
                                <Col lg={8}>
                                    <DatePicker
                                        style={{display:'block'}}
                                        name='start_date'
                                        className="form-control"
                                        selected={filterSubmit.tgl}   
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(e) => onChangeDate(e)}
                                    />    
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <Button color="primary" onClick={handleCetak}>Cetak</Button>
                        </Col>
                        <Col lg={12}>
                            <Table bordered className='tb-drr'>
                                <thead>
                                    <tr>
                                        <th>DESCRIPTION</th>
                                        <th>TODAY</th>
                                        <th>ACTUAL MTD</th>
                                        <th>LAST YEAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={4} style={{fontWeight: 'bold'}}>Room Sales</td>
                                    </tr>
                                    { listData.room_sales.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{post.name}</td>
                                                <td className='text-right'>{post.today.toLocaleString()}</td>
                                                <td className='text-right'>{post.mtd.toLocaleString()}</td>
                                                <td className='text-right'>{post.ytd.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={4} className='text-center' style={{fontWeight: 'bold'}}></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{fontWeight: 'bold'}}>Meeting Room</td>
                                    </tr>
                                    { listData.meeting.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{post.name}</td>
                                                <td className='text-right'>{post.today.toLocaleString()}</td>
                                                <td className='text-right'>{post.mtd.toLocaleString()}</td>
                                                <td className='text-right'>{post.ytd.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={4} className='text-center' style={{fontWeight: 'bold'}}></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{fontWeight: 'bold'}}>Coffee Shop</td>
                                    </tr>
                                    { listData.coffee_obj.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className={post.bold ? 'strong' : ''}>{post.name}</td>
                                                <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.today.toLocaleString()}</td>
                                                <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.mtd.toLocaleString()}</td>
                                                <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.ytd.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={4} className='text-center' style={{fontWeight: 'bold'}}></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{fontWeight: 'bold'}}>Room Sales</td>
                                    </tr>
                                    { listData.presentase.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className={post.bold ? 'strong' : ''}>{post.name}</td>
                                                <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.today.toLocaleString()}</td>
                                                <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.mtd.toLocaleString()}</td>
                                                <td className={post.bold ? 'text-right strong' : 'text-right'}>{post.ytd.toLocaleString()}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>                        
                    </Row>
                </CardBody>
            </CardTemplate>
        </Fragment>
    )
}
export default LaporanDrr;