import React, { useEffect, useState, useCallback, Fragment } from 'react';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { CardBody, Table, Button, Pagination, PaginationItem, PaginationLink, Row, Col, ButtonGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { fetchTipeKamar, deleteTipeKamar } from '../../../slices/tipekamar';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import SearchComp from '../../../containers/Components/SearchComp';
import config from '../../../config';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import ModalComp from '../../../containers/Components/ModalComp';

const TipeKamarPage = (props) => {
    const dispatch = useDispatch();
    const { tipekamars } = useSelector(state => state);
    const [tipeKamarId, setTipeKamarId] = useState(0);
    // const { tipekamars } = useSelector(tipeKamarSelector);
    const [pageValue, setPageValue] = useState({
        page : 1,
        obj : [],
        search : ''
    })
    const history = useHistory();    
    const [modalValue, setModalValue] = useState({
        show: false,
        text : ''
    });

    const fetchData = useCallback(async(q='') => {
        dispatch(fetchTipeKamar(`q=${q}`, config.itemPerPage, pageValue.page));
    },[pageValue.page, dispatch])

    useEffect(() => {
        fetchData();
    },[fetchData]);

    const handlePage = (page) => {
        setPageValue(pageValue => ({...pageValue, page: page}))
    }

    useEffect(() => {
        setPageValue(pageValue => ({...pageValue, obj: tipekamars.list_page}))
    },[tipekamars.list_page]);

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setPageValue(pageValue => ({...pageValue, search: value}))
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, page: 1}))        
        fetchData(pageValue.search)
    }

    const handleEdit = (id) => {        
        history.push({ pathname : '/room/master/tipe-kamar/edit', state: {tipeKamarId: id}});
    }

    const handleAdd = () => {
        sessionStorage.removeItem('tipeKamarId');
        history.push({ pathname : '/room/master/tipe-kamar/add'});
    }

    const handleDelete = async(post) => {
        setModalValue(modalValue => ({...modalValue, 
            show: true,
            text : `Apakah anda yakin akan menghapus tipe kamar ${post.nama} ?`
        }));
        setTipeKamarId(post.id);
    }

    const handleCloseModal = () => {
        setModalValue(modalValue => ({...modalValue, show: false, text : ''}));
    }

    const handleSubmitDelete = () => {        
        dispatch(deleteTipeKamar(tipeKamarId));     
        handleCloseModal();
    }

    return(
        <Fragment>
            { tipekamars.loading &&
                <LoaderComponent/>
            }
            <ModalComp
                isOpen={modalValue.show}
                onClose={handleCloseModal}
                title="Konfirmasi"
                body={modalValue.text}
                onSubmit={handleSubmitDelete}
                submitShow={true}
            />
            <CardTemplate
                colClassName='offset-lg-3'
                xs={12}
                lg={6}
                headerTitle='Tipe Kamar'
            >
                <CardBody>
                    <Row>
                        <Col lg={12}>
                            <Button color="primary" className='float-right' size="sm" onClick={handleAdd}>
                                <i className='fa fa-plus'></i> Tambah Kamar
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Col lg={12}>
                            <SearchComp
                                placeHolder="Cari Tipe Kamar"
                                value={pageValue.search}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDown}
                                onClick={handleSearch}
                            />
                        </Col>
                    </Row>
                    
                    <Table responsive style={{marginTop: 10}}>
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Harga</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { tipekamars.tipekamars.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{post.nama}</td>
                                        <td>
                                            <NumberFormat value={post.harga} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} />
                                            
                                        </td>
                                        <td>
                                            <ButtonGroup>
                                                <Button color="primary" className="btn-edit" onClick={() => handleEdit(post.id)}>
                                                    <i className="fa fa-edit fa-xs" id={"editTipeKamar-" + post.id}></i>&nbsp; Edit
                                                </Button>
                                                <Button color="danger" className="btn-edit" onClick={() => handleDelete(post)}>
                                                    <i className="fa fa-edit fa-xs" id={"hapusTipeKamar-" + post.id}></i>&nbsp; Hapus
                                                </Button>

                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    { pageValue.obj && pageValue.obj.length > 0 &&
                        <Pagination>
                            { pageValue.obj.map((post, index) => {
                                return(
                                    <PaginationItem key={index} className={pageValue.page === post ? 'active' : ''}>
                                        <PaginationLink tag="button" onClick={() => handlePage(post)}>
                                            {post}
                                        </PaginationLink>
                                    </PaginationItem>
                                )
                            })}
                        </Pagination>
                    }
                </CardBody>
            </CardTemplate>
        </Fragment>
    )
}
export default TipeKamarPage