import * as React from "react"

function DoubleBed(props) {
  return (
    <svg width={1306.667} height={1168} viewBox="0 0 980 876" {...props}> color="#fff"
      <path d="M124.3 2.9c-5.6 2.6-13 9.5-15.5 14.6-2 3.7-2.2 6.4-2.9 35-.4 17-.8 74.8-.8 128.2L105 278H135.7l6.8-34.8c3.8-19.1 7.1-35 7.4-35.4.7-.8 36.5-8.1 53.1-10.7 36.9-6 59.5-7.6 106.5-7.6 62.5 0 91.6 3.2 158 17.4l3 .7.3 35.2.2 35.2h40l.2-34.7.3-34.6 5.1-1.7c10.8-3.7 55.8-11.5 82.9-14.4 48.4-5.2 112.7-4.6 160 1.4 23.8 3 66.7 11.5 71.7 14.1 1.8 1 1.7.5 9.8 39.7 3.2 15.6 6 28.5 6.2 28.7.2.2 6.5.7 14.1 1.1l13.7.7V176.4c-.1-111.8-.9-148.6-3.6-157.4-1.9-6.5-5.9-10.6-14.1-14.7-14.4-7-33.5-.8-40.3 13.2-1.7 3.5-2.3 7.4-3 20.7-.5 9-.9 17.5-1 19V60H167v-2.8c-.1-1.5-.5-10-1-19-1-18.6-2.3-22.5-9.2-28.7-5.9-5.4-12-7.8-20.8-8.2-5.9-.3-8.4 0-11.7 1.6zM106.4 298c-1.3.5-2.9 3.1-4.3 6.7C87.9 341.5 1 574 1 575.2c0 .4 220.1.8 489.1.8 465.4 0 489.1-.1 488.7-1.8-.5-1.8-102.8-275.5-103.1-275.8-.1-.1-5.9-.5-12.9-.9l-12.6-.8 2.4 11.4c2 9.2 2.2 11.5 1.2 12.6-2.9 2.8-45.8 12.3-73.3 16.2-33.7 4.8-46.8 5.6-95.5 5.6-49.4 0-63-.7-94-5.1-21-2.9-31.9-5-59.2-11.1l-20.8-4.6V297h-40v24.6l-15.7 3.8c-20.6 4.8-36.9 7.9-57.8 11-38.9 5.7-43.7 6-95.5 6.1-51.4 0-62.7-.6-93.5-5.1-30.9-4.5-78.3-13.8-80.2-15.8-.3-.2.3-5.4 1.3-11.6 1.5-9.6 1.5-11.3.3-12.1-1.8-1.1-20.7-1.1-23.5.1zM46.5 597c-31.7.6-33.2.7-37.3 2.9-5.7 2.9-7.1 6.5-8.3 21-1.1 15-1.1 151.8.1 167.9 1.4 19.3 4.4 22.4 22.3 23l10.7.4v7.7c.1 12.7 1.8 26.5 4.1 32.7 4.1 11 16.5 20.6 28.5 22 9.6 1.2 22.1-4 29.3-12.3 6.5-7.3 8.3-14 8.8-33.6l.5-16.7H875v13.3c0 15.6 1.2 23.2 4.8 30.5 5.1 10.6 18.3 18.6 30.2 18.7 5.6 0 7.7-.6 14.2-3.8 9.1-4.5 14.8-10.4 17.8-18.5 2.2-5.7 3.9-20.2 4-32.8v-7.2l10.8-.4c17.5-.6 20.9-4.1 22.3-23.3 1-14.3 1-146.7 0-165.6-.9-16.6-1.6-18.4-8.8-22.6-4.3-2.5-5-2.6-30.1-3.4-29.3-.9-843.4-.9-893.7.1z" />
    </svg>
  )
}

export default DoubleBed