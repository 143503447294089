import React, {useState, Fragment} from 'react';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { CardBody, Table, Button, ButtonGroup, Row, Col, Pagination, PaginationItem, PaginationLink, Badge } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchRoom, deleteRoom } from '../../../slices/room'
import { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import ModalComp from '../../../containers/Components/ModalComp';
import SearchComp from '../../../containers/Components/SearchComp';
import config from '../../../config';

const RoomPage = (props) => {
    const dispatch = useDispatch();
    const { rooms } = useSelector(state => state);
    const history = useHistory();    
    const [roomId, setRoomId] = useState(0);
    const [modalValue, setModalValue] = useState({
        show: false,
        text : ''
    });
    const [pageValue, setPageValue] = useState({
        page : 1,
        obj : [],
        search : ''
    })
    const handleAdd = (props) => {
        sessionStorage.removeItem('roomId');
        history.push('/room/master/room/add');
    }
    
    const handleEdit = (id) => {
        sessionStorage.setItem('roomId', id)
        history.push('/room/master/room/edit')
    }

    useEffect(() => {
        dispatch(fetchRoom());
    },[dispatch]);

    useEffect(() => {
        setPageValue(pageValue => ({...pageValue, obj: rooms.list_page}))
    },[rooms.list_page]);

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setPageValue(pageValue => ({...pageValue, search: value}))
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    const handlePage = (page) => {
        setPageValue(pageValue => ({...pageValue, page: page}));
        dispatch(fetchRoom(pageValue.search, config.itemPerPage, page))
    }

    const handleSearch = () => {
        dispatch(fetchRoom(`q=${pageValue.search}`, config.itemPerPage, 1));
        setPageValue(pageValue => ({...pageValue, page: 1}))
    }


    const handleDelete = async(post) => {
        setModalValue(modalValue => ({...modalValue, 
            show: true,
            text : `Apakah anda yakin akan menghapus kamar ${post.nama} ?`
        }));
        setRoomId(post.id);
    }
   
    const handleCloseModal = () => {
        setModalValue(modalValue => ({...modalValue, show: false, text : ''}));
    }

    const handleSubmitDelete = () => {        
        dispatch(deleteRoom(roomId));     
        handleCloseModal();
    }


    return(
        <Fragment>
            <ModalComp
                isOpen={modalValue.show}
                onClose={handleCloseModal}
                title="Konfirmasi"
                body={modalValue.text}
                onSubmit={handleSubmitDelete}
                submitShow={true}
            />
            <CardTemplate
                xs={12}
                lg={12}
                headerTitle="Master Kamar"
            >
                <CardBody>                    
                    <Row style={{marginTop: 20}}>
                        <Col lg={10} md={10}>
                            <SearchComp
                                placeHolder="Cari Master Kamar"
                                value={pageValue.search}
                                onChange={handleChangeSearch}
                                onKeyDown={handleKeyDown}
                                onClick={handleSearch}
                            />
                        </Col>
                        <Col lg={2} md={2}>
                            <Button color="warning" block className="float-right" onClick={handleAdd}>
                                <i className="fa fa-plus"></i>&nbsp;Tambah
                            </Button>
                        </Col>
                    </Row>
                    <Table responsive style={{marginTop: 10}}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>No / Nama Kamar</th>
                                <th>Tipe Kamar</th>
                                <th>Tipe Tempat Tidur</th>
                                <th>Harga</th>
                                <th>Status Terisi</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { rooms.rooms.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{post.nomor}</td>
                                        <td>{post.nama}</td>
                                        <td>{post.tipe_kamar_name}</td>
                                        <td>{post.tipe_bed_name}</td>
                                        <td>
                                            <NumberFormat value={post.harga} displayType={'text'} thousandSeparator={true} prefix={'Rp.'} />
                                        </td>
                                        <td>                                            
                                            <Badge color={ post.empty_state ? "success" : "danger"} className="mr-1">{ post.empty_state ? "Ready" : "Terisi"}</Badge>
                                        </td>
                                        <td>
                                            <ButtonGroup>
                                                <Button color="primary" className='btn-edit' onClick={() => handleEdit(post.id)}>
                                                    <i className='fa fa-edit'></i>&nbsp; Edit
                                                </Button>
                                                <Button color="danger" className='btn-edit' onClick={() => handleDelete(post)}>
                                                    <i className='fa fa-trash'></i>&nbsp; Hapus
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    { rooms.list_page && rooms.list_page.length > 0 &&
                        <Pagination>
                            { rooms.list_page.map((post, index) => {
                                return(
                                    <PaginationItem key={index} className={pageValue.page === post ? 'active' : ''}>
                                        <PaginationLink tag="button" onClick={() => handlePage(post)}>
                                            {post}
                                        </PaginationLink>
                                    </PaginationItem>
                                )
                            })}
                        </Pagination>
                    }
                </CardBody>
            </CardTemplate>
        </Fragment>
    )
}
export default RoomPage;