import React, { useState, Fragment, useEffect } from 'react';
import CardTemplate from '../../../containers/Components/CardTemplate';
import { CardBody, CardFooter, Button, Alert } from 'reactstrap';
import FormTextComp from '../../../containers/Components/FormTextComp';
import * as produkService from '../../../services/produkService';
import { useHistory, useLocation } from 'react-router-dom';
import LoaderComponent from '../../../containers/Components/LoaderComponent';

import { fetchDetail } from '../../../slices/tipekamar';
import { useDispatch, useSelector } from 'react-redux';


const TipeKamarAddPage = (props) => {
    const dispatch = useDispatch();
    const { tipekamars } = useSelector(state => state);
    const location = useLocation();
	const tipeKamarId = location.state && location.state.tipeKamarId ? location.state.tipeKamarId :  sessionStorage.getItem('tipeKamarId');
    const history = useHistory();
    const [formData, setFormData] = useState({
        'nama' : '',
        'harga' : 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const [alertValue, setAlertValue] = useState({
        show: false,
        text : ''
    });
    const [errors, setErrors] = useState({
        'nama' : false,
        'harga' : false
    });
    const [isEdit, setIsEdit] = useState(false);

    const onBlur = e => {
		validateForm(e);
    }
    
    const handleChange = e => {        
        const { name, value } = e.target;
        let nilai = value 
        if (name === 'harga'){
            nilai = nilai.replace('Rp.', '');
            nilai = nilai.replace(/,/g, '');
        }
        setFormData({...formData, [name] : nilai});
	}

	const validateForm = (key) => {                  
        if (key === 'harga'){
            if (formData[key] === '' || formData[key] === '0' || formData[key] === 0){
                setErrors(errors => ({...errors, [key] : true}))
            }else{
                setErrors(errors => ({...errors, [key] : false}))
            }
        }else{
            if (formData[key] === ''){
                setErrors(errors => ({...errors, [key] : true}))
            }else{
                setErrors(errors => ({...errors, [key] : false}))
            }
        }
	}
    
    const onSubmit = async() => {
        setIsLoading(true);
        let error = 0;
        if (formData.nama === ''){
            setErrors(errors => ({...errors, nama : true}));
            error++;
        }
        if(error > 0){
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Isi data dengan benar'}));
            return;
        }

        // for number
        let newFormData = {...formData}
        try{    
            let newForm = new FormData()
            newForm.append('formData', JSON.stringify(newFormData));
            let res;
            if (isEdit){
                res = await produkService.putTipeKamar(tipeKamarId, newForm);
            }else{
                res = await produkService.postTipeKamar(newForm);
            }
            const newData = await res;
            setIsLoading(false);
            if (newData.sukses === 'yes'){
                if (isEdit){
                    sessionStorage.removeItem('tipeKamarId');
                }
                history.push('/room/master/tipe-kamar')                
            }else{
                setAlertValue(alertValue => ({...alertValue, show: true, text : newData.pesan}));
            }            
        }catch(error){
            setIsLoading(false);
        }

    }

    useEffect(() => {
		if (location.state && location.state.tipeKamarId) {
            sessionStorage.setItem('tipeKamarId', location.state.tipeKamarId);
            setIsEdit(true);
        }
        if(sessionStorage.getItem('tipeKamarId')){
            setIsEdit(true);
            dispatch(fetchDetail(tipeKamarId));		
        }
    }, [dispatch, tipeKamarId, location.state]);
    
    useEffect(() => {
        if(isEdit){
            setFormData(formData => ({...formData,
                id : tipekamars.tipekamar.id || 0,
                nama : tipekamars.tipekamar.nama || '',
                harga : tipekamars.tipekamar.harga || 0,
            }))
        }
    },[tipekamars, isEdit])
   
    return(
        <Fragment>
            { isLoading &&
                <LoaderComponent/>
            }
            <CardTemplate
                colClassName='offset-lg-3'
                xs={12}
                lg={6}
                headerTitle='Tambah Tipe Kamar'
            >
                <CardBody>
                    <Alert color="info" isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false}))}>
                        {alertValue.text}
                    </Alert>
                    <FormTextComp		
                        onChange={handleChange}
                        errors={errors}
                        onBlur={() => onBlur('nama')}
                        data={{
                            'name': 'nama',
                            'desc': 'Nama Tipe Kamar',
                            'placeHolder' : 'ex: Superior',
                            'tipe': 'text',
                            'id': 'nama',
                            'required': true,
                            'value': formData.nama,                        
                            'rule' : { required : true}
                        }}
                        formData={formData}
                    />
                    <FormTextComp		
                        onChange={handleChange}
                        errors={errors}
                        onBlur={() => onBlur('harga')}
                        data={{
                            'name': 'harga',
                            'desc': 'Harga Kamar',
                            'tipe': 'number',
                            'id': 'harga',
                            'required': true,
                            'value': formData.harga,
                            'error_message' : 'Nilai tidak boleh Nol atau kosong',                  
                            'rule' : { required : true}
                        }}
                        formData={formData}
                    />
                </CardBody>
                <CardFooter>
                    <Button color="primary" size="sm" onClick={onSubmit}>Simpan</Button>
                    &nbsp;<Button color="danger" size="sm" onClick={() => props.history.goBack()}>Cancel</Button>
                </CardFooter>
            </CardTemplate>
        </Fragment>
    )
}
export default TipeKamarAddPage;