import React, { Fragment, useState, useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardBody, CardFooter, Button, Row, Col, Table, Alert, FormGroup, Label, Modal, ButtonGroup } from 'reactstrap';
import CardTemplate from '../../../containers/Components/CardTemplate';
import FormTextSingleComp from '../../../containers/Components/FormTextSingleComp';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
import * as kontakService from '../../../services/kontakService';
import { fetchRoom } from '../../../slices/room';
import { useResto } from '../../FoodnBeverage/Pages/restofunction';
import KontakAddPage from '../../Kontak/Kontak/KontakAddPage';
import KontakPage from '../../Kontak/Kontak/KontakPage';
import Moment from 'moment';
import * as checkinService from '../../../services/checkinService';
import ReservationPage from '../Reservation/ReservationPage';

const CheckinBulkPage = (props) => {
    const dispatch = useDispatch();
    const { rooms } = useSelector(state => state);
    const { methodPembayaran } = useResto()
    let myDate = new Date();
    let tgl_checkout = myDate.setDate(myDate.getDate() + 1);
    const initArrayCheckin = [        
        {
            name : 'jenis_konsumen',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : 1,
            label : 'Jenis Konsumen *',
            tipe : 'select',
            showError : false,            
            show : true,            
            obj : [{value : 1, label: 'Perorangan'},{value: 2, label : 'Perusahaan'}],
            sequence : 1,
        },
        {
            name : 'konsumen_name',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nama Tamu *',
            tipe : 'input_button',
            showError : false,            
            show : true,
            readOnly : true,
            onClick : function(){
                handleClickKonsumen();
            },
            sequence : 4,            
        },
        {
            name : 'pic_name',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Nama Tamu *',
            tipe : 'input_button',
            showError : false,            
            show : true,
            readOnly : true,
            onClick : function(){
                handleClickPic();
            },
            sequence : 4,            
        },
        {
            name : 'deposite',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Deposite',
            tipe : 'number',
            showError : false,            
            show : true,
            readOnly : false,            
            sequence : 5,         
        },
        {
            name : 'method_pembayaran',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Method Pembayaran Deposite',
            tipe : 'select',
            obj : [],
            showError : false,
            show : true,
            readOnly : false,            
            sequence : 5,         
        },
        {
            name : 'nomor_kartu',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Card Remark',
            tipe : 'text',
            showError : false,
            show : true,
            readOnly : false,            
            sequence : 5,         
        },
        {
            name : 'tgl_checkout',
            required : true,
            errorMsg : 'Wajib Diisi',
            value : tgl_checkout,
            label : 'Tanggal Checkout',
            tipe : 'date',
            showError : false,            
            show : true,
            readOnly : false,            
            sequence : 6,         
        },
        {
            name : 'remark',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Remark',
            tipe : 'text',
            showError : false,            
            show : true,
            readOnly : false,            
            sequence : 7,         
        },
        {
            name : 'note',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Note',
            tipe : 'text',
            showError : false,            
            show : true,
            readOnly : false,            
            sequence : 8,
        },
        {
            name : 'konsumen_id',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'Konsumen_id',
            tipe : 'text',
            showError : false,            
            show : false,
            readOnly : true,            
            sequence : 12,         
        },
        {
            name : 'pic_id',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : '',
            label : 'pic_id',
            tipe : 'text',
            showError : false,            
            show : false,
            readOnly : true,            
            sequence : 12,         
        },
        {
            name : 'is_reservasi',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : false,
            label : 'is_reservasi',
            tipe : 'text',
            showError : false,            
            show : false,
            readOnly : true,            
            sequence : 12,         
        },
        {
            name : 'reservasi_id',
            required : false,
            errorMsg : 'Wajib Diisi',
            value : 0,
            label : 'reservasi_id',
            tipe : 'text',
            showError : false,            
            show : false,
            readOnly : true,            
            sequence : 12,         
        },
    ];

    const [arrayValue, setArrayValue] = useState({
        kamar : [{value : 0, label : '-- Pilih Kamar --'}],
        rb_state : [
            {value : 'ya', label : 'Ya'},
            {value : 'tidak', label : 'Tidak'},
        ]
    })
    const initKamar = {        
        kamar_konsumen : '',
        kamar_no_ktp : '',
        rb_state : 'ya',
        kamar_id : 0,
        harga : 0,
        jenis_harga : 0,
        harga_obj : [],
        tipe_kamar : '',
        obj : [] ,
        person : 1
    }

    const tmpFormData = initArrayCheckin.reduce((obj, item) => Object.assign(obj, { [item.name] : item}), {});    
    const [formCheckin, setFormCheckin] = useState(tmpFormData);   
    const [kamarArray, setKamarArray] = useState([]) 
    const [isLoading, setIsLoading] = useState(false);
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : ''
    });
    const [modalValue, setModalValue] = useState({
        show : false,
        tipe : 'konsumen',
        content : 'list',
        title : 'Daftar Tamu'
    })

    const handleClickPic = () => {
        setModalValue(modalValue => ({...modalValue,
            show : true,
            tipe : 'pic',
            content : 'list',
            title : `Daftar Tamu`
        }))
    }
    const handleClickKonsumen = () => {
        let title = modalValue.title;
        title = title.replace('Tambah ', '');
        let tipe = 'konsumen'
        if (parseInt(formCheckin.jenis_konsumen.value) === 1){
            // title = "Tamu";
            tipe = 'konsumen'
        }else if (parseInt(formCheckin.jenis_konsumen.value) === 2){
            // title = "Perusahaan";
            tipe = 'perusahaan'
        }else if (parseInt(formCheckin.jenis_konsumen.value) === 3){
            // title = "Travel"
            tipe = 'travel'    
        }else if (parseInt(formCheckin.jenis_konsumen.value) === 4){
            // title = "Pemerintahan"
            tipe = 'pemerintahan'
        }
        setModalValue(modalValue => ({...modalValue,
            show : true,
            tipe : tipe,
            content : 'list',
            title : title
        }))
    }

    const attrInput = (nilai) => {        
        let tmp = {}
        tmp['tipe'] = formCheckin[nilai].tipe || ''
        tmp['name'] = nilai;
        tmp['onChange'] = handleChangeCheckin        
        tmp['required'] =  formCheckin[nilai].required  || ''
        tmp['onBlur'] = handleBlurCheckin || ''
        tmp['show'] = formCheckin[nilai].show || ''
        tmp['obj'] = formCheckin[nilai].obj || ''
        tmp['value'] = formCheckin[nilai].value || ''
        tmp['onChangeDate'] = handleChangeDate || ''
        tmp['showError'] = formCheckin[nilai].showError || ''
        tmp['errorMsg'] = formCheckin[nilai].errorMsg || ''
        tmp['onClick'] = formCheckin[nilai].onClick || ''
        return tmp;
    }

    const handleChangeDate = (e) => {
        setFormCheckin(formCheckin => ({...formCheckin, tgl_checkout: {...formCheckin.tgl_checkout, value : e}}))
    }

    const labelInput = (nilai) => {
        return formCheckin[nilai].label        
    }

    const handleBlurCheckin = (e) => {
        const { name, value } = e.target;
        if (formCheckin[name].required && value === ''){
            setFormCheckin(formCheckin => ({...formCheckin, [name] : {...formCheckin[name], showError: true}}))
        }else{
            setFormCheckin(formCheckin => ({...formCheckin, [name] : {...formCheckin[name], showError: false}}))
        }
    }

    const handleChangeKamar = (e, index) => {        
        const { name, value } = e.target;
        let nilai = value;
        if (name === 'harga' || name === 'person'){
            nilai = nilai.replace(/,/g, '')
        }
        let tmp = [...kamarArray]
        tmp[index][name] = nilai;
        if (name === 'kamar_id'){
            let detail = rooms.rooms.filter(post => post.id === parseInt(nilai));
            if (detail.length > 0){
                tmp[index].harga = detail[0].harga_jual;
                let tmpHargaVariant = []
                detail[0].harga_variant.map(post => tmpHargaVariant.push({label : post.kategori_harga_name, value : post.kategori_harga_id, harga : post.harga}))

                tmp[index].harga_obj = tmpHargaVariant
                tmp[index].tipe_kamar = detail[0].tipe_kamar_name;
            }else{
                tmp[index].tipe_kamar = '';
                tmp[index].harga = 0;
                tmp[index].harga_obj = []
            }
        }
        if (name === 'jenis_harga'){
            let detail = tmp[index].harga_obj.filter(post => post.value === parseInt(nilai));
            if (detail.length > 0){
                tmp[index].harga = detail[0].harga;
            }            
        }        
        setKamarArray(tmp);
    }

    const handleDeleteKamar = (index) => {
        let array = [...kamarArray]
        array.splice(index, 1);
        setKamarArray(array);
    }

    const fetchTipeKontak = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await kontakService.getTipeKontak();
            setIsLoading(false);
            if (res.sukses === 'no'){
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan,value : ''}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue,show: false, text : '',value : ''}));
                }, 5000)
            }else{                
                setFormCheckin(formCheckin => ({...formCheckin, jenis_konsumen : {...formCheckin.jenis_konsumen, obj : res.data}}))
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue,
                show: true, 
                text : 'Unable to fetch tipe kontak',
                value : ''
            }));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue,
                    show: false, 
                    text : '',
                    value : ''
                }));            
            }, 5000)
        }
    },[])

    useEffect(() => {
        fetchTipeKontak();
    },[fetchTipeKontak]);

    useEffect(() => {        
        dispatch(fetchRoom(`room_state=VC`, 300, 1))
    }, [dispatch]);

    useEffect(() => {
        let tmp = [{value : 0, label : '-- Pilih Kamar --'}];
        rooms.rooms.map((post, index) => tmp.push({label : post.nama, value: post.id, tipe_kamar : post.tipe_kamar_name}))
        setArrayValue(arrayValue => ({...arrayValue, kamar : tmp}))
    },[rooms])

    useEffect(() => {
        let tmp = methodPembayaran.filter(x => x.value !== 7 && x.value !== 5 && x.value !== 2)        
        setFormCheckin(formCheckin => ({...formCheckin, method_pembayaran: {...formCheckin.method_pembayaran, obj : tmp}}));        
    },[methodPembayaran])

    const handleChangeCheckin = (e) => {
        const { name, value } = e.target;
        let title = ''
        let nilai;
        if (formCheckin[name].tipe === 'number'){
            nilai = value.replace(/,/g, '') 
        }else{
            nilai = value;
        }
        if (name === 'jenis_konsumen'){
            if (parseInt(nilai) === 1){ 
                title = 'Tamu'
                setFormCheckin(formCheckin => ({...formCheckin, 
                    konsumen_name : {...formCheckin.konsumen_name, label : 'Nama Tamu *'},
                    pic_name : {...formCheckin.pic_name, required : false},
                    pic_id : {...formCheckin.pic_id, required : false},                    
                }));                
            }else { // travel                
                if (parseInt(nilai) === 2){
                    title = 'Perusahaan'
                }else if (parseInt(nilai) === 3){
                    title = "Travel";
                }else{
                    title = "Pemerintahan"
                }
                setFormCheckin(formCheckin => ({...formCheckin, 
                    pic_name : {...formCheckin.pic_name, required : true},
                    konsumen_name : {...formCheckin.konsumen_name, label : `Nama ${title} *`},
                    pic_id : {...formCheckin.pic_id, required : true},                    
                }))
            }
            setModalValue(modalValue => ({...modalValue, title : `Daftar ${title}`}));
        }

        // if (name === 'method_pembayaran'){
        //     if (parseInt(nilai) === 3 || parseInt(nilai) === 4){
        //         setFormCheckin(formCheckin => ({...formCheckin,
        //             nomor_kartu : {...formCheckin.nomor_kartu, show: true}
        //         }))
        //     }else{
        //         setFormCheckin(formCheckin => ({...formCheckin,
        //             nomor_kartu : {...formCheckin.nomor_kartu, show: false}
        //         }))
        //     }
        // }
        
        setFormCheckin(formCheckin => ({...formCheckin, 
            [name] : {...formCheckin[name], value : nilai, },            
        }));            
    }

    const handleAddKamar = () => {
        setKamarArray([...kamarArray, initKamar])
    }

    const handleAddKontak = () => {
        let title = modalValue.title
        title = `Tambah ${title}`
        setModalValue(modalValue => ({...modalValue, content: 'form', title : title}))
    }

    const handleCancelModal = () => {
        let title = modalValue.title;
        title = title.replace('Tambah ', '');
        setModalValue(modalValue => ({...modalValue, content: 'list', title : title}))
    }

    const selectKontak = (post) => {
        if (modalValue.tipe === 'pic'){
            setFormCheckin(formCheckin => ({...formCheckin, 
                pic_name : {...formCheckin.pic_name, value : post.nama},
                pic_id : {...formCheckin.pic_id, value : post.id}
            }))
        }else{
            setFormCheckin(formCheckin => ({...formCheckin, 
                konsumen_name : {...formCheckin.konsumen_name, value : post.nama},
                konsumen_id : {...formCheckin.konsumen_id, value : post.id}
            }))
        }
        setModalValue(modalValue => ({...modalValue, show: false}))
    }

    const handleCloseModal = () => {
        setModalValue(modalValue => ({...modalValue,
            show: false,
            tipe: 'konsumen',
            content : 'list',
            title : 'Daftar Tamu'
        }))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        let countError = 0;
        let array = {...formCheckin};
        if (array.deposite.value === '' || parseInt(array.deposite.value) === 0){
            array.method_pembayaran.required = false;
        }
        Object.entries(array).map( ([index, post]) => {            
            if(post.required === true){
                if( post.value === ''){                    
                    post.showError = true;
                    countError++;
                }else{
                    post.showError = false;
                }
            }
            return true;
        });
        if (countError > 0){            
            setFormCheckin(array);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data'}));
            setIsLoading(false);
            return;          
        }
        if (kamarArray.length === 0){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'silakan masukan minimal satu kamar'}));
            return;
        }
        
        let tmp = {}
        Object.entries(array).map( ([index, post]) => tmp[post.name] = post.value)
        tmp['tgl_checkout'] = Moment(formCheckin.tgl_checkout.value).format('YYYY-MM-DD');
        const form = tmp;
        let newForm = new FormData();
        newForm.append('formData', JSON.stringify(form));        
        newForm.append('kamarArray', JSON.stringify(kamarArray))
        try{
            const res = await checkinService.postCheckinBulk(newForm);
            const newData = await res; 
            setIsLoading(false);
            if (newData.sukses === 'no'){
                setAlertValue(alertValue => ({...alertValue, show: true, text : newData.pesan}))
            }else{
                props.history.push('/fo');
            }
        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to save checkin'}))
        }
    }

    const handleFromReservation = () => {
        setModalValue(modalValue => ({...modalValue,
            show : true,
            tipe : 'reservasi',
            content : 'list',
            title : 'Daftar Reservasi'
        }))
    }

    const handleSelectReservasi = (post) => {
        setFormCheckin(formCheckin => ({...formCheckin,
            jenis_konsumen : {...formCheckin.jenis_konsumen, value : post.tipe_kontak},
            konsumen_name : {...formCheckin.konsumen_name, value : post.kontak_name},
            pic_name : {...formCheckin.pic_name, value : post.pic_kontak_name},
            tgl_checkout : {...formCheckin.tgl_checkout, value : Moment(post.tgl_checkout, 'DD-MM-YYYY').toDate()},
            konsumen_id : {...formCheckin.konsumen_id, value : post.kontak_id},
            pic_id : {...formCheckin.pic_id, value : post.pic_kontak_id},
            reservasi_id : {...formCheckin.reservasi_id, value : post.id},
            is_reservasi : {...formCheckin.is_reservasi, value : true},
            remark : {...formCheckin.remark, value : post.remark},
        }));        
        let array = [];                
        for(let i=0;i<post.jml_kamar;i++){            
            array.push({        
                kamar_konsumen : '',
                kamar_no_ktp : '',
                rb_state : 'ya',
                kamar_id : 0,
                harga : 0,
                jenis_harga : 0,
                harga_obj : [],
                tipe_kamar : '',
                obj : [] 
            });
        }        
        setKamarArray(array);
        setModalValue(modalValue => ({...modalValue, show : false}))
    }

    const handleReset = () => {
        setFormCheckin(tmpFormData);
        setKamarArray([]);
        setModalValue({
            show : false,
            tipe : 'konsumen',
            content : 'list',
            title : 'Daftar Tamu'
        })
    }

    return(
        <Fragment>
            { (isLoading ) &&
                <LoaderComponent/>
            }
            <Alert isOpen={alertValue.show} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false}))} color="danger">
                {alertValue.text}
            </Alert>
            <Modal isOpen={modalValue.show} toggle={handleCloseModal} size='lg'>  
                { (modalValue.tipe === 'konsumen' || modalValue.tipe === 'pic') &&
                    <Fragment>             
                        { modalValue.content === 'list' ?                         
                            <KontakPage
                                modal={true}
                                query={modalValue.tipe === 'pic' ?  `tipe_kontak=1` : `tipe_kontak=${formCheckin.jenis_konsumen.value}`}
                                handleAdd={handleAddKontak}
                                title={modalValue.title}
                                selectKontak={selectKontak}
                            />
                            :
                            <KontakAddPage
                                hideJenisKontak={true}
                                tipe_kontak={modalValue.tipe === 'pic' ? 1 : formCheckin.jenis_konsumen.value}
                                modal={true}
                                title={modalValue.title}
                                handleAdd={handleCancelModal}
                            />
                        }
                    </Fragment>
                }
                { modalValue.tipe === 'reservasi' &&
                    <ReservationPage
                        modal={true}
                        selectReservasi={handleSelectReservasi}
                    />
                }
            </Modal>
            <CardTemplate
                headerTitle="Checkin"
            >
                <CardBody>                    
                    <Row>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('jenis_konsumen')}</Label>
                                        <FormTextSingleComp                                        
                                            {...attrInput('jenis_konsumen')}
                                            name='jenis_konsumen'
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label>{labelInput('konsumen_name')}</Label>
                                        <FormTextSingleComp                                        
                                            {...attrInput('konsumen_name')}
                                            name='konsumen_name'
                                            readOnly={true}
                                        />
                                    </FormGroup>
                                </Col>
                                { parseInt(formCheckin.jenis_konsumen.value) !==  1 &&
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label>{labelInput('pic_name')}</Label>
                                            <FormTextSingleComp
                                                {...attrInput('pic_name')}
                                                name='pic_name'
                                            />
                                        </FormGroup>
                                    </Col>
                                }
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>{labelInput('method_pembayaran')}</Label>
                                        <FormTextSingleComp                                        
                                            {...attrInput('method_pembayaran')}
                                            name='method_pembayaran'
                                        />                                        
                                    </FormGroup>
                                </Col>      
                                <Col lg={12}>
                                    <FormGroup>
                                        <Label>{labelInput('deposite')}</Label>
                                        <FormTextSingleComp                                        
                                            {...attrInput('deposite')}
                                            name='deposite'
                                        />
                                    </FormGroup>
                                </Col>                                
                                { (parseInt(formCheckin.method_pembayaran.value) === 3 || parseInt(formCheckin.method_pembayaran.value) === 4) &&
                                    <Fragment>
                                        <Col lg={6}>                                    
                                        </Col>                
                                        <Col lg={6}>
                                            <FormGroup>
                                                <Label>{labelInput('nomor_kartu')}</Label>
                                                <FormTextSingleComp                                        
                                                    {...attrInput('nomor_kartu')}
                                                    name='nomor_kartu'
                                                />
                                            </FormGroup>
                                        </Col>                
                                    </Fragment>
                                }          
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Label>{labelInput('tgl_checkout')}</Label>
                                <FormTextSingleComp                                        
                                    {...attrInput('tgl_checkout')}
                                    name='tgl_checkout'
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{labelInput('remark')}</Label>
                                <FormTextSingleComp                                        
                                    {...attrInput('remark')}
                                    name='remark'
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{labelInput('note')}</Label>
                                <FormTextSingleComp                                        
                                    {...attrInput('note')}
                                    name='note'
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className='float-right' style={{marginBottom: 10}}>
                                <ButtonGroup>
                                    <Button color="warning" size="sm" onClick={handleAddKamar}>Tambah Kamar</Button>
                                    <Button color="info" size="sm" onClick={handleFromReservation}>Ambil Data dari Reservasi</Button>
                                    <Button color="danger" size="sm" onClick={handleReset}>Reset Form</Button>
                                </ButtonGroup>
                            </div>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nomor Kamar</th>
                                        <th>Include Breakfast</th>
                                        <th>Jenis Harga</th>
                                        <th>Harga</th>
                                        <th>Nama Tamu</th>
                                        <th>No KTP</th>
                                        <th>Person</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { kamarArray.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>
                                                    <div>
                                                        <FormTextSingleComp
                                                            tipe="select" 
                                                            name='kamar_id'
                                                            readOnly={false}
                                                            value={post.kamar_id}
                                                            obj={arrayValue.kamar}
                                                            show={true}
                                                            onChange={(e) => handleChangeKamar(e, index)}
                                                        />
                                                    </div>
                                                    <div style={{marginTop: 5}}>
                                                        Tipe Kamar : { post.tipe_kamar }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <FormTextSingleComp
                                                            tipe="select" 
                                                            name='rb_state'
                                                            readOnly={false}
                                                            value={post.rb_state}
                                                            obj={arrayValue.rb_state}
                                                            show={true}
                                                            onChange={(e) => handleChangeKamar(e, index)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <FormTextSingleComp
                                                        tipe="select" 
                                                        name='jenis_harga'
                                                        readOnly={false}
                                                        value={post.jenis_harga}
                                                        obj={post.harga_obj}
                                                        show={true}
                                                        onChange={(e) => handleChangeKamar(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <FormTextSingleComp
                                                        tipe="number" 
                                                        name='harga'
                                                        readOnly={false}
                                                        value={post.harga}
                                                        show={true}
                                                        className='text-right'
                                                        onChange={(e) => handleChangeKamar(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <FormTextSingleComp
                                                        tipe="text" 
                                                        name='kamar_konsumen'
                                                        readOnly={false}
                                                        value={post.kamar_konsumen}
                                                        show={true}
                                                        onChange={(e) => handleChangeKamar(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <FormTextSingleComp
                                                        tipe="text" 
                                                        name='kamar_no_ktp'
                                                        readOnly={false}
                                                        value={post.kamar_no_ktp}
                                                        show={true}
                                                        onChange={(e) => handleChangeKamar(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <FormTextSingleComp
                                                        tipe="number" 
                                                        name='person'
                                                        readOnly={false}
                                                        value={post.person}
                                                        show={true}
                                                        onChange={(e) => handleChangeKamar(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <Button color="danger" className="btn-edit" onClick={() => handleDeleteKamar(index)}>
                                                        <i className='fa fa-trash'></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <Button color="primary" onClick={handleSubmit}>Simpan</Button>
                    &nbsp;<Button color="secondary" onClick={() => props.history.push('/fo')}>Kembali</Button>
                </CardFooter>
            </CardTemplate>
        </Fragment>
    )
}
export default CheckinBulkPage;