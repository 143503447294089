export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {        
        // development: "http://127.0.0.1:8000",
        // production: "http://127.0.0.1:8000", 
        // development: "http://localhost:8000",
        // production: "http://localhost:8000",         
        development: "https://bekurniadua.myappscenter.com",
        production: "https://bekurniadua.myappscenter.com" 
        // development: "https://bekph.myappscenter.com",
        // production: "https://bekph.myappscenter.com" 
    },
    timeoutValue : 5000,
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__renataKidsUser",
      ACCESS_TOKEN: "__renataKidsValue",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm",
      DEF_FB : "__defFb", 
    },
    expiresSession : 86400,
    itemPerPage : 10,
    timeOutValue : 5000,
    endPoint : {
      penjualan : `penjualan/api/v1/penjualan-penjualan/`,
      groupPermissionv1 : `/auth/api/v1/permission-group/`,
      authv1 : `/auth/api/v1/`,
      changePassword : `/auth/api/change-password`,

      userv1 : `/auth/api/v1/user/`,
      inputBiaya : `/penjualan/api/v1/input-biaya/`,
      penjualanDetail : `/penjualan/api/v1/penjualan-detail/`
    }
};
  