import { createSlice } from '@reduxjs/toolkit';
import * as penjualanService from '../services/penjualanService';
import config from '../config';

const initialState = {
    loading : false,
    hasErrors : false,
    restos : [],
    resto : {},
    list_page : [],
    pesan : ''
}

const restoStoreSlice = createSlice({
    name : 'restoStore',
    initialState,
    reducers : {
        getData : (state) => { state.loading = true},
        getDataSuccess : (state, action) => {
            state.loading = false;
            state.hasErrors = false;
            state.restos = action.payload.data;
            state.list_page = action.payload.list_page;
        },
        getDataFailure : (state) => { state.loading = false; state.hasErrors = true},
        setDetail : (state, action) => {
            state.loading = false;
            state.hasErrros = false;
            state.resto = action.payload;
        },
        deleteData : (state, action) => {
            // let tmpData = [...state.restos];
            let tmpData = state.restos.restos;
            console.log('restos tmpData', tmpData);
            state.loading = false;
            state.hasErrors = false;
            state.restos = state.restos.filter(item => item.id !== action.payload);
        },
        setPesan : (state, action) => {
            state.pesan = action.payload;
        }
    }
})

export const { getData, getDataSuccess, getDataFailure, setDetail, deleteData, setPesan } = restoStoreSlice.actions;

export const restoSelector = (state) => state.restos;

export default restoStoreSlice.reducer;


export function fetchResto(q='', limit=config.itemPerPage, page=1){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await penjualanService.getPenjualanResto(q, limit, page);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(getDataSuccess(data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}

export function fetchDetail(id){
    return async(dispatch) => {
        dispatch(getData());
        try{
            const response = await penjualanService.getPenjualanResto(`id=${id}`, 1, 1);
            const data = await response;            
            if(data.sukses === 'yes'){
                dispatch(setDetail(data.data))
            }else{
                dispatch(getDataFailure());
            }
        }catch(error){
            dispatch(getDataFailure());
        }
    }
}
