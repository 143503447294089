import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { Button, Col, FormGroup, Input, Row, Table } from 'reactstrap';

const BillingComp = (props) => {       
    const { hiddenAddButton } = props;
    return(
        <Fragment>
            <Row>
                <Col lg={6}>
                    <FormGroup row>
                        <Col lg={3}>Nama Tamu</Col>
                        <Col lg={9}>
                            { props.data.konsumen_name }
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={3}>Alamat</Col>
                        <Col lg={9}>
                            { `${props.data.kecamatan_name} ${props.data.kota_name}` }
                        </Col>
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup row>
                        <Col lg={6}>Tanggal Checkin</Col>
                        <Col lg={6}>
                            { props.data.tgl_checkin }
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={6}>Tanggal Checkout</Col>
                        <Col lg={6}>
                            { props.data.tgl_checkout }
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col lg={6}>Status</Col>
                        <Col lg={6}>
                            { props.data.bill_state }
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    { !hiddenAddButton &&
                        <div className="text-right">
                            <Button  color='primary' size='sm' style={{marginBottom: 10}} onClick={props.handleAddTrx}>Tambah Transaksi</Button>
                        </div>
                    }
                    <Table responsive>
                        <thead>
                            <tr>
                                <th className='text-right'>#</th>
                                <th>Tanggal</th>
                                <th>Uraian</th>
                                <th className='text-right'>Qty</th>
                                <th className='text-right'>Harga Satuan</th>
                                <th className='text-right'>Sub Total</th>                                        
                            </tr>
                        </thead>
                        <tbody>                                    
                            { props.data.room_detail && props.data.room_detail.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td className='text-right'>{post.nomor}</td>
                                        <td>{post.tanggal}</td>
                                        <td>{post.nama_produk}</td>
                                        <td className='text-right'>{post.qty}</td>
                                        <td className='text-right'>{post.harga_jual.toLocaleString()}</td>
                                        <td className='text-right'>{(post.harga_jual * post.qty).toLocaleString()}</td>
                                    </tr>
                                )
                            })}                            
                            { !props.is_close && props.data.billing_detail && props.data.billing_detail.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td className='text-right'>{post.nomor}</td>
                                        <td>{post.tanggal}</td>
                                        <td>{post.nama_produk}</td>
                                        <td className='text-right'>{post.qty}</td>
                                        <td className='text-right'>{post.harga_jual.toLocaleString()}</td>
                                        <td className='text-right'>{(post.harga_jual * post.qty).toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                            { props.latecheckoutArray && props.latecheckoutArray.map((post, index) => {
                                return(
                                    <tr key={index}>
                                        <td>{post.nomor}</td>                                        
                                        <td>
                                            <Input
                                                onChange={(e) => props.handleChangeLate(e, index)}
                                                name='trx_name'
                                                type='select'
                                                onBlur={(e) => props.handleBlurLate(e, index)}
                                                placeholder='Uraian'
                                                value={post.trx_name.value}
                                            >
                                                { post.trx_name.obj.map((trxname, mykey) => {
                                                    return(
                                                        <option value={trxname.value} key={mykey}>{trxname.label}</option>
                                                    )
                                                })}
                                            </Input>                              
                                            { post.trx_name.showError &&
                                                <div className="error-message" style={{ fontStyle:'italic', fontSize: 12, color: 'red'}}>
                                                    {post.trx_name.errorMsg ? post.trx_name.errorMsg : 'Wajib diisi'}
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            { post.desc.show &&
                                                <Fragment>
                                                    { post.desc.tipe === 'text' ?
                                                        <Input
                                                            onChange={(e) => props.handleChangeLate(e, index)}
                                                            name='desc'
                                                            type='text'
                                                            onBlur={(e) => props.handleBlurLate(e, index)}
                                                            placeholder='Uraian'
                                                            value={post.desc.value}
                                                        />
                                                        :
                                                        <Fragment>
                                                        <Input
                                                            onChange={(e) => props.handleChangeLate(e, index)}
                                                            name='desc'
                                                            type='select'
                                                            onBlur={(e) => props.handleBlurLate(e, index)}
                                                            placeholder='Uraian'
                                                            value={post.desc.value}
                                                        >
                                                            <option value=''>-- Pilih Extra Bed --</option>
                                                            { post.desc.obj.map((prod, mykey) => {
                                                                return(
                                                                    <option key={mykey} value={prod.value}>{prod.label}</option>
                                                                )
                                                            })}
                                                        </Input>
                                                        { post.desc.showError &&
                                                            <div className="error-message" style={{ fontStyle:'italic', fontSize: 12, color: 'red'}}>
                                                                {post.desc.errorMsg ? post.desc.errorMsg : 'Wajib diisi'}
                                                            </div>
                                                        }
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                            }
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <NumberFormat 
                                                thousandSeparator={true}      
                                                inputMode="numeric"
                                                name='nominal'
                                                value={post.nominal.value} 
                                                onChange={(e) => props.handleChangeLate(e, index)}
                                                onBlur={(e) => props.handleBlurLate(e, index)}
                                                className='form-control text-right'
                                                readOnly={post.readOnly ? true : false}
                                            />
                                            { post.nominal.showError &&
                                                <div className="error-message" style={{ fontStyle:'italic', fontSize: 12, color: 'red'}}>
                                                    {post.nominal.errorMsg ? post.nominal.errorMsg : 'Wajib diisi'}
                                                </div>
                                            }
                                        </td>                                        
                                    </tr>
                                )
                            })}     
                            { parseInt(props.totalValue.sub_total) > 0 &&
                                <tr>
                                    <td colSpan={5} className='text-right'>Sub Total</td>
                                    <td className='text-right'>{props.totalValue.sub_total.toLocaleString()}</td>
                                </tr>
                            }
                            { parseInt(props.totalValue.total_diskon) > 0 &&
                                <tr>
                                    <td colSpan={5} className='text-right'>Diskon</td>
                                    <td className='text-right'>{props.totalValue.total_diskon && parseInt(props.totalValue.total_diskon).toLocaleString()}</td>
                                </tr>
                            }
                            { parseInt(props.totalValue.total_pajak) > 0 && parseInt(props.totalValue.total_diskon) > 0 && (parseInt(props.totalValue.sub_total) - parseInt(props.totalValue.total_diskon)) > 0 &&
                                <tr>
                                    <td colSpan={5} className='text-right'>DPP</td>
                                    <td className='text-right'>{(parseInt(props.totalValue.sub_total)-parseInt(props.totalValue.total_diskon)).toLocaleString()}</td>
                                </tr>
                            }
                            { parseInt(props.totalValue.total_pajak) > 0 &&
                                <tr>
                                    <td colSpan={5} className='text-right'>Tax</td>
                                    <td className='text-right'>{props.totalValue.total_pajak.toLocaleString()}</td>
                                </tr>
                            }
                            { parseInt(props.totalValue.total) > 0 &&
                                <tr>
                                    <td colSpan={5} className='text-right'>Total</td>
                                    <td className='text-right'>{(parseInt(props.totalValue.total)-parseInt(props.totalValue.total_diskon)).toLocaleString()}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    )
}

BillingComp.defaultProps = {
    hiddenAddButton : false
}
export default BillingComp;