import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, CardBody, Col, Collapse, FormGroup, Label, Row, Table } from 'reactstrap';
import CardTemplate from '../../../containers/Components/CardTemplate';
import SearchComp from '../../../containers/Components/SearchComp';
import Moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTipeKamar } from '../../../slices/tipekamar';
import DatePicker from "react-datepicker";
import * as checkinService from '../../../services/checkinService'
import config from '../../../config';
import LoaderComponent from '../../../containers/Components/LoaderComponent';
const RoomPlanPage = (props) => {
    const dispatch = useDispatch();
    const { tipekamars } = useSelector(state => state);
    const [alertValue, setAlertValue] = useState({
        show : false,
        text : '',
        color : 'danger'
    });
    const [isLoading, setIsLoading] = useState(false);
    const [listData, setListData] = useState([])
    const [totalValue, setTotalValue] = useState({});    
    const [pageValue, setPageValue] = useState({
        page : 1,
        search: '',
        filter : 0,
        tanggal : null,
        tanggal_value : ''
    })
    
    const handleResetFilter = () => {
        setPageValue({
            page : 1,
            search: '',
            filter : 0,
            tanggal : null,
            tanggal_value : ''            
        });
        fetchData();
    }
    const [filterCollapse, setFilterCollapse] = useState(false);
    const fetchData = useCallback(async(q='', tipe_kamar=0, tanggal='', limit=config.itemPerPage, page=1) => {
        setIsLoading(true);
        try{
            const res = await checkinService.getRoomPlan(`q=${q}&tipe_kamar=${tipe_kamar}&tanggal=${tanggal}`, limit, page)
            setIsLoading(false);
            if (res.sukses === 'yes'){
                setListData(res.data);
                setTotalValue(res.total_value)
            }else{
                setAlertValue(alertValue => ({...alertValue, show: true, text : res.pesan, color : 'danger'}))
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))
                }, 5000)
            }

        }catch(error){
            setIsLoading(false);
            setAlertValue(alertValue => ({...alertValue, show: true, text : 'Unable to fetch data', color : 'danger'}))
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))
            }, 5000)
        }
    },[])

    useEffect(() => {
        fetchData();
    },[fetchData])

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    const handleChangeDate = (e) => {
        let tgl;
        if (isValidDate(e)){
            tgl = new Date(e);
            tgl = Moment(tgl).format('YYYY-MM-DD');
            fetchData(pageValue.search, pageValue.filter, tgl, config.itemPerPage, 1)
        }else{
            tgl = '';
        }
        setPageValue(pageValue => ({...pageValue, tanggal : e, tanggal_value : tgl}))
    }

    useEffect(() => {
        dispatch(fetchTipeKamar('', 100, 1))
    },[dispatch]);

    const handleFilter = (post) => {        
        setPageValue(pageValue => ({...pageValue, filter: post}));
        fetchData(pageValue.search, post, pageValue.tanggal_value, config.itemPerPage, 1)        
    }

    const handleCollapse = () => {
        setFilterCollapse(!filterCollapse)
    }

    return(
        <Fragment>
            { (isLoading || tipekamars.loading) &&
                <LoaderComponent/>
            }
            <Alert isOpen={alertValue.show} color={alertValue.color} toggle={() => setAlertValue(alertValue => ({...alertValue, show: false, text : '', color : 'danger'}))}>
                {alertValue.text}
            </Alert>
            <CardTemplate
                headerTitle="Room Plan"
            >
                <CardBody>
                    <Collapse isOpen={filterCollapse}>
                        <Row>
                            <Col lg={4}>
                                <div className="title-filter">Filter</div>                                
                                <br/>
                                <FormGroup>
                                    <Label>Tanggal</Label>
                                    <DatePicker
                                        style={{display:'block'}}
                                        name={'tanggal'}
                                        className="form-control"
                                        selected={pageValue.tanggal}   
                                        dateFormat="dd-MM-yyyy"
                                        onChange={handleChangeDate}
                                    />  
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <ul className='ul-filter-guest'>
                                    <li onClick={() => handleFilter(0)} className={parseInt(pageValue.filter) === 0 ? 'active' : '' }>Semua</li>
                                    { tipekamars.tipekamars.map((post, index) => {
                                        return(
                                            <li key={index} onClick={() => handleFilter(post.id)} className={parseInt(pageValue.filter) === parseInt(post.id) ? 'active' : '' }>{post.nama}</li>
                                        )
                                    })}
                                </ul>
                            </Col>
                        </Row>
                    </Collapse>
                    <Row>
                        <Col lg={8}>
                            <SearchComp/>
                        </Col>    
                        <Col lg={4}>
                            <ButtonGroup>
                                <Button onClick={handleCollapse} color='warning'>{ filterCollapse ? 'Close Filter' : 'Open Filter'}</Button>
                                <Button color='primary' onClick={handleResetFilter}>Reset Filter</Button>
                            </ButtonGroup>
                        </Col>                    
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='text-right'>#</th>
                                        <th>Tipe Kamar</th>
                                        <th className='text-right'>Jumlah Kamar</th>
                                        <th className='text-center'>Checkin</th>
                                        <th className='text-center'>Checkout</th>
                                        <th className='text-right'>Perkiraan kamar tersedia</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { listData.map((post, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className='text-right'>{post.nomor}</td>
                                                <td>
                                                    {post.nama}
                                                    <div>
                                                        {post.room_obj}
                                                    </div>
                                                </td>
                                                <td className='text-right'>{post.jml_kamar}</td>
                                                <td className='text-center'>
                                                    <div className='text-center'>
                                                        {post.checkin_count}
                                                    </div>
                                                    <div className='text-center'>{post.checkin_obj}</div>
                                                    { post.reservation_obj && post.reservation_obj.map((res, mykey) => {
                                                        return(
                                                            <div className='text-center' key={mykey}>{res}</div>
                                                        )
                                                    })}
                                                </td>
                                                <td className='text-center'>
                                                    {post.checkout_count}
                                                    <div className='text-center'>{post.checkout_obj}</div>
                                                </td>
                                                <td className='text-right'>{post.available}</td>
                                                <td className="text-center">
                                                    <Button className='btn-edit' color='success'>
                                                        <i className='fa fa-link'></i>&nbsp;Detail
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                { listData.length > 0 &&
                                    <tfoot>
                                        <tr>
                                            <td className="text-right" colSpan={2}>Total</td>
                                            <td className='text-right'>{totalValue.total_kamar}</td>
                                            <td className='text-center'>{totalValue.total_checkin}</td>
                                            <td className='text-center'>{totalValue.total_checkout}</td>
                                            <td className='text-right'>{totalValue.total_available}</td>
                                        </tr>
                                    </tfoot>
                                }
                            </Table>
                        </Col>                        
                    </Row>
                </CardBody>
            </CardTemplate>
        </Fragment>
    )
}
export default RoomPlanPage;